<template>
  <div id="app">
    <nav-header :style="{ display: onAdminPage }"></nav-header>
    <router-view />
    <nav-footer></nav-footer>
  </div>
</template>
<script>
import NavHeader from "@/components/NavHeader.vue";
import NavFooter from "@/components/NavFooter.vue";
import { db, auth, fire } from "./main";
export default {
  name: "App",
  metaInfo: {
    title: "Vehicles",
    titleTemplate: "%s | Classic Car Collections",
  },
  data() {
    return {
      onAdminPage: "block",
    };
  },
  components: {
    NavHeader,
    NavFooter,
  },
  methods: {
    checkAdmin() {
      if (window.location.href.includes("/admin")) {
        console.log("ON ADMIN PAGE");
        this.onAdminPage = "none";
      } else {
        console.log("NOT ON ADMIN");
        this.onAdminPage = "block";
      }
    },
    initFacebookChatBot() {
      try {
        var chatbox = document.getElementById("fb-customer-chat");
        chatbox.setAttribute("page_id", "106547788299512");
        chatbox.setAttribute("attribution", "biz_inbox");

        window.fbAsyncInit = function () {
          window.FB.init({
            xfbml: true,
            version: "v11.0",
          });
        };

        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src =
            "https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
      } catch (error) {
        console.log(error);
        console.log("Faceboot chat init failed");
      }
    },
  },
  watch: {
    $route() {
      this.checkAdmin();
    },
  },
  created() {
    this.checkAdmin();

    auth.onAuthStateChanged((user) => {
      console.log("Auth State Changed");
      if (user) {
        console.log("User: " + user.email);

        user.getIdToken().then((t) => console.log(t));
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((dbUser) => {
            if (dbUser.exists) {
              console.log("Setting User Details");
              console.log("Email verified: " + user.emailVerified);
              const userData = dbUser.data();
              this.$store.dispatch("user/setUser", {
                id: dbUser.id,
                firstName: userData.firstName,
                surname: userData.surname,
                email: userData.email,
                emailVerified: user.emailVerified,
                admin: false,
                country: userData.country,
              });
            }
          })
          .then(() => {
            user
              .getIdTokenResult(true)
              .then((idTokenResult) => {
                if (idTokenResult.claims.admin) {
                  console.log("USER IS ADMIN");
                  this.$store.commit("user/SET_ADMIN", true);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });

        if (!this.$store.state.user.newUser) {
          db.collection("users")
            .doc(user.uid)
            .update({
              lastLogin: fire.firestore.FieldValue.serverTimestamp(),
            })
            .catch((err) => {
              console.log("Can't update user login time: " + err);
            });
        }
      } else {
        console.log("No User ");
      }
    });
  },
  mounted() {
    this.initFacebookChatBot();
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
}
</style>
