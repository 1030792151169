<template>
  <section class="wrapper">
    <div v-if="registered" class="box has-text-centered successBox">
      <verify-email
        @verifySucceeded="redirectAfterLogin"
        :email="email"
      ></verify-email>
    </div>
    <div v-if="!registered" class="box margins monFontReg">
      <h2 class="mt-3 monFont500">Register</h2>
      <nav class="level">
        <div class="level-item has-text-centered mt-2">
          <span class="grey mr-2">Already have an account?</span>
          <router-link class="has-text-weight-bold" :to="{ name: 'Login' }">
            Log In</router-link
          >
        </div>
      </nav>
      <register-form
        ref="registerForm"
        @registerSucceeded="registerSucceeded"
      ></register-form>
    </div>
  </section>
</template>

<script>
import RegisterForm from "@/components/RegisterForm.vue";
import VerifyEmail from "../components/VerifyEmail.vue";
export default {
  metaInfo: {
    title: "Register",
    meta: [
      {
        name: "description",
        content:
          "Welcome to Classic Car Collections. Register now to bid on our latest lots",
      },
    ],
  },
  components: {
    RegisterForm,
    VerifyEmail,
  },
  data() {
    return {
      registered: false,
      email: "",
    };
  },
  methods: {
    registerSucceeded(email) {
      this.email = email;
      this.registered = true;
    },
    redirectAfterLogin() {
      const dest = this.$route.query.from ?? "Home";
      this.$router.replace({ name: dest });
    },
  },
  created() {
    if (this.$store.state.user.id && !this.$store.state.user.emailVerified) {
      this.registered = true;
      this.email = this.$store.state.user.email;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/bulmaVariables.scss";
.margins {
  margin: 50px 20px !important;

  width: 500px;
}

.wrapper {
  display: flex;
  justify-content: center;
}

h2 {
  font-size: 32px;
}

.successBox {
  width: 700px;
  margin: 10vh auto;
  padding: 50px;
}

@media (max-width: 768px) {
  .margins {
    width: 90vw !important;
  }
  .successBox {
    width: 90vw;
  }

  h2 {
    font-size: 28px;
  }
}
</style>
