import Dashboard from "../views/admin/Dashboard.vue";
import Lot from "../views/admin/Lot.vue";
import Lots from "../views/admin/Lots.vue";
import Auctions from "../views/admin/Auctions.vue";
import Admin from "../views/admin/Admin.vue";
import Users from "../views/admin/Users.vue";
export default [
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "lots",
        name: "Lots",
        component: Lots,
      },
      {
        path: "users",
        name: "Users",
        component: Users,
      },
      {
        path: "lots/:id",
        name: "AdminLot",
        component: Lot,
      },
      {
        path: "auctions/",
        name: "Auctions",
        component: Auctions,
      },
    ],
  },
];
