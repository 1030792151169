<template>
  <section class="wrapper">
    <div class="box margins monFontReg">
      <h2 class="mt-3 monFont500">Login</h2>
      <nav class="level">
        <div class="level-item has-text-centered mt-2">
          <span class="grey mr-2">Are you new?</span>
          <router-link
            class="has-text-weight-bold"
            :to="{ name: 'Register', query: $route.query }"
          >
            Sign Up Now</router-link
          >
        </div>
      </nav>
      <login-form @loginSucceeded="redirectAfterLogin"></login-form>
    </div>
  </section>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
export default {
  metaInfo: {
    title: "Login",
    meta: [
      {
        name: "description",
        content:
          "Welcome back to Classic Car Collections. Login now to bid on our latest lots",
      },
    ],
  },
  components: {
    LoginForm,
  },
  methods: {
    redirectAfterLogin() {
      const dest = this.$route.query.from ?? "Home";
      this.$router.replace({ name: dest });
    },
  },
  created() {
    if (this.$store.state.user.id && !this.$store.state.user.emailVerified) {
      this.$router.replace({ name: "Register", query: this.$route.query });
    }
  },
};
</script>

<style lang="scss" scoped>
.margins {
  margin: 50px 20px;

  width: 500px;
}

.wrapper {
  display: flex;
  justify-content: center;
}

h2 {
  font-size: 32px;
}
</style>
