<template>
  <section class="wrapper has-text-left monFontReg">
    <div class="columns">
      <div
        class="
          column
          is-6-desktop is-8-tablet is-offset-2-tablet is-offset-3-desktop
        "
      >
        <h2 class="mainHeader monFontBold p-1">Terms and Conditions</h2>
        <div class="termsText">
          <p class="monFontBold">
            REGISTRATION REQUIREMENTS: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </p>
          <p>FICA DOCUMENTS</p>
          <p>REGISTRATION DEPOSIT</p>
          <p><span style="white-space: pre">&nbsp; &nbsp;&nbsp;</span></p>
          <p>
            <strong>Conditions of Sale</strong> are always stipulated by
            documents that accompany any particular auction that is being
            advertised on Estate Auction House and Classic Car Collections
            website.
          </p>
          <p>
            It is of utmost importance to study all Conditions of Sale documents
            and it is binding by law.
          </p>
          <p>
            <strong>
              The following Terms &amp; Conditions serve as binding conditions
              to all auctions and sales hosted by : Estate Auction House and
              Classic Car Collections</strong
            >
          </p>
          <p>
            1. &nbsp;I/we (hereafter referred to as the purchaser) indemnify and
            hold harmless Estate Auction House PTY Ltd (hereafter referred to as
            Estate Auction House) in respect of any claim of whatsoever nature,
            including but not limited to death, loss, injuries or damages of
            whatsoever nature and howsoever arising, which the purchaser may
            have against Estate Auction House arising out of or in connection
            with the auction.
          </p>
          <p>
            2. &nbsp;The purchaser regard these terms and conditions as binding
            on he/she/its executors, Legatees, Heirs, Successors in title and or
            assigned respectively, as specified during the announcements
            irrespective of whether he/she/it is present at the time such
            announcements are made. &nbsp;Special terms and conditions relating
            to each auction and/or to any of the lots may be announced prior to
            the commencement of the auction and/or during the auction.
          </p>
          <p>
            3. &nbsp;The purchaser will adhere to all relevant FICA requirements
            on auction.
          </p>
          <p>
            4. &nbsp;The auctioneer shall have the right to control, regulate
            and record the auction. The recording of the auction shall be prima
            facie evidence of the auction proceedings.
          </p>
          <p>
            5. &nbsp;The auctioneer may accept or reject bids in his sole
            discretion without providing reasons. This would include rejecting
            an offer previously accepted by the auctioneer. In the event of a
            dispute between bidders, the auctioneer will have the discretion to
            put the property (movable/immovable) up for auction again and his
            decision shall be final and binding. The auctioneer may, without
            penalty or prior notice, withdraw any item(s) from any group of
            assets (&ldquo;lots&rdquo;) on auction at any time before the sale
            thereof or during the auction.
          </p>
          <p>
            6. &nbsp;The deposit will be refunded to the bidder when he/she
            leaves the auction, provided that he/she is not the highest bidder
            on any item and relevant FICA requirements on auction are adhered
            to.
          </p>
          <p>
            7. Every bid (the act of clicking on the bidding button or
            submitting a bid price by the bidder) shall constitute an offer to
            purchase for the amount bid.
          </p>
          <p>
            8. &nbsp;The highest bidder accepted by the auctioneer shall be the
            purchaser if NO RESERVE or SUBJECT TO CONFIRMATION is applicable.
          </p>
          <p>
            9. &nbsp;No &ldquo;ringing&rdquo; shall be permitted and if the
            auctioneer suspect that the bidders are involved in such activity,
            he shall be entitled to suspend or terminate the auction or to
            prohibit those bidders suspected of such activity from making any
            further bids. In this context ringing are defined as a group of
            buyers at an auction who collude not to bid against one another in
            order to keep the auction price low.
          </p>
          <p>
            10. &nbsp;The auctioneer is entitled to correct any bona fide error
            in the conduct or conclusion of any sale or arising from any
            incorrect information to the items for sale. In the event that the
            error is capable of not being corrected, then the auction shall be
            deemed not having taken place and no party at the auction will have
            any claims against the auctioneer or the seller arising from the
            cancellation of the auction.
          </p>
          <p>11. &nbsp; All sales are done in one of the following manners:</p>
          <p>
            11.1 NO RESERVE - &quot;no reserve&quot; means that there is no
            established minimum price for the item being auctioned. This means
            that no matter what the winning bid, the owner must sell it for that
            amount; or
          </p>
          <p>
            11.2 RESERVE - &ldquo;Reserve&rdquo; means a minimum price that has
            to be attained for the bid to be accepted. In compliance with the
            National Consumer Protection Act and the Regulations thereto, Vendor
            Bidding is permitted at the auction, and the seller, auctioneer or a
            Vendor Bidder representing the seller or the auctioneer may bid up
            to an amount and not exceeding the Reserve price. &nbsp;If no bid
            equals or exceeds the reserve price, the property may be withdrawn
            from the auction.
          </p>
          <p>
            11.3 SUBJECT TO CONFIRMATION - &ldquo;Subject to confirmation&rdquo;
            means that the owner has to accept or reject the bid after
            finalization of the auction. The Seller has 2 (two) business days
            (&ldquo;the Confirmation Period&rdquo;) to accept or reject the
            offer. No bid may be withdrawn after the fall of the hammer until
            the expiry of the Confirmation Period. During this time the offer
            shall be open for acceptance by the Seller or his agent and if the
            offer is accepted, the sale shall be deemed to be a sale by auction
            for purposes of the Act. &nbsp;Higher offers may be made prior to
            confirmation or registration of transfer (whichever occurs first).
            &nbsp;Such higher offers must be made to the auctioneer on the same
            terms and conditions as the offer on the auction, but the original
            bidder at the auction shall have the right to equal such offers
            which offer will be preferent to any other offers. &nbsp;The
            purchaser from the auction must exercise this right within 24 hours
            (excluding public holidays and weekends) after being so notified by
            the auctioneer, by submitting and equivalent offer and supplementing
            his deposit accordingly.
          </p>
          <p>
            12. &nbsp;These Rules of Auction comply with the provisions of
            Section 45 of the Consumer protection Act, 68 of 2008. &nbsp;Sub
            sections (1), (2) and (3) of this Section provides as follows:
          </p>
          <p>
            &ldquo;(1) In this section, &ldquo;auction&rdquo; includes a sale in
            execution of or pursuant to a court order, to the extent that the
            order contemplates that the sale is to be conducted by an auction.
          </p>
          <p>
            (2) When good are put up for sale by auction in lots, each lot is,
            unless there is evidence to be contrary regarded to be the subject
            of a separate transaction.
          </p>
          <p>
            (3) A sale by auction is complete when the auctioneer announces its
            completion by the fall of the hammer, or in any other customary
            manner, until that announcement is made, a bid may be
            retracted.&rdquo;
          </p>
          <p>
            13. &nbsp;All items are sold &ldquo;voetstoots&rdquo;. No
            representations or warranties are made by the auctioneer or the
            seller. &nbsp;The purchaser shall be deemed to have, thoroughly
            examined the items and have irrevocably accepted the condition and
            state thereof. &nbsp;Neither the seller nor Estate Auction House
            gives warranties or guarantees of or makes any representation in
            regards to the condition of sale of any items. &nbsp;Estate Auction
            House shall not be liable for any errors of description of any
            items.
          </p>
          <p>
            14. &nbsp;Neither the auctioneer nor the seller shall be liable in
            consequence of any representation made by them at or before the
            auction; in particular no warranties are made regarding immovable
            property, motor vehicles in respect of year, make or model thereof,
            and/or outstanding tickets or the roadworthiness thereof. It is
            specifically agreed that it is the sole responsibility of the
            Purchaser to acquaint and familiarize them with the property they
            are bidding on.
          </p>
          <p>
            15. &nbsp;Risk on each item passes onto the purchaser on the fall of
            the hammer or electronic notification of a successful bid once a
            particular lot closes on an online auction, even though
            upliftment/delivery has not yet taken place. &nbsp;The purchaser
            shall bear the risk of items remaining at Estate Auction
            House&rsquo;s premises, in particular for any shortages or damage
            which may occur. &nbsp;The purchaser shall remove the items form
            Estate Auction House&rsquo;s premises within 3 (three) days of the
            auction.
          </p>
          <p>
            16. &nbsp;On the acceptance of a successful bid a document detailing
            (herein after referred to as the &ldquo;Invoice&rdquo;) the exact
            identification of the item sold, the price, any additional cost
            including but not limited to Commission, and terms of payment are
            generated by the auctioneer and does the registrant hereby accept
            the content thereof as summary of the sale and amount due. Such
            invoice will be sent by email to the Purchaser before close of the
            next business day.
          </p>
          <p>
            17. &nbsp;The purchaser shall remove his purchases at the conclusion
            of the auction, and only after full clearance of his payment as set
            out in the Invoice have been effected and all agreements required by
            Estate Auction House has been properly signed by both parties.
          </p>
          <p>
            18. &nbsp;Should the purchaser fail to remove any item after
            clearance of his payment, a storage fee of R350.00 plus VAT per item
            per day shall be charged to the purchaser. Furthermore, should any
            items purchased not be removed after a period of 21 (twenty one)
            calendar days, the items will be sold by the auctioneer at the
            expense of the purchaser and without any further notice thereof to
            the purchaser. &nbsp;Alternatively, by written arrangement between
            the auctioneer and the purchaser, the auctioneer will store the
            items at the expense of the purchaser. Storage cost will be
            determined by the parties before conclusion of such an agreement.
          </p>
          <p>
            19. &nbsp;The purchaser shall be liable for the costs of repair for
            any damages caused by him to the auction premises or any other item
            while removing his purchases or otherwise.
          </p>
          <p>
            20. &nbsp;Should any item purchased be financed by the purchaser
            through a financial institution, proof of approved finance must be
            presented to the auctioneer during the bidder registering process.
            Such proof must be sent to the auctioneer at least 12 (twelve) hours
            before the close of the auction. Such proof must be sent to
            sales@estateauction.com and it remains the duty of the Purchaser to
            confirm that such proof was indeed received by the Auctioneer. No
            item may be purchased subject to finance approval.
          </p>
          <p>
            21. &nbsp;The purchase price of each item, including the amounts as
            set out in the invoice, will be paid by the purchaser by way of
            cash, a Bank Guaranteed cheque or Electronic Funds Transfer. Cash
            payments above R 20 000.00 will not be accepted on premises. If
            payment is made in Cash, a 2% (two percent) handling fee will be
            charged.
          </p>
          <p>
            22. &nbsp;The full Invoice amount must be paid to the auctioneer
            within 48 (forty eight) hours of the conclusion of the auction.
            &nbsp;Commission including VAT thereon is payable on conclusion of
            the auction. Ownership will only pass to the purchaser on clearance
            of payment of the full amount as set out in the Invoice.
          </p>
          <p id="23">
            23. &nbsp;The bid price does include Value Added Tax (VAT), unless
            otherwise specified by the auctioneer.&nbsp;
          </p>
          <p>
            24. &nbsp;Buyer&rsquo;s commission of 10% of total bid including VAT
            shall be charged and is payable by the purchaser, unless otherwise
            stated.
          </p>
          <p>
            25. Where registration documents are applicable, documentation fees
            will be charge.
          </p>
          <p>
            26. &nbsp;If any item is sold Subject to Confirmation by the seller,
            the confirmation period may be between 24(twenty four) hours &ndash;
            21 (twenty one) days (depending on the type of matter) from the date
            of the auction, unless otherwise stated by the auctioneer or
            advertised, during which period the purchaser shall not be entitled
            to withdraw his/her offer.
          </p>
          <p>
            27. &nbsp;Any item that is Subject to Confirmation will be paid for
            in full by the highest bidder on conclusion of the auction.
            &nbsp;Should the bid not be accepted by seller within the adequate
            period as noted on Clause 24 above, then the auctioneer will refund
            to the purchaser the full amount paid to the auctioneer for the
            item. No interest is payable by the auctioneer.
          </p>
          <p>
            28. &nbsp;Estate Auction House shall be entitled, without penalty to
            cancel the sale should the required documents not be signed and
            delivered to Estate Auction House within 21 (twenty one) days of the
            date on which the sale becomes unconditional. &nbsp;The said 21
            (twenty one) day period may be extended by Estate Auction House for
            a further 14 (fourteen) days in Estate Auction House&rsquo;s sole
            and absolute discretion. &nbsp;In the event of any purchaser failing
            to pay the full purchase price any item within the applicable time
            (as per the relevant announcement) and/or failing to remove the
            items as foresaid, and/or otherwise failing to comply with any of
            these terms and conditions without prejudice to any other rights
            which Estate Auction House enjoys, the purchaser shall irrevocably
            forfeited to Estate Auction House any deposit paid by the purchaser,
            for and on the purchaser&rsquo;s behalf. &nbsp;SA Auction shall in
            addition have a lien over the items and/shall have the right to
            re-sell the items without notice to the purchaser. &nbsp;Should
            Estate Auction House re-sell such items and should the net proceeds
            thereof (after deducting commission and all costs incurred or to be
            incurred by having to re-sell the items) be less than the sale
            price, the purchaser shall forthwith upon demand pay Estate Auction
            House as pre-estimated, liquidated damages, less any deposit
            forfeited. &nbsp;This shall be without prejudice to the seller
            and/or Estate Auction House&rsquo;s right to claim all
            amounts/damages which it/they may claim in law. &nbsp;Estate Auction
            House shall under no circumstances be liable to the purchaser for
            any losses or damages suffered by the purchaser as a result of the
            auction or termination of any sale concluded in respect of any lots
            in questions, and/or the failure or inability of Estate Auction
            House to deliver any of the items.
          </p>
          <p>
            29. &nbsp;If the purchaser breaches any of these Conditions of Sale,
            he/she shall be liable to pay any cost incurred by the auctioneer as
            a result of the breach, including administration costs, storage
            costs, and legal costs as between the attorney and own client
            including collection commission.
          </p>
          <p>
            30. &nbsp;The purchaser chooses his domicilium citandi et executandi
            at the address supplied by himself during the registration process.
          </p>
          <p>
            31. &nbsp;The parties hereto consent to the jurisdiction of the
            Magistrate&rsquo;s Court, in terms of section 45 read with section
            28 of the Magistrate&rsquo;s Court Act of 1994 as amended.
            Notwithstanding the foregoing, this shall not preclude either party
            from approaching the High Court of South Africa for any relief
            sought. If any party approaches the High Court it is specifically
            agreed to the jurisdiction of the Gauteng High Court.
          </p>
          <p>
            32. &nbsp;These Conditions of Sale constitute the whole agreement
            between the parties as to the subject matter hereof and no
            agreement, representation or warranty between the parties other than
            those set out herein are binding on the parties. The parties
            accepting these Terms and Conditions confirm that they have read and
            understood all of the terms and conditions contained herein and
            agree that they are bound hereto.
          </p>
          <p>
            33. &nbsp;No extension of time, waiver, indulgence or suspension of
            any of the provisions of this agreement which any party hereto may
            have given shall be binding unless recorded in a written document
            signed by all parties.
          </p>
          <p>
            34. &nbsp;By signing this document the signatory hereby cedes,
            assigns, transfers and makes over unto and in favour of the seller
            and/or the auctioneer his/her/its right title and interest to all
            his/her/its movable property and if necessary immovable property
            registered in his/her/its name, such as is sufficient to satisfy
            his/her/its indebtedness to the seller and to the auctioneer.
          </p>
          <p>
            35. &nbsp;To this end the signatory hereby authorizes the sheriff of
            the High Court or his deputy in the jurisdiction of the High Court
            in which the goods in question were sold in terms of the auction to
            act on his/her/its behalf in rem suam and if necessary to sign all
            necessary documentation for and on his/her/its behalf necessary to
            give effect to this clause and the terms and conditions set out
            herein.
          </p>
          <p>
            36. &nbsp;In the event of the signatory opposing any legal
            proceedings instituted in terms of these clauses, any variations or
            amendments hereto shall be of any force or effect unless reduced to
            writing and signed by both parties.
          </p>
          <p>
            37. &nbsp;No indulgence which the auctioneer may grant to the
            purchaser shall prejudice or constitute a waiver of the rights of
            the auctioneer or the seller, who shall not thereby be precluded
            from exercising any rights against the purchaser which may have
            arisen in past or might arise in the future.All contracts on the
            sale of properties to be signed directly after the action, and
            purchaser shall pay the required deposit on sale of property.
          </p>
          <p>
            38. &nbsp;The signatory (person who agrees to the Terms and
            Conditions set out on the website) to this agreement binds
            himself/herself as surety and co-principal debtor together with the
            company, close corporation, trust, association or any other entity
            reflected on the face of the terms and conditions set forth more
            fully above for the due fulfillment by the principal debtor of all
            the principal debtor&rsquo;s obligations and liability arising out
            of the sale of any goods to the principal debtor. In this regard the
            signatory as surety and co-principal debtor renounces the legal
            exceptions of excussion and division, nonnumeratepecuniae, non
            causadebiti, errorecaluli no value received and revision of account
            with which the signatory hereto is fully acquainted and understand.
          </p>
          <p>
            39. &nbsp;Any person, who registers for any auction held by Estate
            Auction House, whether online or on-site, agrees to all the combined
            Terms &amp; Conditions of Estate Auction House.
          </p>
          <p>
            40. &nbsp;Winning bid information: &nbsp;A winning bid is understood
            to be the highest bid at the close of the auction. Should there be a
            contestation to the winning bid, Estate Auction House will refer the
            matter to an external IT company who will investigate the bids
            leading up to the close of the auction. The decision provided by the
            external company will be final and no correspondence will be entered
            into.
          </p>
          <p>
            Please note that online bids are dependent on the bidders internet
            connection and Estate Auction House can not be liable for any bids
            that are not received due to poor connection or unforeseen
            circumstances.
          </p>
          <p>
            &nbsp;In the event that there is a problem with
            www.estateauctionhouse.com &nbsp;(the website) bidders will be
            notified of same and a corrective course of action will be followed
            for all stakeholders.
          </p>
        </div>
      </div>
    </div>
    <p><br /></p>
  </section>
</template>

<script>
export default {
  metaInfo: {
    title: "Terms and Conditions",
    meta: [
      {
        name: "description",
        content:
          "View our terms and conditions for details of the buying, selling and auction process",
      },
    ],
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/bulmaVariables.scss";

p {
  padding-bottom: 10px;
}

.mainHeader {
  text-align: center;
  font-size: 32px;
  background-color: $primary;
  color: white;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .termsText {
    margin: 0 40px;
  }
}
</style>
