<template>
  <div class="box">
    <b-field custom-class="is-medium" label="Title">
      <b-input v-model="lot.title"></b-input>
    </b-field>

    <b-field v-if="sold" class="" custom-class="is-medium" label="Sold Price">
      <b-numberinput
        :controls="false"
        v-model="lot.soldPrice"
        :min="0"
      ></b-numberinput>
    </b-field>

    <b-field class="mt-5 has-text-weight-bold mb-3">
      <b-switch v-model="sold" :left-label="true" size="is-large"
        >Sold</b-switch
      >
    </b-field>

    <b-field class="mt-5 has-text-weight-bold">
      <b-switch v-model="lot.featured" :left-label="true" size="is-large"
        >Featured Lot</b-switch
      >
    </b-field>
    <b-field class="mt-5 mb-6 has-text-weight-bold">
      <b-switch v-model="lot.active" :left-label="true" size="is-large"
        >Activate Lot</b-switch
      >
    </b-field>
    <b-notification
      v-for="(error, index) in errors"
      :key="index"
      type="is-danger"
      has-icon
      :message="'<strong>' + error + '</strong>'"
      aria-close-label="Close notification"
      role="alert"
    >
    </b-notification>

    <b-button
      expanded
      type="is-primary"
      size="is-large"
      icon-left="upload"
      @click="upload"
    >
      Save
    </b-button>
  </div>
</template>

<script>
import { fire } from "@/main";
export default {
  props: {
    lotId: { type: String, required: true },
  },
  data() {
    return {
      lot: {},
      sold: false,
      errors: [],
      loadingComponent: null,
    };
  },

  methods: {
    init() {
      let loadedLot = this.$store.getters["admin/getLot"](this.lotId);
      if (loadedLot) {
        this.lot = loadedLot;

        this.sold = this.lot.status === "Sold";
        console.dir(this.lot);
      } else {
        console.log("Unable to load lot");
      }
    },
    openLoader() {
      this.loadingComponent = this.$buefy.loading.open({
        container: null,
      });
    },
    closeLoader() {
      this.loadingComponent.close();
    },
    checkForm() {
      this.errors = [];

      if (!this.lot.title || this.lot.title == "") {
        this.errors.push("Lot has no title");
      }

      if (
        this.lot.bidInfo.reservePrice == null ||
        this.lot.bidInfo.startingBid == null ||
        this.lot.bidInfo.bidIncrement == null ||
        this.lot.salePrice == null ||
        this.lot.priceRange.min == null ||
        this.lot.priceRange.max == null
      ) {
        this.errors.push("Lot bidding amounts are incomplete");
      }

      if (this.lot.soldPrice == null && this.lot.status == "Sold")
        this.errors.push("Lot has no sold price");

      return this.errors.length == 0;
    },

    async upload() {
      try {
        if (this.sold) {
          this.lot.status = "Sold";
        }
        let succ = this.checkForm();

        if (!succ) {
          console.log("Check form failed");
          return;
        }

        this.openLoader();
        const loadingRef = this.loadingComponent;

        this.lot.modifiedAt = fire.firestore.FieldValue.serverTimestamp();

        await this.$store.dispatch("admin/upsertLot", this.lot);
        console.log("Uploaded Lot:");
        this.$emit("success");

        console.dir(this.lot);
        loadingRef.close();
      } catch (error) {
        console.log("Failed to upload lot");
        console.dir(error);
        this.$swal(
          "Upload Failed",
          "Unable to upload lot: " + error.message,
          "error"
        );
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style></style>
