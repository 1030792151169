<template>
  <section class="containerWrapper monFontReg">
    <h1 class="title is-1 has-text-centered">
      {{ newLot ? "Add New Lot" : "Edit Lot" }}
    </h1>
    <div class="box">
      <b-field
        class="has-text-centered"
        label="Basic Info"
        custom-class="is-large"
      ></b-field>
      <b-field custom-class="is-medium" label="Lot Number">
        <b-input disabled v-model="lot.lotId"></b-input>
      </b-field>
      <b-field custom-class="is-medium" label="Title">
        <b-input v-model="lot.title"></b-input>
      </b-field>

      <b-field custom-class="is-medium" label="Year">
        <b-numberinput
          :controls="false"
          v-model="lot.year"
          :min="1900"
        ></b-numberinput>
      </b-field>

      <b-field custom-class="is-medium" label="Make">
        <b-input v-model="lot.make"></b-input>
      </b-field>

      <b-field custom-class="is-medium" label="Colour">
        <b-input v-model="lot.colour"></b-input>
      </b-field>

      <b-field
        class="mt-5 mb-5"
        custom-class="is-medium"
        label="Keywords (max 4)"
      >
        <b-taginput
          v-model="lot.keywords"
          ellipsis
          type="is-primary"
          size="is-medium"
          icon="tag"
          placeholder="Add a tag"
          aria-close-label="Delete this tag"
        >
        </b-taginput>
      </b-field>
    </div>

    <!-- LOT DETAILS -->
    <div class="box">
      <b-field
        class="has-text-centered"
        label="Lot Details"
        custom-class="is-large"
      ></b-field>
      <lot-information-text
        :infoType="'info'"
        ref="infoText"
        :existingInfo="lot.infoText"
      ></lot-information-text>
    </div>

    <!-- OVERVIEW -->
    <div class="box">
      <b-field
        label="Overview"
        custom-class="is-large"
        class="has-text-centered"
      ></b-field>
      <lot-information-text
        :infoType="'overview'"
        :lotId="lot.lotId"
        ref="OverviewText"
        :existingInfo="lot.overview"
      ></lot-information-text>
    </div>
    <!-- PRICE INFO -->
    <div class="box">
      <b-field
        custom-class="is-large"
        label="Price Info"
        class="has-text-centered"
      >
      </b-field>
      <b-field custom-class="is-medium" label="Reserve Price">
        {{ numberToWords(lot.bidInfo.reservePrice) }}
        <b-numberinput
          :controls="false"
          width="100"
          v-model="lot.bidInfo.reservePrice"
          :min="0"
        ></b-numberinput>
      </b-field>
      <b-field custom-class="is-medium" label="Starting Bid">
        {{ numberToWords(lot.bidInfo.startingBid) }}
        <b-numberinput
          :controls="false"
          v-model="lot.bidInfo.startingBid"
          :min="0"
        ></b-numberinput>
      </b-field>
      <b-field custom-class="is-medium" label="Bid Increment">
        {{ numberToWords(lot.bidInfo.bidIncrement) }}
        <b-numberinput
          :controls="false"
          v-model="lot.bidInfo.bidIncrement"
          :min="0"
        ></b-numberinput>
      </b-field>

      <b-field custom-class="is-medium" label="Sale Price">
        {{ numberToWords(lot.salePrice) }}
        <b-numberinput
          :controls="false"
          v-model="lot.salePrice"
          :min="0"
        ></b-numberinput>
      </b-field>

      <b-field custom-class="is-medium" label="Price Range">
        <h4 class="monFont500 title is-5 pl-3">Min</h4>
        <b-numberinput
          style="width: 30%"
          :controls="false"
          v-model="lot.priceRange.min"
          :min="0"
        ></b-numberinput>
        <div class="ml-2">
          <p style="width: 200px">{{ numberToWords(lot.priceRange.min) }}</p>
        </div>

        <h4 class="monFont500 title is-5 pl-3">Max</h4>

        <b-numberinput
          style="width: 30%"
          :controls="false"
          v-model="lot.priceRange.max"
          :min="0"
        ></b-numberinput>
        <div class="ml-2">
          <p style="width: 200px">{{ numberToWords(lot.priceRange.max) }}</p>
        </div>
      </b-field>
    </div>

    <b-field
      custom-class="is-large"
      label="Sold Price"
      v-if="lot.status === 'sold'"
    >
      {{ numberToWords(lot.soldPrice) }}
      <b-numberinput
        :controls="false"
        v-model="lot.soldPrice"
        :min="0"
      ></b-numberinput>
    </b-field>

    <div class="box">
      <b-field
        custom-class="is-large"
        label="Lot Media"
        class="has-text-centered"
      >
      </b-field>
      <b-field custom-class="is-medium" label="Video URL">
        <b-input :lazy="true" v-model="lot.video_url"></b-input>
      </b-field>
      <b-field class="mb-5">
        <b-button @click="convertToEmbeddedLink" type="is-primary"
          >Convert to embedded link</b-button
        >
      </b-field>
      <b-field custom-class="is-large" label="Hero Image">
        <div class="mainImageWrapper">
          <img v-if="lot.imageUrls.hero !== null" :src="lot.imageUrls.hero" />
        </div>
      </b-field>
      <sortable-upload
        ref="featuredGallery"
        :group="{ name: 'upload', pull: false, put: true }"
        :title="'Featured'"
        :imageType="'featured'"
        :existingImageUrls="lot.imageUrls.featured"
        :lotId="this.lot.lotId"
        @setHero="setHero($event)"
      ></sortable-upload>
      <sortable-upload
        ref="exteriorGallery"
        :group="{ name: 'upload', pull: 'clone', put: false }"
        :title="'Exterior Images'"
        :imageType="'exterior'"
        :existingImageUrls="lot.imageUrls.exterior"
        :lotId="this.lot.lotId"
        @addToFeatured="addToFeatured($event)"
        @setHero="setHero($event)"
      ></sortable-upload>
      <sortable-upload
        ref="interiorGallery"
        :group="{ name: 'upload', pull: 'clone', put: false }"
        :title="'Interior Images'"
        :imageType="'interior'"
        :existingImageUrls="lot.imageUrls.interior"
        :lotId="this.lot.lotId"
        @addToFeatured="addToFeatured($event)"
        @setHero="setHero($event)"
      ></sortable-upload>
      <sortable-upload
        ref="mechanicalGallery"
        :group="{ name: 'upload', pull: 'clone', put: false }"
        :title="'Mechanical Images'"
        :imageType="'mechanical'"
        :existingImageUrls="lot.imageUrls.mechanical"
        :lotId="this.lot.lotId"
        @addToFeatured="addToFeatured($event)"
        @setHero="setHero($event)"
      ></sortable-upload>
      <sortable-upload
        ref="documentGallery"
        :group="{ name: 'upload', pull: 'clone', put: false }"
        :title="'Document Images'"
        :imageType="'document'"
        :existingImageUrls="lot.imageUrls.document"
        :lotId="this.lot.lotId"
        @addToFeatured="addToFeatured($event)"
        @setHero="setHero($event)"
      ></sortable-upload>
    </div>

    <div class="tags">
      <span
        v-for="(file, index) in dropFiles"
        :key="index"
        class="tag is-primary"
      >
        {{ file.name }}
        <button
          class="delete is-small"
          type="button"
          @click="deleteDropFile(index)"
        ></button>
      </span>
    </div>
    <div class="box">
      <b-field
        class="has-text-centered"
        custom-class="is-large"
        label="Upload Lot"
      >
      </b-field>
      <b-field class="mt-5 has-text-weight-bold">
        <b-switch v-model="lot.featured" :left-label="true" size="is-large"
          >Featured Lot</b-switch
        >
      </b-field>
      <b-field class="mt-5 mb-6 has-text-weight-bold">
        <b-switch v-model="lot.active" :left-label="true" size="is-large"
          >Activate Lot</b-switch
        >
      </b-field>
      <b-notification
        v-for="(error, index) in errors"
        :key="index"
        type="is-danger"
        has-icon
        :message="'<strong>' + error + '</strong>'"
        aria-close-label="Close notification"
        role="alert"
      >
      </b-notification>
      <b-message
        title="Error!"
        type="is-danger"
        has-icon
        v-model="failedNotification"
        aria-close-label="Close message"
      >
        Failed to Upload Lot
      </b-message>
      <b-message
        title="Success!"
        type="is-success"
        has-icon
        v-model="successNotification"
        aria-close-label="Close message"
      >
        Successfully Uploaded Lot
      </b-message>
      <b-message
        title="Error!"
        type="is-danger"
        has-icon
        v-model="failedImageNotification"
        aria-close-label="Close message"
      >
        Failed to Upload Images
      </b-message>
      <b-button
        expanded
        type="is-primary"
        size="is-large"
        icon-left="upload"
        @click="upload"
      >
        Upload
      </b-button>
    </div>
  </section>
</template>

<script>
import LotInformationText from "../../components/admin/LotInformationText.vue";
import SortableUpload from "../../components/admin/SortableUpload.vue";

import { fire } from "@/main";
export default {
  data() {
    return {
      lotId: this.$route.params.id,
      newLot: this.$route.params.id == "new",

      dropFiles: [],
      errors: [],
      statuses: ["On Auction", "For Sale", "Sold"],
      informationHeadings: [
        "KEY FACTS",
        "EQUIPMENT AND FEATURES",
        "CONDITION",
        "SERVICE HISTORY",
        "SUMMARY",
      ],
      bulletPoints: {},
      lot: {
        status: "For Sale",
        bidInfo: {},
        priceRange: {},
        infoText: [],
        imageUrls: {
          hero: null,
          featured: [],
          exterior: [],
          interior: [],
          mechanical: [],
          document: [],
        },
        keywords: [],
        active: true,
        featured: false,
      },
      loadingComponent: "",
      failedNotification: false,
      successNotification: false,
      failedImageNotification: false,
    };
  },

  methods: {
    init() {
      if (!this.newLot) {
        let loadedLot = this.$store.getters["admin/getLot"](this.lotId);
        if (loadedLot) {
          this.lot = loadedLot;
          console.dir(this.lot);
        } else {
          console.log("Unable to load lot");
        }
      } else {
        console.log("New Lot");
        this.lot.lotId = this.$store.getters["admin/getNewLotId"]();
        for (let heading of this.informationHeadings) {
          this.lot.infoText.push({
            heading: heading,
            bulletPoints: [{ bulletPoint: "" }],
          });
        }
      }
    },
    getYoutubeEmbeddedUrl(url) {
      const u = new URL(url);
      const urlParams = new URLSearchParams(u.searchParams);
      const videoID = urlParams?.get("v");

      if (!videoID) return null;

      const embedBase = `https://www.youtube.com/embed/${videoID}?rel=0`;

      return embedBase;
    },
    deleteDropFile(index) {
      console.dir(this.dropFiles[index]);

      this.dropFiles.splice(index, 1);
    },
    dateFormat(val) {
      return val.toLocaleString("en-ZA", {
        dateStyle: "medium",
      });
    },
    openLoader() {
      this.loadingComponent = this.$buefy.loading.open({
        container: null,
      });
    },
    closeLoader() {
      this.loadingComponent.close();
    },
    checkForm() {
      this.errors = [];

      if (!this.lot.status) {
        this.errors.push("Lot has no Status selected");
      }
      if (!this.lot.title || this.lot.title == "") {
        this.errors.push("Lot has no title");
      }
      if (!this.lot.imageUrls.hero) {
        this.errors.push("Lot has no hero image");
      }
      if (this.lot.imageUrls.featured.length > 8) {
        const over = this.lot.imageUrls.featured.length - 8;
        this.errors.push(
          `Only 8 featured images allowed. Please remove ${over} images`
        );
      }
      if (
        this.lot.bidInfo.reservePrice == null ||
        this.lot.bidInfo.startingBid == null ||
        this.lot.bidInfo.bidIncrement == null ||
        this.lot.salePrice == null ||
        this.lot.priceRange.min == null ||
        this.lot.priceRange.max == null
      ) {
        this.errors.push("Lot bidding amounts are incomplete");
      }

      if (this.lot.soldPrice == null && this.lot.status == "sold")
        this.errors.push("Lot has no sold price");

      return this.errors.length == 0;
    },

    async upload() {
      try {
        this.lot.imageUrls.featured = this.$refs.featuredGallery.thumbnails;
        this.lot.imageUrls.exterior = this.$refs.exteriorGallery.thumbnails;
        this.lot.imageUrls.interior = this.$refs.interiorGallery.thumbnails;
        this.lot.imageUrls.mechanical = this.$refs.mechanicalGallery.thumbnails;
        this.lot.imageUrls.document = this.$refs.documentGallery.thumbnails;

        const info = this.$refs.infoText.info;

        for (const i of info) {
          i.bulletPoints = i.bulletPoints.filter(
            (bulletPoint) => bulletPoint.bullet.length > 1
          );
        }

        const overview = this.$refs.OverviewText.info;

        for (const o of overview) {
          o.bulletPoints = o.bulletPoints.filter(
            (bulletPoint) => bulletPoint.bullet.length > 1
          );
        }

        this.lot.overview = this.$refs.OverviewText.info;

        if (this.lot.keywords)
          this.lot.keywords = this.lot.keywords
            .map((tag) => tag.trim())
            .filter((tag) => tag.length > 1);
        else this.lot.keywords = [];

        let succ = this.checkForm();

        if (!succ) {
          console.log("Check form failed");
          return;
        }

        this.openLoader();
        const loadingRef = this.loadingComponent;

        if (this.newLot)
          this.lot.createdAt = fire.firestore.FieldValue.serverTimestamp();
        else this.lot.modifiedAt = fire.firestore.FieldValue.serverTimestamp();

        await this.$store.dispatch("admin/upsertLot", this.lot);
        console.log("Uploaded Lot:");
        this.$swal("Success", "Uploaded Lot", "success");

        console.dir(this.lot);
        loadingRef.close();
      } catch (error) {
        console.log("Failed to upload lot");
        console.dir(error);
        this.$swal(
          "Upload Failed",
          "Unable to upload lot: " + error.message,
          "error"
        );
      }
    },
    uploadNew() {},
    convertToEmbeddedLink() {
      let embedUrl = this.getYoutubeEmbeddedUrl(this.lot.video_url);
      if (embedUrl) this.lot.video_url = embedUrl;
      else {
        this.$swal("Can't convert URL", "URL missing v tag. ", "error");
      }
    },

    numberToWords(num) {
      return numToWords(num);
    },

    addToFeatured(thumb) {
      console.log("Add to featured event: ");

      this.$refs.featuredGallery.thumbnails.push(thumb);
    },
    setHero(thumb) {
      this.lot.imageUrls.hero = thumb.url;
    },
  },
  computed: {
    formattedStartDate: {
      get() {
        if (this.lot.bidding_starts) {
          return new Date(this.lot.bidding_starts * 1000);
        } else {
          let date = new Date();
          date.setHours(8, 0, 0);
          return null;
        }
      },
      set(val) {
        this.lot.bidding_starts = Math.round(val.getTime() / 1000);
      },
    },

    formattedEndDate: {
      get() {
        if (this.lot.bidding_ends) {
          return new Date(this.lot.bidding_ends * 1000);
        } else {
          let date = new Date();
          date.setDate(date.getDate() + 1);
          date.setHours(16, 0, 0);
          return null;
        }
      },
      set(val) {
        this.lot.bidding_ends = Math.round(val.getTime() / 1000);
      },
    },
  },
  watch: {
    $route() {
      this.lot = {
        status: "For Sale",
        bidInfo: {},
        priceRange: {},
        infoText: [],
        imageUrls: {
          hero: null,
          featured: [],
          exterior: [],
          interior: [],
          mechanical: [],
          document: [],
        },
        keywords: [],
        active: true,
        featured: false,
      };
      this.lotId = this.$route.params.id;
      this.newLot = this.$route.params.id == "new";
      this.loadingComponent = "";
      this.failedNotification = false;
      this.successNotification = false;
      this.errors = [];
      this.init();
    },
  },
  components: { LotInformationText, SortableUpload },
  created() {
    this.init();
  },
};

const arr = (x) => Array.from(x);
const num = (x) => Number(x) || 0;
const isEmpty = (xs) => xs.length === 0;
const take = (n) => (xs) => xs.slice(0, n);
const drop = (n) => (xs) => xs.slice(n);
const reverse = (xs) => xs.slice(0).reverse();
const comp = (f) => (g) => (x) => f(g(x));
const not = (x) => !x;
const chunk = (n) => (xs) =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

// numToWords :: (Number a, String a) => a -> String
const numToWords = (n) => {
  if (!n) {
    return "";
  }
  let a = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  let b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  let g = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
  ];
  // this part is really nasty still
  // it might edit this again later to show how Monoids could fix this up
  let makeGroup = ([ones, tens, huns]) => {
    return [
      num(huns) === 0 ? "" : a[huns] + " hundred ",
      num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "",
      a[tens + ones] || a[ones],
    ].join("");
  };
  // "thousands" constructor; no real good names for this, i guess
  let thousand = (group, i) => (group === "" ? group : `${group} ${g[i]}`);
  // execute !
  if (typeof n === "number") return numToWords(String(n));
  if (n === "0") return "zero";
  return comp(chunk(3))(reverse)(arr(n))
    .map(makeGroup)
    .map(thousand)
    .filter(comp(not)(isEmpty))
    .reverse()
    .join(" ");
};
</script>

<style lang="scss" scoped>
@import "@/assets/bulmaVariables.scss";
.containerWrapper {
  margin-top: 50px;
  margin-bottom: 100px;
  margin-left: 10vw;
  margin-right: 10vw;
  text-align: left;
}
.box {
  padding: 50px;
}

.switch input[type="checkbox"]:checked + .check {
  background: $primary !important;
}

.mainImageWrapper {
  background-color: rgb(233, 233, 233) !important;
  height: 300px;
  width: 450px;
}
</style>
