<template>
  <div class="control has-icons-left has-icons-right">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <ValidationProvider
        rules="required|email"
        name="Email"
        v-slot="{ errors, valid }"
      >
        <b-field
          v-if="!subscribed && !loggedIn"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
        >
          <b-input
            v-model="emailSubscribe"
            class="is-large subscribeInput"
            type="email"
            icon="email"
            placeholder="Email"
          >
          </b-input>
        </b-field>
      </ValidationProvider>

      <b-button
        v-if="!subscribed && !loggedIn"
        @click="handleSubmit(subscribe)"
        class="mt-2 mb-1"
        size="is-medium"
        type="is-primary"
        :loading="subscribeLoading"
        >Subscribe</b-button
      >
      <b-button
        v-if="!subscribed && loggedIn"
        @click="subscribe"
        class="mt-2 mb-1"
        size="is-medium"
        type="is-primary"
        :loading="subscribeLoading"
        >Subscribe</b-button
      >
      <b-button
        v-if="subscribed"
        class="mt-2 mb-1"
        size="is-medium"
        type="is-primary"
        >You're Subscribed!</b-button
      >
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import subscribeToNewsletter from "../utils/subscribe";
import { ga } from "../utils/analytics";

export default {
  data() {
    return {
      emailSubscribe: "",
      subscribeLoading: false,
      subscribed: false,
    };
  },
  methods: {
    async subscribe() {
      try {
        console.dir(this.$store.state.user);
        this.subscribeLoading = true;
        const userEmail = this.loggedIn
          ? this.$store.state.user.email
          : this.emailSubscribe;
        console.log("User Email: " + userEmail);
        const succ = await subscribeToNewsletter(userEmail);

        if (!succ) throw "Unable to Subscribe";

        ga.subscribeEvent(this.loggedIn);
        this.subscribed = true;
        this.subscribeLoading = false;

        this.emailSubscribe = "";
        this.$refs.observer.reset();
      } catch (error) {
        this.subscribeLoading = false;
        this.$buefy.toast.open({
          message: `Unable to subscribe to newsletter. Please try again later`,
          type: "is-danger",
        });
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.user.id !== null;
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
};
</script>

<style></style>
