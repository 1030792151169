import { firestoreAction } from "vuexfire";
import { fire, db } from "@/main";
const namespaced = true;

const state = { lots: [] };

const actions = {
  bindLots: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "lots",
      db.collection("lots").orderBy("createdAt")
    );
  }),

  getLots: (state) => async () => {
    const ref = await db.collection("lots").get();

    console.log("Lots?");

    console.dir(ref.data());

    state.lots = ref.data();
  },

  async addInterestRequest(context, request) {
    console.log("addInterestRequest");
    console.dir(request);
    const id = request.lotId + "-" + request.userId;
    await db.collection("interestRequests").doc(id).set({
      lotId: request.lotId,
      userId: request.userId,
      createdAt: fire.firestore.FieldValue.serverTimestamp(),
    });
  },
};

const mutations = {
  SET_LOTS(state, l) {
    state.lots = l;
  },
};

const getters = {
  getLot: () => async (id) => {
    return await db.collection("lots").doc(id).get();
  },
  interestRequestExists: () => async (lotId, userId) => {
    const id = lotId + "-" + userId;
    const request = await db.collection("interestRequests").doc(id).get();

    return request.exists;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
