<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title monFontBold">Edit Lot</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body has-text-centered">
      <quick-edit @success="$parent.close()" :lotId="lotId"></quick-edit>
    </section>
  </div>
</template>

<script>
import QuickEdit from "./QuickEdit.vue";
export default {
  components: { QuickEdit },
  props: {
    lotId: { type: String, required: true },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  width: 700px;
}

@media only screen and (max-width: 768px) {
  .modal-card {
    width: 100vw;
  }
}

.sliderField {
  margin: 50px;
  margin-top: 10px;
}
</style>
