<template>
  <div>
    <p class="title monFont500">Let's verify your email address</p>
    <p class="mb-5">
      Click on the link in the email we sent to: <br />
      <b> {{ email }} </b>
    </p>

    <b-button
      expanded
      :loading="verifyLoading"
      class="mb-5 verifyBtn"
      type="is-primary"
      @click="refresh"
      >I have verified my email</b-button
    >
    <p>Haven't received it?</p>
    <b-button :loading="loading" @click="sendEmail">Resend Email</b-button>
  </div>
</template>

<script>
import { auth } from "@/main";

export default {
  props: {
    email: { type: String },
  },
  data() {
    return {
      loading: false,
      verifyLoading: false,
    };
  },
  methods: {
    async refresh() {
      try {
        this.verifyLoading = true;
        await auth.currentUser.reload();
        this.verifyLoading = false;
        const userAfter = auth.currentUser;
        if (userAfter.emailVerified) {
          this.$store.commit("user/SET_EMAIL_VERIFIED", true);
          this.$emit("verifySucceeded");
        } else {
          this.$swal(
            "Unable to verify email",
            "Please make sure to click the link in the email we sent you",
            "error"
          );
        }
      } catch (error) {
        console.dir(error);
        if (error.code === "auth/user-token-expired") {
          this.$swal(
            "Session Expired",
            "Your session has expired. Please login again or contact us for help",
            "error"
          );
        } else {
          this.$swal(
            "An unexpected error occured",
            "Please contact us if this error persists. (" + error.code + ")",
            "error"
          );
        }
      }
      //  window.location.replace("/");
    },
    async sendEmail() {
      this.loading = true;
      await auth.currentUser.sendEmailVerification();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/bulmaVariables.scss";
.successBox {
  width: 700px;
  margin: 10vh auto;
  padding: 50px;
}

h2 {
  font-size: 32px;
}

@media (max-width: 768px) {
  .successBox {
    width: 70vw;
  }
  .title {
    font-size: 24px;
  }
}
</style>
