<template>
  <div class="outer">
    <h2 class="title monFont500">Interest Requests</h2>
    <b-table
      sticky-header
      bordered
      class="wrapper has-text-left"
      :data="interestRequests"
      default-sort="createdAt"
      default-sort-direction="desc"
      centered
    >
      <b-table-column field="userId" label="Name" sortable v-slot="props">
        {{ getUserName(props.row.userId) }}
      </b-table-column>
      <b-table-column field="userId" label="Surname" sortable v-slot="props">
        {{ getUserSurname(props.row.userId) }}
      </b-table-column>
      <b-table-column field="userId" label="Email" sortable v-slot="props">
        {{ getUserEmail(props.row.userId) }}
      </b-table-column>
      <b-table-column field="userId" label="Number" v-slot="props">
        {{ getUserPhoneNumber(props.row.userId) }}
      </b-table-column>
      <b-table-column field="lotId" label="Lot ID" v-slot="props">
        {{ props.row.lotId }}
      </b-table-column>
      <b-table-column field="lotId" label="Lot Title" sortable v-slot="props">
        {{ getLotTitle(props.row.lotId) }}
      </b-table-column>
      <b-table-column field="createdAt" label="Sent At" sortable v-slot="props">
        {{
          new Date(props.row.createdAt.toDate()).toLocaleDateString("en-ZA", {
            dateStyle: "medium",
          }) +
          " " +
          new Date(props.row.createdAt.toDate()).toLocaleTimeString("en-ZA")
        }}
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">Nothing today</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    dailySummary: { type: Boolean, default: false },
  },
  data() {
    return {
      showOlderMessages: false,
      loading: true,
      perPage: "10",
    };
  },

  methods: {
    getUser(uid) {
      return this.$store.getters["admin/getUserById"](uid);
    },

    getUserName(uid) {
      return this.getUser(uid)?.firstName ?? "Not Found";
    },
    getUserSurname(uid) {
      return this.getUser(uid)?.surname ?? "Not Found";
    },
    getUserEmail(uid) {
      return this.getUser(uid)?.email ?? "Not Found";
    },
    getUserPhoneNumber(uid) {
      return this.getUser(uid)?.phoneNumber ?? "Not Found";
    },
    getLotTitle(uid) {
      return this.$store.getters["admin/getLotTitle"](uid) ?? "Not Found";
    },
  },

  computed: {
    interestRequests() {
      if (!this.dailySummary) return this.$store.state.admin.interestRequests;

      const today = dayjs(new Date());

      return this.$store.state.admin.interestRequests.filter((m) =>
        dayjs(m.createdAt.toDate()).isSame(today, "day")
      );
    },
  },
  created() {},
};
</script>

<style scoped>
.outer {
  max-height: 100px !important;
}
</style>
