<template>
  <section class="wrapper">
    <div class="columns">
      <div class="column is-5">
        <h2 class="title monFontBold">Auctions</h2>
        <b-table
          :data="auctions"
          sticky-header
          default-sort="dateRange.start"
          default-sort-direction="desc"
          sort-icon="arrow-up"
          sort-icon-size="is-regular"
          :selected.sync="selectedRow"
          paginated
          :per-page="perPage"
          hoverable
          focusable
        >
          <b-table-column
            field="dateRange.start"
            label="Starts"
            sortable
            centered
            v-slot="props"
          >
            {{
              props.row.dateRange.start.toDate().toLocaleDateString("en-ZA", {
                dateStyle: "medium",
              })
            }}
          </b-table-column>

          <b-table-column
            field="end"
            label="Ends"
            sortable
            centered
            v-slot="props"
          >
            {{
              props.row.dateRange.end.toDate().toLocaleDateString("en-ZA", {
                dateStyle: "medium",
              })
            }}
          </b-table-column>
          <template #bottom-left>
            <b-button
              :disabled="selectedRow == null"
              type="is-primary"
              @click="showAuctionModal('edit')"
              >Edit Auction</b-button
            >

            <b-button
              class="ml-5 mr-5"
              icon-left="plus"
              icon-pack="fa"
              type="is-success"
              @click="showAuctionModal('new')"
            >
              Add Auction
            </b-button>

            <b-button
              class="ml-5"
              icon-left="delete"
              type="is-danger"
              size="is-small"
              @click="confirmDelete"
              :disabled="selectedRow == null"
            >
              Delete Auction
            </b-button>
          </template>
        </b-table>
      </div>

      <div class="column is-6 is-offset-1">
        <h2 class="title monFontBold">Auction Lots</h2>
        <b-table
          v-if="selectedRow"
          :key="lotTableKey"
          :data="selectedRow.lots"
          :checked-rows.sync="checkedRows"
          checkable
          sticky-header
          sticky-checkbox
          paginated
          :per-page="perPage"
          hoverable
        >
          <b-table-column field="lotId" label="Lot #" centered v-slot="props">
            {{ props.row }}
          </b-table-column>

          <b-table-column field="title" label="Title" centered v-slot="props">
            {{ $store.getters["admin/getLotTitle"](props.row) }}
          </b-table-column>
          <template #bottom-left>
            <b-button
              :disabled="checkedRows.length === 0"
              :loading="addLotsLoading"
              type="is-danger"
              @click="removeLots"
              >Remove from Auction</b-button
            >
          </template>
        </b-table>

        <div>
          <h2 class="title monFontBold">Verified Bidder</h2>
          <b-table
            v-if="selectedRow"
            :key="bidderTableKey"
            :data="selectedRow.verifiedUsers"
            sticky-header
            paginated
            :per-page="perPage"
            hoverable
          >
            <b-table-column field="user" label="Name" centered v-slot="props">
              {{ getUserName(props.row) }}
            </b-table-column>

            <!-- <template #bottom-left>
              <b-field label="">
                <b-autocomplete
                  v-model="searchedName"
                  placeholder="Enter user name"
                  :data="filteredInput"
                  field="firstName"
                  @select="(option) => (inputSelected = option)"
                >
                </b-autocomplete>

                <b-button type="is-success">Add bidder</b-button>
              </b-field>
            <b-button
                :disabled="checkedRows.length === 0"
                :loading="addLotsLoading"
                type="is-danger"
                @click="removeLots"
                >Remove from Auction</b-button
              > 
            </template> -->
          </b-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AuctionModal from "@/components/admin/AuctionModal.vue";
export default {
  data() {
    return {
      selectedRow: null,
      perPage: 10,
      checkedRows: [],
      draggingRow: null,
      draggingRowIndex: null,
      draggingColumn: null,
      draggingColumnIndex: null,
      loadingComponent: null,
      addLotsLoading: false,
      lotTableKey: 1,
      bidderTableKey: 1,
      auctionLots: [],
      searchedName: "",
      inputSelected: null,
    };
  },
  computed: {
    auctions() {
      console.dir(this.$store.state.admin.auctions);
      return this.$store.state.admin.auctions;
    },

    filteredInput() {
      return this.$store.state.admin.users.filter((option) => {
        return (
          option.firstName
            .toString()
            .toLowerCase()
            .indexOf(this.searchedName.toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {
    setAuctionLots() {
      console.log("Auction lots computing");
      console.dir(this.$store.state.admin.lots);
      console.log("Filter: ");
      console.dir(this.selectedRow?.lots);
      const filterLots = this.selectedRow?.lots;
      if (filterLots) {
        this.auctionLots = this.$store.state.admin.lots.filter((l) =>
          filterLots.includes(l.lotId)
        );
      } else this.auctionLots = [];
    },
    getUserName(id) {
      const user = this.$store.state.admin.users.find((u) => u.id === id);

      const str = user?.firstName + " " + user?.surname;

      return str ?? "Not found";
    },
    async addAuction() {},
    showAuctionModal(action) {
      this.$buefy.modal.open({
        parent: this,
        component: AuctionModal,
        props: {
          action: action,
          existingDate: {
            start: this.selectedRow?.dateRange.start.toDate(),
            end: this.selectedRow?.dateRange.end.toDate(),
          },
          auctionId: this.selectedRow?.id,
        },
        events: {
          success: (val) => this.addAuction(val),
        },
        hasModalCard: true,
        trapFocus: true,
      });
    },

    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting Auction: ${new Date(
          this.selectedRow.dateRange.start
        ).toLocaleDateString("en-ZA", {
          dateStyle: "medium",
        })}`,
        message:
          "Are you sure you want to <b>delete</b> this auction? This action cannot be undone.",
        confirmText: "Delete Auction",
        type: "is-danger",

        hasIcon: true,
        onConfirm: () => this.deleteClicked(),
      });
    },
    deleteClicked() {
      this.openLoader();
      const loadingRef = this.loadingComponent;
      this.$store
        .dispatch("admin/deleteAuction", this.selectedRow)
        .then((res) => {
          loadingRef.close();
          console.log(res);
          this.$buefy.notification.open({
            duration: 5000,
            message: `Successfully deleted auction`,
            position: "is-bottom-right",
            type: "is-success",
            hasIcon: true,
          });
        })
        .catch((err) => {
          loadingRef.close();
          console.log(err);
          this.$buefy.notification.open({
            duration: 5000,
            message: `Failed to delete auction`,
            position: "is-bottom-right",
            type: "is-danger",
            hasIcon: true,
          });
        });
    },

    async removeLots() {
      try {
        this.addLotsLoading = true;

        const ids = this.checkedRows;
        console.log("ids: " + ids);

        await this.$store.dispatch("admin/removeAuctionLots", {
          auctionId: this.selectedRow.id,
          lotIds: ids,
        });
        this.$buefy.notification.open({
          duration: 5000,
          message: `Successfully removed lots`,
          position: "is-bottom-right",
          type: "is-success",
          hasIcon: true,
        });
        this.addLotsLoading = false;
        this.selectedRow.lots = this.selectedRow.lots.filter(
          (id) => !ids.includes(id)
        );

        this.checkedRows = [];
      } catch (error) {
        this.addLotsLoading = false;

        console.log(error);
        this.$buefy.notification.open({
          duration: 5000,
          message: `Failed to remove lots`,
          position: "is-bottom-right",
          type: "is-danger",
          hasIcon: true,
        });
      }
    },
    openLoader() {
      this.loadingComponent = this.$buefy.loading.open({
        container: null,
      });
    },
    openAuctionDialog() {},
    dragstart(payload) {
      this.draggingRow = payload.row;
      this.draggingRowIndex = payload.index;
      payload.event.dataTransfer.effectAllowed = "copy";
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = "copy";
      payload.event.target.closest("tr").classList.add("is-selected");
      payload.event.preventDefault();
    },
    dragleave(payload) {
      payload.event.target.closest("tr").classList.remove("is-selected");
      payload.event.preventDefault();
    },
    drop(payload) {
      payload.event.target.closest("tr").classList.remove("is-selected");
      const droppedOnRowIndex = payload.index;
      this.$buefy.toast.open(
        `Moved ${this.draggingRow.first_name} from row ${
          this.draggingRowIndex + 1
        } to ${droppedOnRowIndex + 1}`
      );
    },
  },
  created() {
    this.$store.dispatch("admin/bindAuctions").then(() => {
      this.loading = false;
      this.selectedRow = this.$store.state.admin.auctions[0];
      this.setAuctionLots();
    });
  },
};
</script>

<style scoped>
.wrapper {
  margin: 50px 100px;
}
</style>
