import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Lot from "../views/LotView.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import Register from "../views/Register.vue";
import Buy from "../views/Buy.vue";
import Sell from "../views/Sell.vue";
import Auction from "../views/Auction.vue";
import adminRoutes from "./admin";
import Faq from "../views/faq.vue";
import Terms from "../views/terms.vue";
import Contact from "../views/Contact.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/buy",
    name: "Buy",
    component: Buy,
  },
  {
    path: "/sell",
    name: "Sell",
    component: Sell,
    beforeEnter: authGuard,
  },
  {
    path: "/auction",
    name: "Auction",
    component: Auction,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: Faq,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/lots/:id",
    name: "Lot",
    component: Lot,
  },
  ...adminRoutes,
];

function authGuard(to, from, next) {
  if (store.state.user.id && store.state.user.emailVerified) {
    next();
  } else {
    next({ name: "Login", query: { from: to.name } });
  }
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
