<template>
  <section :class="['wrapper', { opacityLowered: lot.status === 'Sold' }]">
    <div class="upperHalf card ml-1 mr-1 mb-1" @click="$emit('lot-click')">
      <span v-if="lot.status === 'Sold'" class="ribbon monFontBold">SOLD</span>
      <div class="card-image has-text-centered">
        <figure class="image imageWrapper gradient">
          <transition name="fade">
            <img
              v-on:load="loaded = true"
              v-show="loaded"
              class="lotImage fade-in"
              :src="imageUrl()"
              alt="Placeholder image"
            />
          </transition>
        </figure>
      </div>
      <div class="card-content">
        <div class="media">
          <div class="media-content lotTitle">
            <p class="monFont500">
              <router-link :to="`/lots/${lot.lotId}`">{{
                lot.title
              }}</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="content box monFontReg lotInfo ml-1 mr-1 mb-1">
      <p class="mt-0"><span class="monFont500">YEAR:</span> {{ lot.year }}</p>
      <p v-if="view === 'buy'">
        <span class="monFont500">SALE PRICE: </span>
        {{ formatNumber(lot.salePrice) }}
      </p>
      <p v-else class="mb-0">
        <span class="monFont500">ESTIMATED RANGE:</span>
      </p>
      <p v-if="view !== 'buy'">
        {{
          `${formatNumber(lot.priceRange.min)} - ${formatNumber(
            lot.priceRange.max
          )}`
        }}
      </p>

      <p>
        <span class="monFont500">STATUS:&nbsp;</span>
        <span class="lotStatus monFontBold"> {{ lot.status }} </span>
      </p>
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export default {
  props: {
    lot: { type: Object, required: true },
    view: { type: String, default: "standard" },
  },
  data() {
    return {
      loaded: false,
      placeholder: true,
      currencyFormat: {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 0,
      },
    };
  },

  methods: {
    imageUrl() {
      if (this.lot?.imageUrls?.hero) {
        return this.lot?.imageUrls.hero;
      } else if (this.lot["imageUrls.hero"]) {
        return this.lot["imageUrls.hero"];
      }
      return "../assets/placeholder16by9.jpg";
    },
    formatNumber(num) {
      return "R " + num.toLocaleString("en-ZA");
    },

    dateFromFormat(d) {
      return dayjs(d * 1000).fromNow();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/bulmaVariables.scss";

.ribbon {
  position: absolute;
  z-index: 5;
  background-color: red;
  left: -55px;
  top: 20px;
  transform: rotate(-45deg);
  width: 200px;
  color: white;
  padding: 10px;
  filter: opacity(1) !important;
}
.opacityLowered {
  filter: opacity(0.6);
}

a {
  color: white;
}

a:hover {
  color: white;
  text-decoration: underline;
}

.fade-enter-active {
  transition: opacity 1s ease-in-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

.upperHalf {
  cursor: pointer;
  overflow: hidden;
}

.imageWrapper {
  min-height: 300px;
}

.countBg {
  color: #3c176f;
}

.lotImage {
  height: 300px;
  max-width: 470px;
  object-fit: cover;
}

.lotTitle {
  width: 100%;
  padding: 10px;
  padding-left: 20px;
  text-align: left;
  background-color: $primary;
  height: 75px;
  overflow: hidden;
}

.media {
  width: 100%;
  margin: 0 !important;
}

.content {
  height: 200px;
  margin-top: 10px;
  text-align: left;
}

.lotInfo {
  font-size: 22px;
}

.lotStatus {
  color: red;
}
.card-content {
  padding: 0;
  padding-top: 10px;
}

.lotTitle p {
  color: white;
  font-size: 30px;
  line-height: 110%;
}

.gradient {
  background: $primary;
  animation-name: example;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-direction: alternate;
}

@keyframes example {
  from {
    background-color: $primary;
  }
  to {
    background-color: $secondary;
  }
}

// .gradient {
//   animation-duration: 3s;
//   animation-fill-mode: forwards;
//   animation-iteration-count: infinite;
//   animation-name: placeHolderShimmer;
//   animation-timing-function: linear;
//   background: $primary;
//   background: linear-gradient(to right, #004f4c 25%, #00918c 50%, #004f4c 75%);

//   position: relative;
// }

// @keyframes placeHolderShimmer {
//   0% {
//     background-position: -800px 0;
//   }
//   100% {
//     background-position: 800px 0;
//   }
// }
</style>
