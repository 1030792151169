import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions
import "firebase/analytics";
import "firebase/performance";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSmoothScroll from "vue2-smooth-scroll";
import "./validation";
import "./assets/main.scss";
import VueMeta from "vue-meta";
import { firestorePlugin } from "vuefire";
import vueAwesomeCountdown from "vue-awesome-countdown";

Vue.use(firestorePlugin);
Vue.use(VueMeta);
Vue.use(Buefy);
Vue.use(VueSmoothScroll, { duration: 800 });
Vue.use(vueAwesomeCountdown, "vac");

const swalOptions = {
  confirmButtonColor: "#004f4c",
  cancelButtonColor: "#ff7674",
};
Vue.use(VueSweetalert2, swalOptions);

Vue.config.productionTip = false;

let firebaseConfig = null;

console.log("ENV: " + process.env.NODE_ENV);

if (
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "local "
) {
  firebaseConfig = {
    apiKey: "AIzaSyDQ_IH26IPi2hIRCcnMH-tRIeZRyrv3rSY",
    authDomain: "classiccarcollections-dev.firebaseapp.com",
    projectId: "classiccarcollections-dev",
    storageBucket: "classiccarcollections-dev.appspot.com",
    messagingSenderId: "184444684370",
    appId: "1:184444684370:web:d2979e906a1aef5e0d378b",
    measurementId: "G-02C7608P0W",
  };
} else if (process.env.NODE_ENV === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyAaU9ENdgIH8OJEO8Yr0t1eQykRPE78JHw",
    authDomain: "classiccarcollections-prod.firebaseapp.com",
    projectId: "classiccarcollections-prod",
    storageBucket: "classiccarcollections-prod.appspot.com",
    messagingSenderId: "24554948194",
    appId: "1:24554948194:web:64e62a3cff9a75f6ac333b",
    measurementId: "G-NEMBETQKDS",
  };
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.performance();

if (process.env.NODE_ENV == "local ") {
  console.log("Using local emulator");
  firebase.firestore().useEmulator("192.168.101.249", 8086);
  firebase
    .auth()
    .useEmulator("http://192.168.101.249:9099", { disableWarnings: true });
  firebase.functions().useEmulator("192.168.101.249", 5001);
  firebase.storage().useEmulator("192.168.101.249", 9199);
}

export const functions = firebase.functions();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const fire = firebase;
export const analytics = firebase.analytics();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
