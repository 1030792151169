<template>
  <section class="wrapper">
    <div class="columns is-gapless is-multiline is-centered">
      <div
        v-for="(lot, index) in lots"
        :key="index"
        class="column is-one-quarter-desktop is-half-tablet"
      >
        <lot-card
          :lot="lot"
          :view="'buy'"
          @lot-click="$router.push(`/lots/${lot.lotId}`)"
        ></lot-card>
      </div>
      <div
        v-for="i in numPlaceholders"
        :key="i"
        class="column is-one-quarter-desktop is-half-tablet"
      >
        <lot-card-placeholder></lot-card-placeholder>
      </div>
    </div>
  </section>
</template>

<script>
import { db } from "@/main";
import LotCard from "@/components/LotCard.vue";
import LotCardPlaceholder from "@/components/placeholders/LotCardPlaceholder.vue";

export default {
  components: { LotCard, LotCardPlaceholder },

  data() {
    return {
      lots: [],
      numPlaceholders: 4,
    };
  },
  created() {
    db.collection("lots")
      .where("featured", "==", true)
      .limit(4)
      .get()
      .then((snapshot) => {
        this.numPlaceholders = 0;
        snapshot.docs.forEach((doc) => {
          this.lots.push(doc.data());
        });
      });
  },
};
</script>

<style scoped>
/* .wrapper {
  margin-bottom: 80px;
  min-height: 595px;
}

@media (max-width: 899px) {
  .wrapper {
    min-height: 100vh;
  }
} */
</style>
