import axios from "axios";

const sendContactMessage = async (contactForm) => {
  const url = process.env.VUE_APP_FUNCTIONS_API;

  console.log("URl: " + url);
  const payload = { contactForm: contactForm };
  try {
    const res = await axios.post(url + "/users-user/contact", payload);
    console.dir(res);
    if (res.data.success) return true;
    else return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default sendContactMessage;
