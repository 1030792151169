<template>
  <div class="wrapper">
    <div class="columns">
      <div
        class="
          column
          is-4-desktop is-offset-4-desktop is-8-tablet is-offset-2-tablet
        "
      >
        <h2 class="mainHeader monFontBold p-1">Contact Us</h2>
        <div class="carOverview contactCard monFont500 box">
          <h4 class="monFontBold mb-4">Contact Information</h4>
          <p>
            <a href="mailto:sales@estateauctionhouse.com">
              <b-icon class="pr-2" icon="email"></b-icon>
              sales@estateauctionhouse.com</a
            >
          </p>
          <p>
            <a href="tel:+27674016020">
              <b-icon class="pr-2" icon="phone"></b-icon> +27 67 401 6020</a
            >
          </p>
        </div>
        <div
          v-if="successfulMessage"
          class="box succBox has-text-centered successBox"
        >
          <span class="icon has-text-info">
            <i class="mdi mdi-check-circle succIcon"></i>
          </span>
          <p class="title monFont500 mt-5">Message Sent</p>
          <p class="subtitle monFontReg">We'll be in touch!</p>
        </div>
        <div v-if="!successfulMessage" class="box contactForm">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <ValidationProvider
              rules="required"
              name="Name"
              v-slot="{ errors, valid }"
            >
              <b-field
                position="is-centered"
                class="margins"
                label="Name"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-input v-model="firstName" placeholder="Your name"> </b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              name="Surname"
              autocomplete="family-name"
              v-slot="{ errors, valid }"
            >
              <b-field
                position="is-centered"
                class="margins"
                label="Surname"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-input
                  type="text"
                  v-model="surname"
                  placeholder="Your surname"
                  required
                >
                </b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              rules="required|email"
              name="Email"
              v-slot="{ errors, valid }"
            >
              <b-field
                position="is-centered"
                class="margins"
                label="Email"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-input type="email" v-model="email" placeholder="Your email">
                </b-input>
              </b-field>
            </ValidationProvider>
            <b-field
              position="is-centered"
              class="margins"
              label="Phone Number"
              :message="phoneFieldMessage"
              :type="{
                'is-danger': phoneNumberInvalid,
                'is-success': phoneNumberValid,
              }"
            >
              <b-input
                autocomplete="tel"
                @blur="validateNumber"
                type="tel"
                id="phone"
              >
              </b-input>
            </b-field>
            <b-field label="Message">
              <b-input
                v-model="message"
                maxlength="300"
                type="textarea"
              ></b-input>
            </b-field>
            <b-field position="is-centered" class="margins" label="I am a">
              <b-checkbox native-value="buyer" v-model="userType" class="mr-3"
                >Buyer</b-checkbox
              >
              <b-checkbox native-value="seller" v-model="userType" class="ml-3"
                >Seller</b-checkbox
              >
            </b-field>
            <b-button
              :loading="isLoading"
              class="mt-4"
              type="is-primary is-large"
              @click="handleSubmit(sendMessage)"
              >Send Message</b-button
            >
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SendContactMessage from "../utils/contact";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
let iti = null;
export default {
  metaInfo: {
    title: "Contact Us",
    meta: [
      {
        name: "description",
        content: "How can we help you? Get in touch with us via email or phone",
      },
    ],
  },
  data() {
    return {
      successfulMessage: false,
      firstName: "",
      surname: "",
      email: "",
      userType: [],
      error: " ",
      message: "",
      passwordFocus: false,
      isLoading: false,
      selectedCountry: "",
      phoneFieldMessage: "",
      phoneNumberValid: false,
      phoneNumberInvalid: false,
      countries: [
        { name: "Albania", code: "AL" },
        { name: "Åland Islands", code: "AX" },
        { name: "Algeria", code: "DZ" },
        { name: "American Samoa", code: "AS" },
        { name: "Andorra", code: "AD" },
        { name: "Angola", code: "AO" },
        { name: "Anguilla", code: "AI" },
        { name: "Argentina", code: "AR" },
        { name: "Armenia", code: "AM" },
        { name: "Aruba", code: "AW" },
        { name: "Australia", code: "AU" },
        { name: "Austria", code: "AT" },
        { name: "Azerbaijan", code: "AZ" },
        { name: "Bahamas ", code: "BS" },
        { name: "Bahrain", code: "BH" },
        { name: "Bangladesh", code: "BD" },
        { name: "Barbados", code: "BB" },
        { name: "Belarus", code: "BY" },
        { name: "Belgium", code: "BE" },
        { name: "Belize", code: "BZ" },
        { name: "Benin", code: "BJ" },
        { name: "Bermuda", code: "BM" },
        { name: "Bhutan", code: "BT" },
        { name: "Bolivia (Plurinational State of)", code: "BO" },
        { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
        { name: "Bosnia and Herzegovina", code: "BA" },
        { name: "Botswana", code: "BW" },
        { name: "Bouvet Island", code: "BV" },
        { name: "Brazil", code: "BR" },
        { name: "British Indian Ocean Territory", code: "IO" },
        { name: "Brunei Darussalam", code: "BN" },
        { name: "Bulgaria", code: "BG" },
        { name: "Burkina Faso", code: "BF" },
        { name: "Burundi", code: "BI" },
        { name: "Cabo Verde", code: "CV" },
        { name: "Cambodia", code: "KH" },
        { name: "Cameroon", code: "CM" },
        { name: "Canada", code: "CA" },
        { name: "Cayman Islands", code: "KY" },
        { name: "Central African Republic", code: "CF" },
        { name: "Chad", code: "TD" },
        { name: "Chile", code: "CL" },
        { name: "China", code: "CN" },
        { name: "Christmas Island", code: "CX" },
        { name: "Cocos (Keeling) Islands ", code: "CC" },
        { name: "Colombia", code: "CO" },
        { name: "Comoros ", code: "KM" },
        { name: "Congo ", code: "CG" },
        { name: "Cook Islands ", code: "CK" },
        { name: "Costa Rica", code: "CR" },
        { name: "Croatia", code: "HR" },
        { name: "Cuba", code: "CU" },
        { name: "Curaçao", code: "CW" },
        { name: "Cyprus", code: "CY" },
        { name: "Czechia", code: "CZ" },
        { name: "Côte d'Ivoire", code: "CI" },
        { name: "Denmark", code: "DK" },
        { name: "Djibouti", code: "DJ" },
        { name: "Dominica", code: "DM" },
        { name: "Dominican Republic", code: "DO" },
        { name: "Ecuador", code: "EC" },
        { name: "Egypt", code: "EG" },
        { name: "El Salvador", code: "SV" },
        { name: "Equatorial Guinea", code: "GQ" },
        { name: "Eritrea", code: "ER" },
        { name: "Estonia", code: "EE" },
        { name: "Eswatini", code: "SZ" },
        { name: "Ethiopia", code: "ET" },
        { name: "Falkland Islands", code: "FK" },
        { name: "Faroe Islands", code: "FO" },
        { name: "Fiji", code: "FJ" },
        { name: "Finland", code: "FI" },
        { name: "France", code: "FR" },
        { name: "Gabon", code: "GA" },
        { name: "Gambia ", code: "GM" },
        { name: "Georgia", code: "GE" },
        { name: "Germany", code: "DE" },
        { name: "Ghana", code: "GH" },
        { name: "Gibraltar", code: "GI" },
        { name: "Greece", code: "GR" },
        { name: "Greenland", code: "GL" },
        { name: "Grenada", code: "GD" },
        { name: "Guatemala", code: "GT" },
        { name: "Guinea", code: "GN" },
        { name: "Guinea-Bissau", code: "GW" },
        { name: "Guyana", code: "GY" },
        { name: "Haiti", code: "HT" },
        { name: "Honduras", code: "HN" },
        { name: "Hong Kong", code: "HK" },
        { name: "Hungary", code: "HU" },
        { name: "Iceland", code: "IS" },
        { name: "India", code: "IN" },
        { name: "Indonesia", code: "ID" },
        { name: "Iran", code: "IR" },
        { name: "Iraq", code: "IQ" },
        { name: "Ireland", code: "IE" },
        { name: "Isle of Man", code: "IM" },
        { name: "Israel", code: "IL" },
        { name: "Italy", code: "IT" },
        { name: "Jamaica", code: "JM" },
        { name: "Japan", code: "JP" },
        { name: "Jersey", code: "JE" },
        { name: "Jordan", code: "JO" },
        { name: "Kazakhstan", code: "KZ" },
        { name: "Kenya", code: "KE" },
        { name: "Kiribati", code: "KI" },
        { name: "Republic of Korea", code: "KR" },
        { name: "Kuwait", code: "KW" },
        { name: "Kyrgyzstan", code: "KG" },
        { name: "Latvia", code: "LV" },
        { name: "Lebanon", code: "LB" },
        { name: "Lesotho", code: "LS" },
        { name: "Liberia", code: "LR" },
        { name: "Libya", code: "LY" },
        { name: "Liechtenstein", code: "LI" },
        { name: "Lithuania", code: "LT" },
        { name: "Luxembourg", code: "LU" },
        { name: "Macao", code: "MO" },
        { name: "Madagascar", code: "MG" },
        { name: "Malawi", code: "MW" },
        { name: "Malaysia", code: "MY" },
        { name: "Maldives", code: "MV" },
        { name: "Mali", code: "ML" },
        { name: "Malta", code: "MT" },
        { name: "Marshall Islands ", code: "MH" },
        { name: "Martinique", code: "MQ" },
        { name: "Mauritania", code: "MR" },
        { name: "Mauritius", code: "MU" },
        { name: "Mayotte", code: "YT" },
        { name: "Mexico", code: "MX" },
        { name: "Moldova", code: "MD" },
        { name: "Monaco", code: "MC" },
        { name: "Mongolia", code: "MN" },
        { name: "Montenegro", code: "ME" },
        { name: "Montserrat", code: "MS" },
        { name: "Morocco", code: "MA" },
        { name: "Mozambique", code: "MZ" },
        { name: "Myanmar", code: "MM" },
        { name: "Namibia", code: "NA" },
        { name: "Nauru", code: "NR" },
        { name: "Nepal", code: "NP" },
        { name: "Netherlands ", code: "NL" },
        { name: "New Caledonia", code: "NC" },
        { name: "New Zealand", code: "NZ" },
        { name: "Nicaragua", code: "NI" },
        { name: "Niger ", code: "NE" },
        { name: "Nigeria", code: "NG" },
        { name: "Niue", code: "NU" },
        { name: "Norfolk Island", code: "NF" },
        { name: "Northern Mariana Islands ", code: "MP" },
        { name: "Norway", code: "NO" },
        { name: "Oman", code: "OM" },
        { name: "Pakistan", code: "PK" },
        { name: "Palau", code: "PW" },
        { name: "Palestine", code: "PS" },
        { name: "Panama", code: "PA" },
        { name: "Papua New Guinea", code: "PG" },
        { name: "Paraguay", code: "PY" },
        { name: "Peru", code: "PE" },
        { name: "Philippines ", code: "PH" },
        { name: "Pitcairn", code: "PN" },
        { name: "Poland", code: "PL" },
        { name: "Portugal", code: "PT" },
        { name: "Puerto Rico", code: "PR" },
        { name: "Qatar", code: "QA" },
        { name: "Romania", code: "RO" },
        { name: "Russian Federation ", code: "RU" },
        { name: "Rwanda", code: "RW" },
        { name: "Saint Barthélemy", code: "BL" },
        { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
        { name: "Saint Lucia", code: "LC" },
        { name: "Samoa", code: "WS" },
        { name: "San Marino", code: "SM" },
        { name: "Sao Tome and Principe", code: "ST" },
        { name: "Saudi Arabia", code: "SA" },
        { name: "Senegal", code: "SN" },
        { name: "Serbia", code: "RS" },
        { name: "Seychelles", code: "SC" },
        { name: "Sierra Leone", code: "SL" },
        { name: "Singapore", code: "SG" },
        { name: "Slovakia", code: "SK" },
        { name: "Slovenia", code: "SI" },
        { name: "Solomon Islands", code: "SB" },
        { name: "Somalia", code: "SO" },
        { name: "South Africa", code: "ZA" },
        { name: "Spain", code: "ES" },
        { name: "Sri Lanka", code: "LK" },
        { name: "Sudan ", code: "SD" },
        { name: "Sweden", code: "SE" },
        { name: "Switzerland", code: "CH" },
        { name: "Syrian Arab Republic", code: "SY" },
        { name: "Taiwan", code: "TW" },
        { name: "Tajikistan", code: "TJ" },
        { name: "Tanzania", code: "TZ" },
        { name: "Thailand", code: "TH" },
        { name: "Tokelau", code: "TK" },
        { name: "Tonga", code: "TO" },
        { name: "Trinidad and Tobago", code: "TT" },
        { name: "Tunisia", code: "TN" },
        { name: "Turkey", code: "TR" },
        { name: "Turkmenistan", code: "TM" },
        { name: "Uganda", code: "UG" },
        { name: "Ukraine", code: "UA" },
        { name: "United Arab Emirates", code: "AE" },
        { name: "United Kingdom", code: "GB" },
        { name: "United States of America", code: "US" },
        { name: "Uruguay", code: "UY" },
        { name: "Uzbekistan", code: "UZ" },
        { name: "Vanuatu", code: "VU" },
        { name: "Venezuela", code: "VE" },
        { name: "Vietnam", code: "VN" },
        { name: "Zambia", code: "ZM" },
        { name: "Zimbabwe", code: "ZW" },
      ],
    };
  },
  methods: {
    async sendMessage() {
      this.validateNumber();
      if (!this.phoneNumberValid) {
        return;
      }
      this.successfulMessage = false;
      this.isLoading = true;
      const number = iti.getNumber();

      try {
        await SendContactMessage({
          firstName: this.firstName,
          surname: this.surname,
          email: this.email,
          phoneNumber: number,
          message: this.message,
          userType: this.userType,
        });
        this.successfulMessage = true;
        this.isLoading = false;
        window.scrollTo(0, 0);
      } catch (error) {
        this.successfulMessage = false;

        this.isLoading = false;
        this.$swal(
          "Unable to send message",
          "Please try again later or contact us directly",
          "error"
        );
        console.error(error);
      }
    },
    validateNumber() {
      const number = iti.getNumber();
      const valid = iti.isValidNumber();
      this.phoneNumberValid = false;
      this.phoneNumberInvalid = false;
      this.phoneFieldMessage = "";

      if (valid) {
        this.phoneNumberValid = true;
      } else {
        this.phoneNumberInvalid = true;
        if (number?.length === 0) {
          this.phoneFieldMessage = "This field is required";
        } else {
          this.phoneFieldMessage = "Invalid Phone Number";
        }
      }
      console.log("Number: " + number);
      console.log("Valid: " + valid);
    },

    hasNumber(str) {
      const regex = /\d/;

      return regex.test(str);
    },
  },
  mounted() {
    const input = document.querySelector("#phone");

    import("intl-tel-input/build/js/utils.js").then((m) => {
      iti = intlTelInput(input, {
        utilsScript: m,
        preferredCountries: ["za", "gb", "us"],
        separateDialCode: true,
      });

      this.selectedCountry = iti.getSelectedCountryData().iso2.toUpperCase();
    });

    input.addEventListener("countrychange", () => {
      this.selectedCountry = iti.getSelectedCountryData().iso2.toUpperCase();
    });
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/bulmaVariables.scss";

.wrapper {
  margin-bottom: 50px;
}

.contactForm {
  margin: 0 20px;
}

.contactCard {
  margin: 20px;
  margin-top: 10px;
}
input {
  margin-left: 80px;
  margin-right: 80px;
}

.valid {
  color: rgb(0, 192, 0);
  font-weight: bold;
}
.notValid {
  color: rgba(163, 163, 163, 0.801);
}

.mainHeader {
  text-align: center;
  font-size: 32px;
  background-color: $primary;
  color: white;
  margin-bottom: 30px;
}
.succBox {
  padding: 50px;
}

.succBox p.title {
  font-size: 34px;
}
.succBox p.subtitle {
  font-size: 20px;
}
.succIcon {
  font-size: 100px;
  color: $primary;
}

.successBox {
  max-width: 550px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .successBox {
    width: 70vw;
  }
}
</style>
