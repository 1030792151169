import { required, confirmed, length, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

import { setInteractionMode } from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "This field is required",
});

extend("email", {
  ...email,
  message: "Must be a valid email",
});

extend("confirmed", {
  ...confirmed,
  message: "This field confirmation does not match",
});

extend("length", {
  ...length,
  message: "This field must have 2 options",
});

extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: `Must be at least {length} characters`,
});

extend("hasNumber", {
  validate(value) {
    const regex = /\d/;
    return regex.test(value);
  },
  message: "Must contain a number",
});
