<template>
  <div class="wrapper">
    <h1 class="title is-2 monFontBold mb-5">Daily Summary</h1>
    <div class="columns mt-5">
      <div class="column is-3">
        <contact-message-table :dailySummary="true"></contact-message-table>
      </div>
      <div class="column is-8">
        <interest-request-table dailySummary></interest-request-table>
      </div>

      <div class="column is-1"></div>
    </div>
    <div class="columns userSection">
      <div class="column is-3">
        <subscribers-table :dailySummary="true"></subscribers-table>
      </div>
      <div class="column is-8">
        <h2 class="title monFont500">New Users</h2>

        <users-table :dailySummary="true"></users-table>
      </div>
    </div>
  </div>
</template>

<script>
import ContactMessageTable from "../../components/admin/ContactMessageTable.vue";
import InterestRequestTable from "../../components/admin/InterestRequestTable.vue";
import UsersTable from "../../components/admin/UsersTable.vue";
import SubscribersTable from "../../components/admin/SubscribersTable.vue";
export default {
  components: {
    ContactMessageTable,
    InterestRequestTable,
    UsersTable,
    SubscribersTable,
  },
  data() {
    return {
      loadingComp: null,
    };
  },
};
</script>

<style scoped>
.wrapper {
  margin-bottom: 350px;
}

.column {
  margin: 0 50px;
}

.columns {
  max-height: 250px;
}

.userSection {
  margin-top: 100px;
}
</style>
