<template>
  <section class="margins">
    <b-navbar>
      <template #brand>
        <img
          class="mobileBrand"
          :src="require('@/assets/images/classiccarcollections_logo.svg')"
          alt="Classic Car Collection"
      /></template>

      <template #start>
        <div class="wrapper">
          <div class="brand">
            <b-navbar-item class="logo">
              <img
                :src="require('@/assets/images/classiccarcollections_logo.svg')"
                alt="Classic Car Collection"
              />
            </b-navbar-item>
          </div>
          <div class="menu monFont500">
            <b-navbar-item class="navItem" tag="router-link" to="/">
              MAIN SITE
            </b-navbar-item>
            <b-navbar-item class="navItem" tag="router-link" to="/admin">
              DASHBOARD
            </b-navbar-item>
            <b-navbar-item class="navItem" tag="router-link" to="/admin/lots">
              LOTS
            </b-navbar-item>
            <b-navbar-item class="navItem" tag="router-link" to="/admin/users">
              USERS
            </b-navbar-item>
            <b-navbar-item class="navItem" tag="router-link" to="/admin/bids">
              BIDS
            </b-navbar-item>
            <b-navbar-item
              class="navItem"
              tag="router-link"
              to="/admin/auctions"
            >
              AUCTIONS
            </b-navbar-item>

            <b-navbar-item tag="div">
              <div class="buttons">
                <b-button
                  icon-left="plus"
                  icon-pack="fa"
                  tag="router-link"
                  to="/admin/lots/new"
                  type="is-success"
                >
                  Upload Lot
                </b-button>
              </div>
            </b-navbar-item>

            <!-- <b-navbar-item class="navItem" tag="router-link" to="/">
              <b-icon icon="magnify" size="is-large"> </b-icon
            ></b-navbar-item> -->
          </div>
        </div>
      </template>
    </b-navbar>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "@/assets/bulmaVariables.scss";

.navbarHeader {
  display: none !important;
}

.margins {
  margin: 0;
  padding: 0;
}

.logo img {
  min-height: 100%;
  margin: 0 auto;
  margin-top: 5vh;
}

.wrapper {
  height: 25vh;
}

.logo {
  height: 100%;
  width: 100%;
  z-index: -10;
  pointer-events: none;
}

.logo:hover {
  background-color: inherit;
}

.mobileBrand {
  display: none;
}

.brand {
  display: block;
  height: 60%;
  width: 100%;
  position: absolute;
  z-index: 50;
}

.menu {
  width: 100vw;
  margin-top: 14vh;
  display: flex;
  justify-content: center;
  flex: 1;
  background-color: #f1f1f1;
  padding-top: 30px;
  z-index: 20;
}

$navPadding: 45px;
.navItem {
  font-size: 28px;
  align-self: flex-end;
  padding-left: $navPadding;
  padding-right: $navPadding;
}

@media screen and (max-width: 1023px) {
  .menu {
    width: 100vw;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #f1f1f1;
    padding-top: 30px;
    z-index: 20;
  }

  .brand {
    display: none;
  }

  .mobileBrand {
    display: block;
    position: relative;
    height: auto;
    width: 55%;
  }

  .navItem {
    font-size: 25px;
    align-self: flex-end;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
