var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"\n        column\n        is-4-desktop is-offset-4-desktop is-8-tablet is-offset-2-tablet\n      "},[_c('h2',{staticClass:"mainHeader monFontBold p-1"},[_vm._v("Contact Us")]),_c('div',{staticClass:"carOverview contactCard monFont500 box"},[_c('h4',{staticClass:"monFontBold mb-4"},[_vm._v("Contact Information")]),_c('p',[_c('a',{attrs:{"href":"mailto:sales@estateauctionhouse.com"}},[_c('b-icon',{staticClass:"pr-2",attrs:{"icon":"email"}}),_vm._v(" sales@estateauctionhouse.com")],1)]),_c('p',[_c('a',{attrs:{"href":"tel:+27674016020"}},[_c('b-icon',{staticClass:"pr-2",attrs:{"icon":"phone"}}),_vm._v(" +27 67 401 6020")],1)])]),(_vm.successfulMessage)?_c('div',{staticClass:"box succBox has-text-centered successBox"},[_vm._m(0),_c('p',{staticClass:"title monFont500 mt-5"},[_vm._v("Message Sent")]),_c('p',{staticClass:"subtitle monFontReg"},[_vm._v("We'll be in touch!")])]):_vm._e(),(!_vm.successfulMessage)?_c('div',{staticClass:"box contactForm"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ValidationProvider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"margins",attrs:{"position":"is-centered","label":"Name","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"placeholder":"Your name"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Surname","autocomplete":"family-name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"margins",attrs:{"position":"is-centered","label":"Surname","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text","placeholder":"Your surname","required":""},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"margins",attrs:{"position":"is-centered","label":"Email","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"email","placeholder":"Your email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)}),_c('b-field',{staticClass:"margins",attrs:{"position":"is-centered","label":"Phone Number","message":_vm.phoneFieldMessage,"type":{
              'is-danger': _vm.phoneNumberInvalid,
              'is-success': _vm.phoneNumberValid,
            }}},[_c('b-input',{attrs:{"autocomplete":"tel","type":"tel","id":"phone"},on:{"blur":_vm.validateNumber}})],1),_c('b-field',{attrs:{"label":"Message"}},[_c('b-input',{attrs:{"maxlength":"300","type":"textarea"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('b-field',{staticClass:"margins",attrs:{"position":"is-centered","label":"I am a"}},[_c('b-checkbox',{staticClass:"mr-3",attrs:{"native-value":"buyer"},model:{value:(_vm.userType),callback:function ($$v) {_vm.userType=$$v},expression:"userType"}},[_vm._v("Buyer")]),_c('b-checkbox',{staticClass:"ml-3",attrs:{"native-value":"seller"},model:{value:(_vm.userType),callback:function ($$v) {_vm.userType=$$v},expression:"userType"}},[_vm._v("Seller")])],1),_c('b-button',{staticClass:"mt-4",attrs:{"loading":_vm.isLoading,"type":"is-primary is-large"},on:{"click":function($event){return handleSubmit(_vm.sendMessage)}}},[_vm._v("Send Message")])]}}],null,false,1174094062)})],1):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon has-text-info"},[_c('i',{staticClass:"mdi mdi-check-circle succIcon"})])}]

export { render, staticRenderFns }