<template>
  <div v-if="thumbnails && thumbnails.length > 0">
    <h3 class="monFontBold galleryHeading mt-5">{{ heading }}</h3>
    <div class="columns">
      <div class="column is-12">
        <div class="gallery columns is-multiline">
          <div
            v-for="(thumb, index) in thumbnails"
            :key="index"
            class="column is-3"
            @click="$emit('openLightBox', name, index)"
          >
            <img class="galleryImage" :src="thumb.url" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["thumbnails", "heading", "loaded", "name"],
  data() {
    return {
      index: null,
    };
  },
  methods: {
    getUrls(gallery) {
      return gallery.map((o) => o.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  margin-left: 130px;
}

.galleryHeading {
  margin-left: 130px;
}

h3 {
  font-size: 28px;
  margin-bottom: 30px;
  text-align: left;
}

.galleryImage {
  object-fit: cover;
  width: 300px;
  height: 200px;
}

.thumbsGallery {
  display: flex;
}

.thumbsGallery {
  flex-wrap: wrap;

  .thumb {
    flex-basis: 50%;
  }
}

.thumb img {
  object-fit: cover;
  width: 15.5vw;
  height: 9.8vw;
}

@media only screen and (max-width: 768px) {
  .gallery {
    margin-left: 10px;
    margin-right: 10px;
  }

  .galleryHeading {
    margin-left: 20px;
  }

  .thumb {
    height: 200px;
    padding: 5px;
    box-sizing: border-box;
    flex: 1;
  }

  .thumb img {
    object-fit: cover;
    width: 300px;
    height: 200px;
    padding-top: 10px;
  }
}
</style>
