const namespaced = true;
const state = {
  id: null,
  firstName: null,
  surname: null,
  email: null,
  emailVerified: false,
  country: null,
  admin: false,
  newUser: false,
};

const mutations = {
  SET_USER: (state, u) => {
    state.id = u.id;
    state.firstName = u.firstName;
    state.surname = u.surname;
    state.email = u.email;
    state.country = u.country;
    state.emailVerified = u.emailVerified;
  },

  SET_ADMIN: (state, isAdmin) => {
    state.admin = isAdmin;
  },
  SET_EMAIL_VERIFIED: (state, isVerified) => {
    state.emailVerified = isVerified;
  },
  SET_NEW_USER: (state, isNewUser) => {
    state.newUser = isNewUser;
  },

  CLEAR_USER: (state) => {
    state.id = null;
    state.firstName = null;
    state.surname = null;
    state.email = null;
    state.emailVerified = false;
    state.admin = false;
    state.country = null;
  },
};

const actions = {
  setUser(context, user) {
    context.commit("SET_USER", user);
  },
  clearUser(context) {
    context.commit("CLEAR_USER");
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
