<template>
  <section class="wrapper">
    <div v-if="!showResetPassword">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <ValidationProvider
          rules="required|email"
          name="Email"
          v-slot="{ errors, valid }"
        >
          <b-field
            position="is-centered"
            class="margins"
            label="Email"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input
              type="email"
              v-model="email"
              placeholder="Your email"
              autocomplete="email"
            >
            </b-input>
          </b-field>
        </ValidationProvider>
        <ValidationProvider
          rules="required"
          name="Password"
          v-slot="{ errors, valid }"
        >
          <b-field
            class="margins"
            label="Password"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input
              type="password"
              v-model="password"
              icon-pack="mdi"
              password-reveal
              placeholder="Your password"
            >
            </b-input>
          </b-field>
        </ValidationProvider>
        <b-button
          :loading="isLoading"
          class="mt-4"
          type="is-primary is-large"
          @click="handleSubmit(login)"
          >Log In</b-button
        >
        <nav class="level">
          <div class="level-item has-text-centered mt-5 mb-4">
            <b-button @click="resetClicked" type="is-ghost"
              >Reset Password</b-button
            >
          </div>
        </nav>
      </ValidationObserver>
    </div>

    <div v-if="showResetPassword">
      <b-field
        position="is-centered"
        class="margins"
        label="Enter email to reset password"
      >
        <b-input type="email" v-model="resetEmail" placeholder="Your email">
        </b-input>
      </b-field>
      <b-button @click="resetPassword" :loading="resetLoading" type="is-primary"
        >Send Reset Email</b-button
      >
      <br />
      <b-button class="mt-5" @click="showResetPassword = false" type="is-ghost"
        >Back to Login</b-button
      >
    </div>
  </section>
</template>

<script>
import { auth } from "@/main";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ga } from "../utils/analytics.js";

export default {
  data() {
    return {
      email: "",
      resetEmail: "",
      password: "",
      isLoading: false,
      resetLoading: false,
      showResetPassword: false,
    };
  },
  methods: {
    async login() {
      try {
        this.isLoading = true;
        const uc = await auth.signInWithEmailAndPassword(
          this.email,
          this.password
        );
        const user = uc.user;

        this.$store.dispatch("user/setUser", {
          id: user.uid,
          emailVerified: user.emailVerified,
          admin: false,
        });

        ga.loginEvent(user.uid);

        this.isLoading = false;

        this.$emit("loginSucceeded");
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.error = "An unexpected error occured";

        if (err.message) {
          this.error = err.message;
        }

        if (err.code == "auth/wrong-password") {
          this.error = "Incorrect password";
        }
        if (err.code == "auth/user-not-found") {
          this.error = "No account found for this email address.";
        }

        this.$swal("Unable to Login", this.error, "error");
      }
    },
    async resetPassword() {
      this.resetLoading = true;
      try {
        await auth.sendPasswordResetEmail(this.resetEmail);
        this.$buefy.toast.open({
          message: `Reset Password Email Sent!`,
          type: "is-success",
        });
      } catch (error) {
        this.$swal(
          "Unable to send email",
          "Please make sure your email address is valid and belongs to an existing account",
          "error"
        );
        console.log(error);
      }
      this.resetLoading = false;

      this.resetEmail = "";
    },
    resetClicked() {
      this.resetEmail = this.email.length > 1 ? this.email : "";
      this.showResetPassword = true;
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
};
</script>

<style lang="scss" scoped>
input {
  margin-left: 80px;
  margin-right: 80px;
}
</style>
