<template>
  <div class="mb-5 mt-5">
    <b-field
      v-for="(i, index) in info"
      :key="index"
      custom-class="is-medium"
      :label="i.heading"
    >
      <b-input
        style="display: block; width: 100%"
        v-for="(bp, index2) in i.bulletPoints"
        :key="index2"
        :disabled="infoType === 'overview' && index == 1 && index2 == 0"
        v-model="bp.bullet"
      ></b-input>
      <b-button @click="addBullet(i.heading)" style="display: block"
        >Add Bullet Point</b-button
      >
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    infoType: { type: String },
    lotId: { type: String },
    existingInfo: { type: Array },
  },
  data() {
    return {
      info: [
        { heading: "KEY FACTS", bulletPoints: [] },
        { heading: "EQUIPMENT AND FEATURES", bulletPoints: [] },
        { heading: "CONDITION", bulletPoints: [] },
        { heading: "SERVICE HISTORY", bulletPoints: [] },
        { heading: "SUMMARY", bulletPoints: [] },
      ],
      overview: [
        { heading: "CAR OVERVIEW", bulletPoints: [] },
        { heading: "LOT OVERVIEW", bulletPoints: [] },
        { heading: "SELLER", bulletPoints: [] },
      ],
    };
  },
  methods: {
    addBullet(heading) {
      const infoBlock = this.info.find((i) => i.heading === heading);

      infoBlock.bulletPoints.push({ bullet: "" });
    },
  },
  mounted() {
    if (this.existingInfo?.length > 0) {
      this.info = this.existingInfo;
      console.log("Existing info: ", JSON.stringify(this.existingInfo));
    } else {
      console.log("NEW LOT");
      if (this.infoType === "overview") {
        this.info = this.overview;
        this.info[1].bulletPoints.push({ bullet: `Lot #${this.lotId}` });
      }
    }
  },
};
</script>

<style></style>
