<template>
  <div>
    <header id="header" class="header">
      <nav class="level searchWrapper">
        <!--
        <nav class="level-item">
          <div class="filterWrapper box">
            <h3 class="p20 monFont500">Filter by Make</h3>
            <button
              @click="resetFilter"
              class="
                ais-ClearRefinements-button
                ais-ClearRefinements-button--disabled
                ml20
              "
              :disabled="!selectedMake"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="11"
                viewBox="0 0 11 11"
              >
                <g fill="none" fill-rule="evenodd" opacity=".4">
                  <path d="M0 0h11v11H0z"></path>
                  <path
                    fill="#000"
                    fill-rule="nonzero"
                    d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                  ></path>
                </g>
              </svg>
              Clear filters
            </button>
            <div id="brandList">
              <ul>
                <li v-for="(make, index) in makes" :key="index">
                  <b-radio
                    name="makeRadio"
                    v-model="selectedMake"
                    :native-value="make.make"
                  >
                    <span
                      >{{ make.make }} <b-tag> {{ make.count }} </b-tag></span
                    >
                  </b-radio>
                </li>
              </ul>
            </div>
          </div>
        </nav>
-->
        <div class="level-item">
          <div class="ais-SearchBox">
            <div class="ais-SearchBox-form">
              <input
                @keyup="searchOnEnter"
                type="search"
                autocorrect="off"
                autocapitalize="off"
                autocomplete="off"
                spellcheck="false"
                required="required"
                maxlength="512"
                aria-label="Search"
                placeholder="Make, Model, color, …"
                class="ais-SearchBox-input monFont500"
                v-model="searchInput"
              />
              <!---->
            </div>
          </div>
        </div>
        <div class="level-item">
          <b-button @click="search" class="m-2">Search</b-button>
          <b-button @click="reset" class="m-2">Reset</b-button>
        </div>
      </nav>
    </header>

    <div class="columns is-gapless is-multiline is-centered hitsWrapper">
      <div
        v-for="(hit, index) in hits"
        :key="index"
        class="column is-one-quarter-desktop is-half-tablet"
      >
        <lot-card
          :lot="hit"
          :view="'buy'"
          @lot-click="$router.push(`/lots/${hit.lotId}`)"
        ></lot-card>
      </div>
    </div>

    <b-button
      v-if="pageNumber + 1 < totalPages"
      @click="loadMore"
      size="is-large"
      type="is-primary"
      >Load More</b-button
    >
    <div v-if="false" class="hits-empty-state">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="138"
        height="138"
        class="hits-empty-state-image"
      >
        <defs>
          <linearGradient id="c" x1="50%" x2="50%" y1="100%" y2="0%">
            <stop offset="0%" stop-color="#F5F5FA"></stop>
            <stop offset="100%" stop-color="#FFF"></stop>
          </linearGradient>
          <path
            id="b"
            d="M68.71 114.25a45.54 45.54 0 1 1 0-91.08 45.54 45.54 0 0 1 0 91.08z"
          ></path>
          <filter
            id="a"
            width="140.6%"
            height="140.6%"
            x="-20.3%"
            y="-15.9%"
            filterUnits="objectBoundingBox"
          >
            <feOffset
              dy="4"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation="5.5"
            ></feGaussianBlur>
            <feColorMatrix
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
              values="0 0 0 0 0.145098039 0 0 0 0 0.17254902 0 0 0 0 0.380392157 0 0 0 0.15 0"
            ></feColorMatrix>
            <feOffset
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter2"
            ></feOffset>
            <feGaussianBlur
              in="shadowOffsetOuter2"
              result="shadowBlurOuter2"
              stdDeviation="1.5"
            ></feGaussianBlur>
            <feColorMatrix
              in="shadowBlurOuter2"
              result="shadowMatrixOuter2"
              values="0 0 0 0 0.364705882 0 0 0 0 0.392156863 0 0 0 0 0.580392157 0 0 0 0.2 0"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
            </feMerge>
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <circle
            cx="68.85"
            cy="68.85"
            r="68.85"
            fill="#5468FF"
            opacity=".07"
          ></circle>
          <circle
            cx="68.85"
            cy="68.85"
            r="52.95"
            fill="#5468FF"
            opacity=".08"
          ></circle>
          <use fill="#000" filter="url(#a)" xlink:href="#b"></use>
          <use fill="url(#c)" xlink:href="#b"></use>
          <path
            d="M76.01 75.44c5-5 5.03-13.06.07-18.01a12.73 12.73 0 0 0-18 .07c-5 4.99-5.03 13.05-.07 18a12.73 12.73 0 0 0 18-.06zm2.5 2.5a16.28 16.28 0 0 1-23.02.09A16.29 16.29 0 0 1 55.57 55a16.28 16.28 0 0 1 23.03-.1 16.28 16.28 0 0 1-.08 23.04zm1.08-1.08l-2.15 2.16 8.6 8.6 2.16-2.15-8.6-8.6z"
            fill="#5369FF"
          ></path>
        </g>
      </svg>
      <p class="hits-empty-state-title">
        Sorry, we can't find any matches to your query!
      </p>
      <p class="hits-empty-state-description">Please try another query.</p>
      <div class="ais-ClearRefinements">
        <button
          type="reset"
          disabled="disabled"
          class="
            ais-ClearRefinements-button ais-ClearRefinements-button--disabled
          "
        >
          <div class="clear-filters">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0h11v11H0z"></path>
                <path
                  fill="#000"
                  fill-rule="nonzero"
                  d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                ></path>
              </g>
            </svg>
            Clear filters
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import LotCard from "./LotCard.vue";

import "@/assets/algolia/Theme.css";
import "@/assets/algolia/App.css";
import { ga } from "../utils/analytics";
import { db } from "../main";

let searchClient;
let query = "";
let index;

export default {
  components: { LotCard },
  data() {
    return {
      aisInput: "",
      searchInput: "",
      hits: [],
      makes: [],
      selectedMake: null,
      pageNumber: 0,
      totalPages: 0,
      auction: null,
      auctionActive: false,
    };
  },
  methods: {
    setAisSearch() {
      this.aisInput = this.searchInput;

      console.dir(this.$refs.hits);
    },

    searchOnEnter($event) {
      if ($event.key === "Enter") {
        this.search();
      }
    },

    async loadAuction() {
      const today = new Date();
      const auctions = await db
        .collection("auctions")
        .where("dateRange.end", ">", today)
        .orderBy("dateRange.end")
        .limit(1)
        .get();

      let auctionFound = false;
      auctions.forEach((doc) => {
        auctionFound = true;
        this.auction = doc.data();
        this.auction.id = doc.id;
      });

      if (auctionFound) {
        if (this.auction.dateRange.start.toDate() < today) {
          console.log("Auction Active: ");
          this.auctionActive = true;
        }
      }
    },

    setAuctionLots() {
      for (const lot of this.hits) {
        console.log("Chekcing hit: " + lot.lotId);
        if (this.auction.lots.includes(lot.lotId)) {
          lot.status = "On Auction";
          console.log("Set auction lot: " + lot.lotId);
        }
      }
    },

    async search() {
      this.pageNumber = 0;
      query = this.searchInput;
      this.selectedMake = null;

      const result = await index.search(query, {
        filters: "active:true",
      });
      this.hits = result.hits;
      this.totalPages = result.nbPages;
      if (query && query.length !== 0) ga.searchEvent(query);

      console.log(result.hits);
      console.dir(result);
      console.log("Page Number: " + this.pageNumber);
      console.log("Total pages: " + this.totalPages);
    },

    async loadMore() {
      console.log("Loading more");
      this.pageNumber++;
      query = this.searchInput;

      const result = await index.search(query, {
        filters: "active:true",
        page: this.pageNumber,
      });

      console.dir(result);
      this.hits = this.hits.concat(result.hits);
    },

    async filter() {},

    async resetFilter() {
      this.selectedMake = null;
      const result = await index.search(this.searchInput, {
        filters: `active:true`,
      });
      this.hits = result.hits;
    },

    async reset() {
      this.searchInput = "";

      this.search();
    },
  },
  watch: {
    async selectedMake() {
      let result;
      if (this.selectedMake) {
        result = await index.search(this.searchInput, {
          filters: `make:'${this.selectedMake}'`,
        });
        this.hits = result.hits;
      }
    },

    hits() {
      if (this.auction && this.auctionActive) this.setAuctionLots();
    },
  },
  mounted() {
    searchClient = algoliasearch(
      "00ETXOGIMN",
      "847a3f036cc09d60a365947bab5d455e"
    );
    const loadAuc = this.loadAuction();

    const indexName =
      process.env.NODE_ENV === "production" ? "prod_ccc" : "dev_ccc";
    index = searchClient.initIndex(indexName);
    this.search();

    loadAuc.then(() => {
      console.log("Auction loaded");
      if (this.auctionActive) this.setAuctionLots();
    });
  },
};
</script>

<style scoped>
.hitsWrapper {
  margin-top: 50px !important;
}

.filterWrapper {
  text-align: center;
  padding: 20px !important;
  width: 300px;
}

.level-item {
  margin: 0 30px;
}

#brandList {
  padding-top: 20px;
}

#brandList ul {
  text-align: left;
  width: 100%;
}

#brandList ul li {
  width: 100%;
}

@media (max-width: 899px) {
  .ais-SearchBox {
    width: 90vw;
  }

  .hitsWrapper {
    margin-top: 20px !important;
  }
}
</style>
