import { firestoreAction } from "vuexfire";
import { db, fire } from "@/main";
const namespaced = true;

const state = {
  lots: [],
  auctions: [],
  users: [],
  contactMessages: [],
  interestRequests: [],
  subscribers: [],
};

const actions = {
  async upsertLot(context, lot) {
    console.log("Upserting lot: " + lot.lotId);
    console.log("Lot: " + JSON.stringify(lot));
    await db.collection("lots").doc(lot.lotId).set(lot);
  },

  async insertSellerLot(context, lot) {
    console.log("Inserting Seller lot: " + lot.lotId);
    console.log("Lot: " + JSON.stringify(lot));
    await db.collection("sellerLots").doc(lot.lotId).set(lot);
  },

  async upsertAuction(context, auction) {
    console.log("Upserting Auction: " + auction.id);
    console.log("auction: " + JSON.stringify(auction));
    await db.collection("auctions").doc(auction.id).set(auction);
  },

  async insertAuction(context, auction) {
    const fireDate = {
      start: fire.firestore.Timestamp.fromDate(
        new Date(auction.dateRange.start)
      ),
      end: fire.firestore.Timestamp.fromDate(new Date(auction.dateRange.end)),
    };

    auction.dateRange = fireDate;
    console.log("auction: " + JSON.stringify(auction));
    await db.collection("auctions").add(auction);
  },

  async updateAuctionDates(context, auction) {
    const fireDate = {
      start: fire.firestore.Timestamp.fromDate(
        new Date(auction.dateRange.start)
      ),
      end: fire.firestore.Timestamp.fromDate(new Date(auction.dateRange.end)),
    };
    await db
      .collection("auctions")
      .doc(auction.auctionId)
      .update({ dateRange: fireDate });
  },

  async addAuctionLots(context, auction) {
    console.dir(auction.lotIds);
    await db
      .collection("auctions")
      .doc(auction.auctionId)
      .update({
        lots: fire.firestore.FieldValue.arrayUnion(...auction.lotIds),
      });
  },

  async removeAuctionLots(context, auction) {
    await db
      .collection("auctions")
      .doc(auction.auctionId)
      .update({
        lots: fire.firestore.FieldValue.arrayRemove(...auction.lotIds),
      });
  },

  async deleteAuction(context, auction) {
    console.log("deleting Auction: " + auction.id);
    console.log("auction: " + JSON.stringify(auction));

    await db.collection("auctions").doc(auction.id).delete();
  },

  async addAuctionBidders(context, auction) {
    console.dir(auction);
    await db
      .collection("auctions")
      .doc(auction.auctionId)
      .update({
        verifiedUsers: fire.firestore.FieldValue.arrayUnion(
          ...auction.bidderIds
        ),
      });
  },
  async removeAuctionBidders(context, auction) {
    await db
      .collection("auctions")
      .doc(auction.auctionId)
      .update({
        verifiedUsers: fire.firestore.FieldValue.arrayRemove(
          ...auction.bidderIds
        ),
      });
  },

  bindLots: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "lots",
      db.collection("lots").orderBy("createdAt", "desc")
    );
  }),

  bindUsers: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "users",
      db.collection("users").orderBy("createdAt", "desc")
    );
  }),

  bindSubscribers: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "subscribers",
      db.collection("subscribers").orderBy("createdAt", "desc")
    );
  }),

  bindContactMessages: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "contactMessages",
      db.collection("contactMessages").orderBy("createdAt", "desc")
    );
  }),

  bindInterestRequests: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "interestRequests",
      db.collection("interestRequests").orderBy("createdAt", "desc")
    );
  }),

  bindAuctions: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "auctions",
      db.collection("auctions").orderBy("dateRange.start", "desc")
    );
  }),
};

const mutations = {};

const getters = {
  getLot: (state) => (id) => {
    return state.lots.find((l) => l.lotId == id);
  },
  getLotTitle: (state) => (id) => {
    return state.lots.find((l) => l.lotId == id).title;
  },

  getNewLotId: (state) => () => {
    let temp = "";
    for (let i = 0; i < 5; i++) {
      temp += Math.floor(Math.random() * (9 - 0 + 1) + 0);
    }

    if (state.lots.some((l) => l.id == temp)) {
      console.log("DUPLICATE ID FOUND!!!!");
      temp = "";
      for (let i = 0; i < 5; i++) {
        temp += Math.floor(Math.random() * (9 - 0 + 1) + 0);
      }
    }

    return temp;
  },

  // getLotWatchers: (state) => (id) => {
  //   return [];
  // },

  // getLotBids: (state) => (id) => {
  //   return [];
  // },
  // getLotBuyNows: (state) => (id) => {
  //   return [];
  // },

  // getUserBuyNows: (state) => (id) => {
  //   return [];
  // },

  getUserById: (state) => (id) => {
    return state.users.find((u) => u.id === id);
  },

  // getUserBids: (state) => (id) => {
  //   return [];
  // },

  // getUserWatched: (state) => (id) => {
  //   return [];
  // },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
