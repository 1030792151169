<template>
  <section class="margins">
    <b-navbar>
      <template #brand>
        <img
          class="mobileBrand"
          :src="require('@/assets/images/classiccarcollections_logo.svg')"
          alt="Classic Car Collections"
      /></template>

      <template #start>
        <div class="wrapper">
          <div class="brand">
            <b-navbar-item class="logo">
              <img
                :src="require('@/assets/images/classiccarcollections_logo.svg')"
                alt="Classic Car Collections"
              />
            </b-navbar-item>
          </div>
          <div class="menu monFont500">
            <b-navbar-item class="navItem" tag="router-link" to="/">
              HOME
            </b-navbar-item>
            <b-navbar-item class="navItem" tag="router-link" to="/buy">
              BUY
            </b-navbar-item>
            <b-navbar-item class="navItem" tag="router-link" to="/sell">
              SELL
            </b-navbar-item>
            <b-navbar-item class="navItem" tag="router-link" to="/auction">
              AUCTION
            </b-navbar-item>
            <b-navbar-item class="navItem" tag="router-link" to="/faq">
              FAQ
            </b-navbar-item>
            <b-navbar-item class="navItem" tag="router-link" to="/contact">
              CONTACT US
            </b-navbar-item>
            <b-navbar-item
              v-if="!isLoggedIn"
              class="navItem"
              tag="router-link"
              to="/login"
            >
              LOGIN
            </b-navbar-item>
            <b-navbar-item
              v-if="!isLoggedIn"
              class="navItem registerBtn"
              tag="router-link"
              to="/register"
            >
              REGISTER
            </b-navbar-item>
            <b-navbar-item
              v-if="isLoggedIn && !emailVerified"
              class="navItem registerBtn"
              tag="router-link"
              to="/register"
            >
              VERIFY EMAIL
            </b-navbar-item>

            <b-navbar-item
              v-if="isLoggedIn && isAdmin"
              class="navItem"
              tag="router-link"
              to="/admin"
            >
              ADMIN
            </b-navbar-item>

            <!-- <b-navbar-item class="navItem" tag="router-link" to="/">
              <b-icon icon="magnify" size="is-large"> </b-icon
            ></b-navbar-item> -->
          </div>
        </div>
      </template>
    </b-navbar>
  </section>
</template>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.state.user.id !== null;
    },
    emailVerified() {
      return this.$store.state.user.emailVerified;
    },
    isAdmin() {
      return this.$store.state.user.admin;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/bulmaVariables.scss";
.margins {
  margin: 0;
  padding: 0;
}

.logo img {
  min-height: 100%;
  margin: 0 auto;
  margin-top: 5vh;
}

.wrapper {
  height: 25vh;
}

.logo {
  height: 100%;
  width: 100%;
  z-index: -10;
  pointer-events: none;
}

.logo:hover {
  background-color: inherit;
}

.mobileBrand {
  display: none;
}

.brand {
  display: block;
  height: 60%;
  width: 100%;
  position: absolute;
  z-index: 50;
}

.menu {
  width: 100vw;
  margin-top: 14vh;
  display: flex;
  justify-content: center;
  flex: 1;
  background-color: #f1f1f1;
  padding-top: 30px;
  z-index: 20;
}

$navPadding: 2vw;
.navItem {
  font-size: 1.3vw;
  align-self: flex-end;
  padding-left: $navPadding;
  padding-right: $navPadding;
}

.registerBtn {
  color: white;
  background-color: $primary;
  border-radius: 10px;
}

.registerBtn:hover {
  color: white !important;
  background-color: $secondary !important;
}

.logoutBtn {
  color: red;
}

@media screen and (max-width: 1023px) {
  .menu {
    width: 100vw;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #f1f1f1;
    padding-top: 30px;
    z-index: 20;
  }

  .brand {
    display: none;
  }

  .mobileBrand {
    display: block;
    position: relative;
    height: 50px;
    width: 30%;
    right: -35vw;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .navItem {
    font-size: 25px;
    align-self: flex-end;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
