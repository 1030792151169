<template>
  <section class="wrapper">
    <home-carousel :view="'auction'"></home-carousel>
    <div v-if="auction" class="auctionCountdown">
      <h3 class="title monFont500">
        {{ auctionActive ? "Auction Ends In: " : "AUCTION STARTS IN" }}
      </h3>
      <vac v-if="!auctionActive" :end-time="auction.dateRange.start.toDate()">
        <template v-slot:process="{ timeObj }">
          <p class="monFontBold countdown" v-if="startAuctionday">
            {{ ` ${timeObj.h} : ${timeObj.m} : ${timeObj.s}` }}
          </p>
          <p class="monFontBold countdown" v-else>
            {{ `${timeObj.d} ${timeObj.d == 1 ? "day" : "days"}` }}
          </p>
        </template>
        <template v-slot:finish>
          <span>NOW</span>
        </template>
      </vac>
      <vac v-if="auctionActive" :end-time="auction.dateRange.end.toDate()">
        <template v-slot:process="{ timeObj }">
          <p class="monFontBold countdown" v-if="finalAuctionday">
            {{ ` ${timeObj.h} : ${timeObj.m} : ${timeObj.s}` }}
          </p>
          <p class="monFontBold countdown" v-else>
            {{ `${timeObj.d} ${timeObj.d == 1 ? "day" : "days"}` }}
          </p>
        </template>
        <template v-slot:finish>
          <span>NOW</span>
        </template>
      </vac>
    </div>
    <div class="columns is-gapless is-multiline is-centered">
      <div
        v-for="(lot, index) in lots"
        :key="index"
        class="column is-one-quarter-desktop is-half-tablet"
      >
        <lot-card
          :lot="lot"
          @lot-click="$router.push(`/lots/${lot.lotId}`)"
        ></lot-card>
      </div>
      <div
        v-for="i in numPlaceholders"
        :key="i"
        class="column is-one-quarter-desktop is-half-tablet"
      >
        <lot-card-placeholder></lot-card-placeholder>
      </div>
    </div>
  </section>
</template>

<script>
import { db } from "@/main";
import LotCard from "../components/LotCard.vue";
import HomeCarousel from "../components/HomeCarousel.vue";
import LotCardPlaceholder from "../components/placeholders/LotCardPlaceholder.vue";
import dayjs from "dayjs";

export default {
  metaInfo: {
    title: "Auction",
    meta: [
      {
        name: "description",
        content:
          "A safe, smart and simple online auction experience where sellers can have confidence in the bidding and buyers can have confidence in the cars.",
      },
    ],
  },
  components: { LotCard, HomeCarousel, LotCardPlaceholder },

  data() {
    return {
      lots: [],
      numPlaceholders: 0,
      auction: null,
      auctionActive: false,
    };
  },
  computed: {
    finalAuctionday() {
      if (this.auction) {
        const date = dayjs(this.auction.dateRange.end.toDate());

        const today = dayjs();

        const endHours = date.diff(today, "hour");
        console.log("End Hours: " + endHours);

        if (endHours < 24) {
          return true;
        }
      }

      return false;
    },

    startAuctionday() {
      if (this.auction) {
        const date = dayjs(this.auction.dateRange.start.toDate());

        const today = dayjs();

        const endHours = date.diff(today, "hour");
        console.log("End Hours: " + endHours);

        if (endHours < 24) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    async loadData() {
      const today = new Date();
      const auctions = await db
        .collection("auctions")
        .where("dateRange.end", ">", today)
        .orderBy("dateRange.end")
        .limit(1)
        .get();

      this.auctionActive = false;
      let auctionFound = false;
      auctions.forEach((doc) => {
        auctionFound = true;
        this.auction = doc.data();
        this.auction.id = doc.id;
        console.dir(this.auction);
      });

      console.log("Auction DAte: " + this.auction.dateRange.end.toDate());

      if (auctionFound) {
        if (this.auction.dateRange.start.toDate() < today) {
          console.log("Auction Active: ");
          this.auctionActive = true;
        }

        this.numPlaceholders = this.auction.lots?.length ?? 0;

        if (this.auctionActive) {
          this.auctionTitle = "Live Auction: ";
        } else {
          this.auctionTitle = "Upcoming Auction: ";
        }

        console.log("Auction ID: " + this.auction.id);

        const auctionLots = await db
          .collection("lots")
          .where("lotId", "in", this.auction.lots)
          .get();

        this.numPlaceholders = 0;
        auctionLots.forEach((doc) => {
          console.log("Auction Lot");
          const lot = doc.data();
          if (this.auctionActive) lot.status = "On Auction";
          this.lots.push(lot);
        });
      }
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.countdown {
  font-size: 100px;
}
.wrapper {
  margin-bottom: 80px;
  min-height: 595px;
}

@media (max-width: 899px) {
  .wrapper {
    min-height: 100vh;
  }

  .countdown {
    font-size: 60px;
  }
}
</style>
