<template>
  <div>
    <b-field class="" grouped group-multiline>
      <!-- <b-select v-model="perPage" class="ml-4 mr-5">
            <option value="10">10 per page</option>
            <option value="20">20 per page</option>
            <option value="100">100 per page</option>
          </b-select> -->
      <b-button
        :disabled="checkedRows.length == 0 || !currentAuction"
        class="controls"
        icon-left="plus"
        icon-pack="fa"
        type="is-success"
        @click="addUsersToAuction"
        :loading="addToAuctionLoading"
      >
        Add to Auction
      </b-button>
      <b-button
        class="ml-5"
        size="is-small"
        label="Clear selected"
        type="is-danger"
        v-if="checkedRows != 0"
        @click="clearSelections"
      />
    </b-field>

    <b-table
      class="margins has-text-left"
      hoverable
      height="510px"
      :data="allUsers"
      :checked-rows.sync="checkedRows"
      :paginated="!dailySummary"
      :per-page="'10'"
      default-sort="createdAt"
      default-sort-direction="desc"
      centered
      checkable
      bordered
    >
      <b-table-column
        field="firstName"
        label="Name"
        sortable
        searchable
        v-slot="props"
      >
        {{ props.row.firstName }}
      </b-table-column>
      <b-table-column
        field="surname"
        label="Surname"
        sortable
        searchable
        v-slot="props"
      >
        {{ props.row.surname }}
      </b-table-column>
      <b-table-column
        field="email"
        label="Email"
        sortable
        searchable
        v-slot="props"
      >
        {{ props.row.email }}
      </b-table-column>
      <b-table-column
        field="phoneNumber"
        label="Phone Number"
        sortable
        searchable
        v-slot="props"
      >
        {{ props.row.phoneNumber }}
      </b-table-column>
      <b-table-column
        field="country"
        label="Country"
        searchable
        sortable
        v-slot="props"
      >
        {{ props.row.country }}
      </b-table-column>
      <b-table-column
        field="createdAt"
        label="Created At"
        sortable
        v-slot="props"
      >
        {{
          new Date(props.row.createdAt.toDate()).toLocaleDateString("en-ZA", {
            dateStyle: "medium",
          }) +
          " " +
          new Date(props.row.createdAt.toDate()).toLocaleTimeString("en-ZA")
        }}
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No records</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { db } from "@/main";

export default {
  props: {
    dailySummary: { type: Boolean, default: false },
  },
  data() {
    return {
      checkedRows: [],
      perPage: "10",
      loading: false,
      addToAuctionLoading: false,
      currentAuction: null,
    };
  },
  computed: {
    allUsers() {
      if (!this.dailySummary) {
        return this.$store.state.admin.users;
      }
      const today = dayjs(new Date());

      return this.$store.state.admin.users.filter((m) =>
        dayjs(m.createdAt.toDate()).isSame(today, "day")
      );
    },
  },

  methods: {
    async addUsersToAuction() {
      if (this.currentAuction == null) {
        this.alertCustomError("Auction", "No auction found");
        return;
      }
      try {
        this.addToAuctionLoading = true;
        const ids = this.checkedRows.map((row) => row.id);
        console.log("IDS: ");
        console.dir(ids);
        console.log(`selectedAuction`, this.currentAuction);

        await this.$store.dispatch("admin/addAuctionBidders", {
          auctionId: this.currentAuction.id,
          bidderIds: ids,
        });

        this.checkedRows = [];

        this.$buefy.notification.open({
          duration: 5000,
          message: `Successfully added users`,
          position: "is-bottom-right",
          type: "is-success",
          hasIcon: true,
        });

        this.addToAuctionLoading = false;
      } catch (error) {
        console.log(error);

        this.$buefy.notification.open({
          duration: 5000,
          message: `Failed to add users`,
          position: "is-bottom-right",
          type: "is-danger",
          hasIcon: true,
        });

        this.addToAuctionLoading = false;
      }
    },
    clearSelections() {
      this.checkedRows = [];
    },
    alertCustomError(context, message) {
      this.$buefy.dialog.alert({
        title: `No ${context} Selected`,
        message: message,
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },

    async initAuction() {
      const today = new Date();
      const auctions = await db
        .collection("auctions")
        .where("dateRange.end", ">", today)
        .orderBy("dateRange.end")
        .limit(1)
        .get();

      auctions.forEach((doc) => {
        this.currentAuction = doc.data();
        this.currentAuction.id = doc.id;
      });
      console.log("Current Auction: ");
      console.dir(this.currentAuction);
    },
  },

  created() {
    this.initAuction();
  },
};
</script>

<style>
.controls {
  margin-left: 100px;
  margin-bottom: 20px;
}
</style>
