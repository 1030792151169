<template>
  <div class="modal-card box">
    <header class="modal-card-head">
      <p v-if="state === 'register'" class="modal-card-title monFontBold">
        Register
      </p>
      <p v-else class="modal-card-title monFontBold">Login</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body has-text-centered">
      <nav class="level">
        <div class="level-item has-text-centered mt-2">
          <span v-if="state === 'register' && !registered" class="grey mr-2"
            >Already have an account?</span
          >
          <span v-if="state !== 'register'" class="grey mr-2"
            >Are you new?</span
          >
          <a
            v-if="state === 'register' && !registered"
            @click="state = 'login'"
            class="has-text-weight-bold"
          >
            Login Now</a
          >
          <a
            v-if="state !== 'register'"
            @click="state = 'register'"
            class="has-text-weight-bold"
          >
            Sign Up Now</a
          >
        </div>
      </nav>
      <register-form
        v-if="state === 'register' && !registered"
        @registerSucceeded="registerSucceeded"
      ></register-form>
      <login-form
        v-if="state !== 'register'"
        @loginSucceeded="
          $emit('success');
          $parent.close();
        "
      >
      </login-form>
      <verify-email
        @verifySucceeded="verifySucceeded"
        :email="email"
        v-if="state === 'register' && registered"
      ></verify-email>
    </section>
  </div>
</template>

<script>
import RegisterForm from "./RegisterForm.vue";
import LoginForm from "./LoginForm.vue";
import VerifyEmail from "./VerifyEmail.vue";
export default {
  props: {
    initialState: { type: String, default: "register" },
  },
  data() {
    return {
      state: "",
      registered: false,
      email: "",
    };
  },
  created() {
    this.state = this.initialState;
    if (this.$store.state.user.id && !this.$store.state.user.emailVerified) {
      this.registered = true;
      this.email = this.$store.state.user.email;
      this.state = "register";
    }
  },
  methods: {
    registerSucceeded(email) {
      this.email = email;
      this.registered = true;
    },
    verifySucceeded() {
      this.$emit("success");
      this.$parent.close();
    },
  },
  components: { RegisterForm, LoginForm, VerifyEmail },
};
</script>

<style scoped>
.modal-card {
  width: 500px;
}

@media only screen and (max-width: 768px) {
  .modal-card {
    width: 100vw;
  }
}

.modal-card-head {
  background-color: inherit;
  border: inherit;
}
</style>
