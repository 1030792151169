<template>
  <section class="container">
    <h2 v-if="loggedOut" class="title is-2 monFont500 mb-5">
      You have been successfully logged out
    </h2>

    <h2 v-if="logoutError" class="title is-2 monFont500 mb-5">
      An error occured while trying to log out
    </h2>

    <b-button
      v-if="loggedOut"
      tag="router-link"
      to="/login"
      type="is-success is-large"
      class="mt-5 mb-5"
    >
      Log In
    </b-button>

    <b-loading v-model="loading"></b-loading>
  </section>
</template>

<script>
import { auth } from "@/main";

export default {
  metaInfo: {
    title: "Logged Out",
  },
  data() {
    return {
      loggedOut: false,
      logoutError: false,
      loading: true,
    };
  },
  created() {
    this.loading = true;
    try {
      if (this.$store.state.user.id) {
        auth
          .signOut()
          .then(() => {
            this.loading = false;
            this.loggedOut = true;

            this.$store.dispatch("user/clearUser");
          })
          .catch((err) => {
            console.log(err);
            this.logoutError = true;
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.loggedOut = true;
      }
    } catch (err) {
      console.log(err);
      this.logoutError = true;
      this.loading = false;
    }
  },
};
</script>

<style></style>
