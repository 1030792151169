<template>
  <div class="modal-card box">
    <header class="modal-card-head">
      <p class="modal-card-title monFontBold">Login</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body has-text-centered">
      <nav class="level">
        <div class="level-item has-text-centered mt-2">
          <span class="grey mr-2">Are you new?</span>
          <router-link class="has-text-weight-bold" :to="{ name: 'Register' }">
            Sign Up Now</router-link
          >
        </div>
      </nav>
      <login-form @loginSucceeded="$emit('close')"></login-form>
    </section>
  </div>
</template>

<script>
import LoginForm from "./LoginForm.vue";
export default {
  components: { LoginForm },
};
</script>

<style scoped>
.modal-card {
  width: 500px;
}

@media only screen and (max-width: 768px) {
  .modal-card {
    width: 100vw;
  }
}

.modal-card-head {
  background-color: inherit;
  border: inherit;
}
</style>
