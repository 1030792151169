<template>
  <section>
    <div class="titleBlock"></div>
    <div class="columns is-desktop">
      <div class="faqWrapper column is-8-desktop">
        <h2 class="mainHeader monFontBold p-1">Frequently Asked Questions</h2>
        <b-collapse
          class="card"
          animation="slide"
          v-for="(faq, index) of faqs"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
        >
          <template #trigger="props">
            <div class="card-header" role="button">
              <a class="card-header-icon">
                <b-icon
                  size="is-large"
                  :type="props.open ? 'is-primary' : 'is-dark'"
                  :icon="props.open ? 'menu-down' : 'menu-right'"
                >
                </b-icon>
              </a>
              <p class="card-header-title monFontBold">
                {{ faq.question }}
              </p>
            </div>
          </template>
          <div class="card-content">
            <div class="content answer monFont500">
              {{ faq.answer }}
            </div>
          </div>
        </b-collapse>
      </div>

      <div class="column is-4-desktop">
        <div class="carOverview registerCard monFont500">
          <h4 class="monFontBold mb-1">REGISTER NOW</h4>
          <p>Create an account and start buying and selling now.</p>
          <b-button
            @click="$router.push('/register')"
            type="is-primary"
            size="is-medium"
            class="monFont500 mt-2"
            >REGISTER</b-button
          >
        </div>
        <div class="carOverview contactCard monFont500 mb-5">
          <h4 class="monFontBold mb-4">MORE QUESTIONS?</h4>
          <p>
            <a href="mailto:sales@estateauctionhouse.com">
              <b-icon class="pr-2" icon="email"></b-icon>
              sales@estateauctionhouse.com</a
            >
          </p>
          <p>
            <a href="tel:+27674016020">
              <b-icon class="pr-2" icon="phone"></b-icon> +27 67 401 6020</a
            >
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "faq",
  metaInfo: {
    title: "FAQ",
    meta: [
      {
        name: "description",
        content:
          "Find answers to frequently asked questions about the buying, selling and auction process",
      },
    ],
  },
  data() {
    return {
      isOpen: 0,
      faqs: [
        {
          question: "Do I need to provide COR ( Certificate of Roadworthy )",
          answer:
            "No, there are no costs/commitment to Seller under Auction House T & C’S",
        },
        {
          question: "How do I get paid for my Asset?",
          answer: "Flow of monies is directly to Seller from Purchaser",
        },
        {
          question: "Do I need a Sales Agreement?",
          answer:
            "Yes, we handle all the legal requirements to fulfil the deal",
        },
        {
          question: "Where does my asset go to?",
          answer:
            "Your beloved Asset will remain with you and in the comfort of your own environment",
        },
        {
          question: "Do I need to sign a Mandate?",
          answer:
            "Yes you will be asked to enter into a Sales Mandate for a minimum of 30 Days",
        },
        {
          question: " Virtual Showroom to Auction, what’s the difference?",
          answer:
            "Moving an existing Asset into the Auction section allows us to aggressively market and sell your Assets. ",
        },
        {
          question: "Do you have Reserved Auctions?",
          answer:
            "Most of our Auction assets have reserves as best to protect and qualify the Seller",
        },
        {
          question: "Am I forced to sell even if Reserve is met?",
          answer:
            "NO, the Seller reserves all rights as per our Mandate and Terms & Conditions",
        },
        {
          question: "How safe is my Asset through-out this process?",
          answer:
            "Your asset is as safe as it is now, remember NO MONEY, NO ASSET!",
        },
        {
          question: "What happens if my asset doesn’t sell on Auction?",
          answer:
            "Simple, we either re-adjust starting bids or reserves and the asset is placed back into the virtual showroom",
        },
        {
          question: "Am I required to Auction my asset?",
          answer:
            "Absolutely NOT, you can take advantage of our safe online virtual showroom",
        },
        {
          question:
            "So the entire process ( Media Pack, Advertising, Sales and Legal Documents ) will cost me nothing?",
          answer: "Correct!",
        },
        {
          question: "So, how do you make your money?",
          answer:
            "We charge a buyers commission that ranges from 2.5% - 10% ( See Terms & Conditions )",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/bulmaVariables.scss";

.faqWrapper {
  margin-left: 40px;
}
.mainHeader {
  font-size: 32px;
  background-color: $primary;
  color: white;
  margin-bottom: 30px;
}
.answer {
  text-align: left;
  font-size: 18px;
  margin-left: 60px;
}

.card-header {
  background-color: lightgray;
  text-align: left;
}
.card-header-title {
  font-size: 26px;
}

.card {
  margin-bottom: 20px;
}

.carOverview {
  text-align: left;
  background-color: #f1f1f1;
  font-size: 22px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;

  margin-right: 50px;
}

.registerCard {
  position: sticky;
  top: 0;
}

.contactCard {
  margin-top: 30px;
  position: sticky;
  top: 180px;
}

.carOverview h4 {
  font-size: 24px;
}

.carOverview p {
  font-size: 16px;
}

@media (max-width: 768px) {
  .card-header-title {
    font-size: 20px;
  }

  .card {
    margin-bottom: 16px;
  }

  .card-header-icon {
    padding-right: 0;
  }

  .carOverview {
    margin-right: 0px !important;
  }

  .faqWrapper {
    margin-left: 0 !important;
  }
}
</style>
