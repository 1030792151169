<template>
  <section>
    <div v-show="lot.active">
      <div class="imagesBlock tile is-ancestor">
        <div class="tile is-vertical is-8">
          <div class="tile is-parent">
            <div class="mainImageWrapper tile is-child">
              <figure
                class="b-image-wrapper image is-3by2 gradient"
                id="mainImage"
              >
                <span v-if="lot.status === 'Sold'" class="ribbon monFontBold"
                  >SOLD</span
                >
                <transition name="fade">
                  <img
                    v-on:load="heroLoaded = true"
                    v-show="heroLoaded"
                    loading="lazy"
                    class="has-ratio"
                    :src="lot.imageUrls.hero"
                  />
                </transition>
              </figure>
              <div id="lotTitleWrapper" class="tile mt-2">
                <span class="monFontBold" id="lotTitle">{{ lot.title }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-vertical is-4 is-parent">
          <!-- <div v-if="!loaded" class="thumbsGallery">
            <div v-for="i in 8" :key="i" class="thumb ">
              <div class="placeholderThumb gradient"></div>
            </div>
          </div> -->
          <div v-show="loaded" class="thumbsGallery">
            <div
              v-for="(thumb, index) in lot.imageUrls.featured"
              :key="thumb.imageId"
              class="thumb"
              @click="setIndex('featured', index)"
            >
              <img :src="thumb.url" alt="" />
            </div>
            <div style="width: 100%" class="has-text-centered monFont500">
              <a href="#galleries" v-smooth-scroll
                >MORE PHOTOS <b-icon icon="chevron-down"> </b-icon
              ></a>
            </div>
            <div class="mobileSpacer"></div>
            <div
              :class="[
                lot.status === 'On Auction' ? 'inactiveBlock' : 'activeBlock',
                'statusIndicatorButtons',
                'monFont500',
              ]"
            >
              <p>{{ lot.status === "Sold" ? "SOLD" : "FOR SALE" }}</p>
            </div>
            <div
              :class="[
                lot.status === 'On Auction' ? 'activeBlock' : 'inactiveBlock',
                'statusIndicatorButtons',
                'monFont500',
              ]"
            >
              <p>ON AUCTION</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="lot.status === 'On Auction'" class="bidInfoBlock">
        <div class="level monFontReg">
          <!-- Left side -->
          <div class="level-left mt-5 mb-5">
            <div class="bidInfo level-item has-text-centered">
              <div>
                <p class="">CURRENT BID</p>
                <p id="currentBid" class="monFontBold">
                  R {{ showCurrentBid }}
                </p>
              </div>
              <div>
                <p class="">AUCTION ENDS</p>
                <vac v-if="auction" :end-time="auction.dateRange.end.toDate()">
                  <template v-slot:process="{ timeObj }">
                    <p class="monFontBold" v-if="finalAuctionday">
                      {{ ` ${timeObj.h} : ${timeObj.m} : ${timeObj.s}` }}
                    </p>
                    <p class="monFontBold" v-else>{{ `${timeObj.d} days` }}</p>
                  </template>
                  <template v-slot:finish>
                    <span>Done</span>
                  </template>
                </vac>
              </div>
              <div>
                <p class="monFontReg">BIDS</p>
                <p id="currentBid" class="monFontBold">{{ bids.length }}</p>
              </div>
            </div>
          </div>
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <!-- <b-button size="is-large" icon-right="share-variant" /> -->
              <b-button
                @click="loggedIn ? showBidModal() : showLoginModal()"
                class="bidButton monFontBold"
                type="is-danger"
              >
                {{ loggedIn ? "PLACE BID" : "LOGIN TO BID" }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div class="columns reverse-columns">
        <div v-if="loaded" class="column is-8">
          <p v-if="lotInAuction" class="disclaimer monFontBold">
            All registered bidders are required to pay a refundable goodwill fee
            of R500.00 in order to participate in any Auctions. Please use your
            full name as reference and email POP through to
            sales@estateauctionhouse.com This transaction can be prior and
            current during the Auction dates. Please make your electronic
            transfer to below account: <br />
            <br />
            ACCOUNT NAME: ESTATE AUCTION HOUSE <br />
            BANK: First National Bank <br />
            BRANCH: Bryanston <br />
            BRANCH CODE: 250017 <br />
            ACCOUNT NO: 62863223740
          </p>
          <div
            v-for="(info, index) in lot.infoText"
            :key="index"
            class="infoBlock monFont500"
          >
            <h3 class="monFontBold">{{ info.heading }}</h3>
            <ul>
              <li v-for="(fact, index) in info.bulletPoints" :key="index">
                {{ fact.bullet }}
              </li>
            </ul>
          </div>
          <p class="disclaimer monFontBold">
            The description of this
            {{ lot.status == "On Auction" ? "Auction" : "Sale" }} lot is, to the
            best of the seller's knowledge, accurate and not misleading. Classic
            Car Collections requests a range of detail about the lot from the
            seller, and performs a level of due diligence. However, bidders must
            satisfy themselves as to the accuracy of the description, and
            conduct any research they feel is necessary before committing to
            {{ lot.status == "On Auction" ? "an Auction Bid" : "a Sale" }}.
            Please see our Terms and Conditions for full details
          </p>
          <div v-if="lot.video_url" class="videoWrapper video-responsive">
            <iframe
              id="youtubeIframe"
              width="560"
              height="315"
              :src="lot.video_url"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div id="galleries" v-if="loaded">
            <lot-gallery
              :id="gallery.name"
              v-for="gallery in galleries"
              :key="gallery.name"
              :thumbnails="lot.imageUrls[gallery.name]"
              :heading="gallery.title"
              :name="gallery.name"
              @openLightBox="(name, index) => setIndex(name, index)"
            ></lot-gallery>
          </div>
        </div>
        <div class="column">
          <div class="stickyWrapper">
            <div
              v-if="lot.status === 'For Sale'"
              class="carOverview monFont500"
            >
              <h4 class="monFontBold">SALE PRICE</h4>
              <ul>
                <li>R {{ lot.salePrice.toLocaleString("en-za") }}</li>
              </ul>
            </div>
            <div
              v-for="(card, index) in lot.overview"
              :key="index"
              class="carOverview monFont500"
            >
              <h4 class="monFontBold">{{ card.heading }}</h4>
              <ul>
                <li v-for="(item, index) in card.bulletPoints" :key="index">
                  {{ item.bullet }}
                </li>
              </ul>
            </div>
            <div class="carOverview monFont500">
              <h4 class="monFontBold">BUYER'S PREMIUM</h4>
              <ul>
                <li>View our <a target="_blank" href="/terms#23">Fees</a></li>
              </ul>
            </div>
            <b-button
              v-if="lot.status === 'For Sale'"
              class="monFontBold interestedBtn"
              @click="interestedButtonClicked"
              :loading="interestedButtonLoading"
              :disabled="interestRequestSent"
              >I'M INTERESTED</b-button
            >
          </div>
        </div>
      </div>
      <div v-if="loaded">
        <LightBox
          v-for="lightbox in lightboxes"
          :key="lightbox.name"
          :items="getUrls(lot.imageUrls[lightbox.name])"
          thumbsPosition="bottom"
          :index="lightbox.index"
          @close="lightbox.index = null"
          effect="swipe"
        >
        </LightBox>
      </div>
    </div>
    <div v-if="lotUnavailable" class="box has-text-centered successBox">
      <span class="icon has-text-info">
        <i class="mdi mdi-car-brake-alert succIcon"></i>
      </span>
      <p class="title monFont500 mt-5">This lot is not available</p>
      <router-link to="/">return home</router-link>
    </div>

    <div v-if="lotDoesNotExist" class="box has-text-centered successBox">
      <span class="icon has-text-info">
        <i class="mdi mdi-car-brake-alert succIcon"></i>
      </span>
      <p class="title monFont500 mt-5">Lot Not Found</p>
      <router-link to="/">return home</router-link>
    </div>
  </section>
</template>

<script>
import LightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import LotGallery from "../components/LotGallery.vue";
import LoginModal from "../components/LoginModal.vue";
import RegisterModal from "../components/RegisterModal.vue";
import BidModal from "../components/BidModal.vue";
import { ga } from "../utils/analytics";
import { db } from "../main";
import dayjs from "dayjs";

export default {
  metaInfo: {
    title: "Lot",
  },
  data() {
    return {
      loaded: false,
      heroLoaded: false,
      interestedButtonLoading: false,
      interestRequestSent: false,
      galleries: [
        { title: "EXTERIOR PHOTOS", name: "exterior" },
        { title: "INTERIOR PHOTOS", name: "interior" },
        { title: "MECHANICAL PHOTOS", name: "mechanical" },
        { title: "DOCUMENT PHOTOS", name: "document" },
      ],
      lightboxes: [
        { name: "exterior", index: null },
        { name: "interior", index: null },
        { name: "mechanical", index: null },
        { name: "featured", index: null },
        { name: "document", index: null },
      ],
      lot: { active: true, imageUrls: { exterior: [] } },
      lotInAuction: false,
      lotUnavailable: false,
      lotDoesNotExist: false,
      lotId: this.$route.params.id,
      thumbnails: [],

      index: null,

      highBid: null,
      auction: null,
      currentBid: null,
      bids: [],
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.user.id !== null;
    },

    auctionEnd() {
      if (this.auction) {
        const date = dayjs(this.auction.dateRange.end.toDate());

        return date.fromNow();
      }
      return "";
    },
    showCurrentBid() {
      const cb = this.bids[0]?.amount ?? this.lot.bidInfo.startingBid;

      return cb.toLocaleString("en-za");
    },
    finalAuctionday() {
      if (this.auction) {
        const date = dayjs(this.auction.dateRange.end.toDate());

        const today = dayjs();

        const endHours = date.diff(today, "hour");
        console.log("End Hours: " + endHours);

        if (endHours < 24) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    setFeaturedIndex(item) {
      this.index = this.lot.imageUrls.featured.indexOf(item);
      console.log("Index Set: " + this.index);
    },
    setIndex(name, index) {
      console.log("NAME: " + name);
      console.log("Index: " + index);
      const lightbox = this.lightboxes.find((l) => l.name == name);
      console.log("Lightbox: " + JSON.stringify(lightbox));
      lightbox.index = index;
      console.log("Lightbox: " + JSON.stringify(lightbox));
    },
    getUrls(gallery) {
      return gallery.map((o) => o.url);
    },
    async loginSuccess() {
      console.log("Logged in from modal");
      this.interestedButtonLoading = true;

      this.sendInterestedRequest();
    },

    showLoadingModal() {},

    showLoginModal() {
      this.$buefy.modal.open({
        parent: this,
        component: LoginModal,
        events: {
          success: (val) => this.loginSuccess(val),
        },
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
      });
    },
    showRegisterModal() {
      this.$buefy.modal.open({
        parent: this,
        component: RegisterModal,
        events: {
          success: (val) => this.loginSuccess(val),
        },
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
      });
    },
    showBidModal() {
      this.$buefy.modal.open({
        parent: this,
        component: BidModal,
        props: {
          startingBid: this.lot.bidInfo.startingBid,
          currentBid: this.bids[0]?.amount ?? null,
          bidIncrement: this.lot.bidInfo.bidIncrement,
          lotId: this.lotId,
        },
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
      });
    },
    interestedButtonClicked() {
      if (
        this.$store.state.user.id !== null &&
        this.$store.state.user.emailVerified
      ) {
        this.sendInterestedRequest();
      } else {
        this.showRegisterModal();
      }
    },

    showSubmittedModal() {
      this.$swal({
        title: "Request Submitted",
        html: "We will be in touch!",
        icon: "success",
      });
    },

    async sendInterestedRequest() {
      console.log("Sending Interest Request");

      const userId = this.$store.state.user.id;

      if (userId) {
        try {
          this.interestedButtonLoading = true;
          await this.$store.dispatch("lot/addInterestRequest", {
            lotId: this.lotId,
            userId: userId,
          });

          this.showSubmittedModal();
          this.interestedButtonLoading = false;
          this.interestRequestSent = true;
        } catch (error) {
          console.log(error);
          this.interestedButtonLoading = false;
          this.interestRequestSent = false;

          this.$swal({
            title: "Failed to submit request",
            html: "Please try again later or contact us directly",
            icon: "error",
          });
        }
      } else {
        this.interestedButtonLoading = false;
        console.log("Not logged In");
      }
    },

    async checkInterestRequest() {
      const userId = this.$store.state.user.id;
      if (userId) {
        const exists = await this.$store.getters["lot/interestRequestExists"](
          this.lotId,
          userId
        );
        if (exists) {
          console.log("Interest Request found");
          this.interestRequestSent = true;
        }
      }
    },

    async loadAuctionInfo() {
      try {
        const today = new Date();
        const auctions = await db
          .collection("auctions")
          .where("dateRange.end", ">", today)
          .orderBy("dateRange.end")
          .limit(1)
          .get();

        let auctionFound = false;
        let auctionActive = false;
        auctions.forEach((doc) => {
          auctionFound = true;
          this.auction = doc.data();
          this.auction.id = doc.id;
        });

        if (auctionFound && this.auction.dateRange.start.toDate() < today) {
          console.log("Auction Active: ");
          auctionActive = true;
        }

        if (this.auction.lots.includes(this.lotId)) {
          this.lotInAuction = true;
        }
        console.log("Auction Found: " + auctionFound);
        console.dir(this.auction);
        if (auctionFound && this.lotInAuction && auctionActive) {
          this.lot.status = "On Auction";
          console.log("LOT ON AUCTION");
          this.$bind(
            "bids",
            db
              .collection(`auctions/${this.auction.id}/bids`)
              .where("lotId", "==", this.lotId)
              .where("rejected", "==", false)
              .orderBy("amount", "desc")
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
  },

  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    try {
      this.$store.getters["lot/getLot"](this.lotId).then((doc) => {
        console.log("Doc: ");
        console.dir(doc);
        if (doc.exists) {
          this.lot = doc.data();
          if (!this.lot.active) {
            this.lotUnavailable = true;
          }
          this.loaded = true;
          console.log("Lot set");
          console.dir(this.lot);

          ga.viewLotEvent(this.lotId, this.lot.title, this.lot.make);
        } else {
          this.lot.active = false;
          this.lotDoesNotExist = true;
          console.log("LOT NOT FOUND");
        }
      });
    } catch (error) {
      console.log(error);
    }

    try {
      this.loadAuctionInfo().then(() => {
        if (this.auction) {
          console.log("Auction FOUND: " + this.auction.id);
        }
      });
    } catch (error) {
      console.log("Auction error");
      console.log(error);
    }

    try {
      this.checkInterestRequest();
    } catch (error) {
      console.log("Interest rewuest failed");
    }
  },

  components: {
    LightBox,
    LotGallery,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/bulmaVariables.scss";

.ribbon {
  position: absolute;
  z-index: 5;
  background-color: red;
  left: -55px;
  top: 20px;
  transform: rotate(-45deg);
  width: 200px;
  color: white;
  padding: 10px;
  filter: opacity(1) !important;
}
.opacityLowered {
  filter: opacity(0.6);
}

.fade-enter-active {
  transition: opacity 1s ease-in-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

.gradient {
  background: $primary;
  animation-name: example;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-direction: alternate;
}

@keyframes example {
  from {
    background-color: $primary;
  }
  to {
    background-color: $secondary;
  }
}
//cool-lightbox--show-thumbs

.bidButton {
  background-color: red !important;
  border-radius: 0 !important;
  padding: 5px 30px;
  font-size: 24px;
  margin: 20px;
}

.interestedBtn {
  margin-top: 20px;
  font-size: 26px;
  color: white;
  background-color: red;
}
.interestedBtn:disabled {
  color: white;
  background-color: darkred;
}

.level-right {
  margin: 0 80px;
}

.bidInfo > div {
  padding: 0 4vw;
}

.is-parent {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.disclaimer {
  font-size: 20px;
  text-align: left;
  padding: 25px;
  margin-left: 120px;
}

.videoWrapper {
  margin-left: 130px;
  margin-top: 50px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.carOverview {
  text-align: left;
  background-color: #f1f1f1;
  font-size: 22px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 30px;
}

.carOverview h4 {
  font-size: 28px;
}

.bidInfoBlock {
  color: white;
  background-color: rgba($secondary, 0.75);
  /* opacity: 0.75;*/
  padding-left: 80px;
  font-size: 24px;
  /* position: absolute;*/
  top: 0;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  z-index: 20;
}

.stickyWrapper {
  top: 0;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  z-index: 18;
}

a {
  text-decoration: underline;
}

.mobileSpacer {
  display: none;
}

.level-left {
  width: 50vw;
}

.level-item {
  padding-right: 80px;
}

.mainImageWrapper {
  height: 100%;
}

#heroImage {
  object-fit: cover;
}

.infoBlock {
  margin-top: 30px;
  text-align: left;
  margin-left: 130px;
  font-size: 20px;
}

.infoBlock ul {
  /*list-style: square outside none;*/
  list-style: none;
  margin: 1em;
  padding: 0;
}
h3 {
  font-size: 28px;
  margin-bottom: 30px;
  text-align: left;
}

.infoBlock li {
  margin: 0;
  margin-bottom: 1em;
  padding-left: 1.5em;
  position: relative;

  &::after {
    content: "";
    height: 0.4em;
    width: 0.4em;
    background: #000000;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    top: 0.5em;
    left: 0;
  }
}

.firebase-emulator-warning {
  display: none;
}

.thumbsGallery {
  display: flex;
}

.thumbsGallery {
  flex-wrap: wrap;

  .thumb {
    flex-basis: 50%;
  }
}

.thumb img {
  object-fit: cover;
  width: 15.5vw;
  height: 9.8vw;
}

.placeholderThumb {
  width: 15.5vw;
  height: 9.8vw;
}

.succIcon {
  font-size: 100px;
  color: red;
}

.successBox {
  padding: 50px;
  width: 700px;
  margin: 50px auto;
}

@media only screen and (max-width: 768px) {
  .successBox {
    width: 70vw;
  }
  .gallery {
    margin-left: 10px;
    margin-right: 10px;
  }

  .level-left {
    width: 100vw;
  }

  .videoWrapper {
    margin-left: 10px;
    margin-right: 10px;
  }

  .galleryHeading {
    margin-left: 20px;
  }
  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
  .thumb {
    height: 200px;
    padding: 5px;
    box-sizing: border-box;
    flex: 1;
  }

  .thumb img {
    object-fit: cover;
    width: 300px;
    height: 200px;
    padding-top: 10px;
  }

  .mobileSpacer {
    display: block;
    width: 100vw;
    height: 20px;
  }
  .statusIndicatorButtons {
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  #lotTitleWrapper {
    background-color: $primary;
    padding-left: 0 !important;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .bidInfoBlock {
    padding: 0;
    padding-top: 5px;
    font-size: 18px;
  }

  .bidInfo > div {
    padding: 10px;
  }

  .level-item {
    padding: 0;
  }

  .infoBlock {
    margin-top: 30px;
    text-align: left;
    margin-left: 20px;
    font-size: 20px;
  }

  .disclaimer {
    margin-left: 20px;
  }

  /*.level {
    overflow-x: scroll;
  }*/
}

#lotTitleWrapper {
  background-color: $primary;
  padding-left: 130px;
  padding-top: 30px;
  padding-bottom: 30px;
}

#lotTitle {
  color: white;
  font-size: 30px;
}

.statusIndicatorButtons {
  color: white;
  width: 100%;
  height: 3.5vw;
  line-height: 4vw;
  margin: 5px;
  font-size: 24px;
}

.inactiveBlock {
  background-color: rgb(187, 187, 187);
}

.activeBlock {
  background-color: red;
}
</style>
