<template>
  <div
    :class="[
      view === 'home' ? 'carouselHeight70' : 'carouselHeight40',
      'carousel',
    ]"
  >
    <div
      style="width: 100%"
      :class="[
        view === 'home' ? 'placeholderHeight70' : 'placeholderHeight40',
        'gradient',
      ]"
      v-if="!swiperVisible"
    ></div>
    <div v-show="swiperVisible" id="featuredLotSwiper" class="home-carousel">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide" v-for="img in images" :key="img">
          <img
            :class="[view === 'home' ? 'carouselHeight70' : 'carouselHeight40']"
            :src="require(`@/assets/images/banner/${img}`)"
          />
        </div>
      </div>

      <div class="swiper-pagination"></div>

      <!-- If we need navigation buttons -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <div class="centered title loraFont">
      <span> {{ auctionTitle }} </span>
      <span id="auctionDate"> {{ auctionDates }} </span>
    </div>
  </div>
</template>

<script>
import { db } from "../main";
import Swiper from "swiper";
// import Swiper styles
import "swiper/swiper-bundle.css";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper/core";

// Install modules
SwiperCore.use([Navigation, Pagination, Scrollbar]);

export default {
  props: {
    view: { type: String, default: "home" },
  },
  data() {
    return {
      images: ["banner1.jpg", "banner2.jpg", "banner3.jpg"],
      auctionDates: "",
      auctionTitle: "",
      swiperVisible: false,
      swiperOptions: {
        autoplay: false,
        loop: false,
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
        },
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          init: this.swiperInit,
        },
      },
    };
  },
  mounted() {
    const swiper = new Swiper(".home-carousel", this.swiperOptions);
  },
  methods: {
    async loadData() {
      const today = new Date();
      const auctions = await db
        .collection("auctions")
        .where("dateRange.end", ">", today)
        .orderBy("dateRange.end")
        .limit(1)
        .get();

      let auctionActive = false;
      let auction = null;
      let auctionFound = false;
      auctions.forEach((doc) => {
        auctionFound = true;
        auction = doc.data();
        auction.id = doc.id;
        console.dir(auction);
      });

      if (auctionFound) {
        this.auctionDates = `${auction.dateRange.start
          .toDate()
          .toLocaleDateString("en-ZA", {
            month: "long",
            day: "numeric",
          })} - ${auction.dateRange.end.toDate().toLocaleDateString("en-ZA", {
          month: "long",
          day: "numeric",
        })}`;

        if (auction.dateRange.start.toDate() < today) {
          console.log("Auction Active: ");
          auctionActive = true;
        }

        if (auctionActive) {
          this.auctionTitle = "Live Auction: ";
        } else {
          this.auctionTitle = "Upcoming Auction: ";
        }
      }
    },
    swiperInit() {
      this.swiperVisible = true;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/bulmaVariables.scss";

.gradient {
  background: $primary;
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-direction: alternate;
}

@keyframes pulse {
  from {
    background-color: $primary;
  }
  to {
    background-color: $secondary;
  }
}

.carousel {
  width: 100vw;

  margin-bottom: 30px;
}

img {
  width: 100%;
  object-fit: cover;
  filter: brightness(0.5);
}

.carouselHeight70 {
  max-height: 70vh;
}

.carouselHeight40 {
  max-height: 40vh;
}

.placeholderHeight40 {
  height: 40vh;
}

.placeholderHeight70 {
  height: 70vh;
}

.swiper-button-prev,
.swiper-button-next {
  color: lightgray;
  z-index: 50 !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 100px;
}

.swiper-button-prev {
  margin-left: 30px;
}

.swiper-button-next {
  margin-right: 42px;
}

.swiper-pagination >>> .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  background: lightgray !important;
}

.swiper-pagination >>> .swiper-pagination-bullet-active {
  background: lightgray !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 60px;
  width: 80%;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .centered {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 35px;
    width: 80%;
  }

  #auctionDate {
    display: block;
    position: absolute;
    top: 20vh;
    width: 100%;
    font-size: 24px;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 50px;
  }

  .swiper-button-prev {
    margin-left: 10px;
  }

  .swiper-button-next {
    margin-right: 10px;
  }
}
</style>
