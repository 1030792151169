<template>
  <section class="wrapper">
    <algolia-search></algolia-search>
  </section>
</template>

<script>
import AlgoliaSearch from "../components/AlgoliaSearch.vue";

export default {
  metaInfo: {
    title: "Buy",
    meta: [
      {
        name: "description",
        content: "View our Classic Car Collections Showroom and buy now",
      },
    ],
  },
  components: { AlgoliaSearch },
};
</script>

<style scoped>
.wrapper {
  margin-bottom: 80px;
}
</style>
