<template>
  <div class="bottom">
    <h1 class="title is-2 monFontBold mb-5">Users</h1>

    <users-table></users-table>

    <div class="columns mt-5">
      <div class="column is-3">
        <contact-message-table :dailySummary="false"></contact-message-table>
      </div>
      <div class="column is-8">
        <interest-request-table :dailySummary="false"></interest-request-table>
      </div>

      <div class="column is-1"></div>
    </div>
  </div>
</template>

<script>
import ContactMessageTable from "../../components/admin/ContactMessageTable.vue";
import InterestRequestTable from "../../components/admin/InterestRequestTable.vue";
import UsersTable from "../../components/admin/UsersTable.vue";
export default {
  components: { ContactMessageTable, InterestRequestTable, UsersTable },
  data() {
    return {
      loadingComp: null,
    };
  },

  created() {
    console.log("USERS CREATED");
    // this.loadingComp = this.$buefy.loading.open({
    //   container: null,
    // });
  },
};
</script>

<style scoped>
.margins {
  margin: 20px;
}
.bottom {
  margin-bottom: 200px;
}
</style>
