<template>
  <div class="home">
    <home-carousel></home-carousel>
    <h1 class="featuredTitle monFontReg mb-2">FEATURED COLLECTION</h1>

    <featured-lot-row></featured-lot-row>
    <b-button
      tag="router-link"
      to="/buy"
      type="is-primary"
      size="is-large"
      class="viewMoreBtn mt-4"
      >View More</b-button
    >
    <h2 class="featuredTitle welcomeTitle monFontReg">
      WELCOME TO CLASSIC CAR COLLECTIONS
    </h2>
    <div class="columns is-centered">
      <div class="column col1" v-html="column1"></div>
      <div class="column col2" v-html="column2"></div>
    </div>
    <div class="banner">
      <div>
        <h2 id="callToAction" class="action monFontBold">
          SUBSCRIBE TO OUR NEWSLETTER
        </h2>
        <newsletter-subscribe></newsletter-subscribe>
        <h4 class="actionSub monFontReg">
          Be the first to see our new arrivals
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import HomeCarousel from "../components/HomeCarousel.vue";

import { db } from "../main";
import FeaturedLotRow from "../components/FeaturedLotRow.vue";
import NewsletterSubscribe from "../components/newsletterSubscribe.vue";

export default {
  name: "Home",
  metaInfo: {
    title: "Home",
    meta: [
      {
        name: "description",
        content:
          "Buy and Sell cars via online auctions and virtual showrooms. We gather vehicles from various platforms and market assets locally and internationally",
      },
    ],
  },
  data() {
    return {
      featuredLots: [],
      column1: `<p>
        Classic Car Collections, a division of <a href='https://estateauctionhouse.com' target="_blank">Estate Auction House Pty Ltd</a>, has established itself as a leader in the world of 
         online auctions. We gather consignments from across specialized private and corporate platforms and market assets locally and throughout the world.
      </p><br/>

 <p>
   Our streamlined process is making buying and selling cars via online auctions and virtual showrooms, one of the most effective and painless ways of transacting.
 </p><br/>

 <p>
   We've built a dedicated platform for the purpose of ensuring a
   simple and enjoyable experience for both buyers and sellers.
 </p><br/>`,

      column2: `<p>
        Our platform provides a safe, smart and simple online auction 
         experience where sellers can have confidence in the bidding 
         and buyers can have confidence in the cars.
      </p><br/>

 <p>
   Our knowledgeable team understands the value of every car,
   no matter what it is. We encourage anyone thinking about
   selling their car to contact us to discuss how we may help.
 </p><br/>`,
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.user.id !== null;
    },
  },
  created() {
    db.collection("lots")
      .where("featured", "==", true)
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          this.featuredLots.push(doc.data());
        });
      });
  },
  components: {
    HomeCarousel,
    FeaturedLotRow,
    NewsletterSubscribe,
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/bulmaVariables.scss";

.column {
  text-align: left;
  font-size: 28px;
}

.col1 {
  padding-left: 100px;
}

.col2 {
  padding-right: 100px;
}

.viewMoreBtn {
  display: none;
}

@media screen and (max-width: 768px) {
  .viewMoreBtn {
    display: inline-flex;
  }
  .col1 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .col2 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .featuredTitle {
    font-size: 24px !important;
    margin-bottom: 15px !important;
    letter-spacing: 1px !important;
  }
}

@media screen and (max-width: 900px) {
  #callToAction {
    font-size: 24px;
  }

  .actionSub {
    font-size: 18px !important;
  }

  .banner {
    height: 35vh !important;
  }
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28vh;
  margin-bottom: 20px;
  padding: 20px;

  background-color: $secondary;
}

.banner div h2,
h4 {
  color: white;
}

.featuredTitle {
  font-size: 40px;
  letter-spacing: 2px;
}

.action {
  font-size: 32px;
  letter-spacing: 2px;
}

.actionSub {
  font-size: 24px;
}

.welcomeTitle {
  margin: 35px 0;
}
</style>
