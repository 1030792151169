<template>
  <section class="wrapper">
    <div class="modal-card box">
      <header class="modal-card-head">
        <p class="modal-card-title monFontBold">
          {{ action === "edit" ? "Update Auction Dates" : "New Auction" }}
        </p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body has-text-centered">
        <VueDatePicker
          class="mb-5"
          placeholder="Select auction dates"
          :color="color"
          range
          v-model="dateRange"
        ></VueDatePicker>

        <b-button
          @click="action === 'edit' ? updateAuction() : insertAuction()"
          :loading="loading"
          type="is-primary"
          size="is-large"
          class="mt-5"
          >{{ action === "edit" ? "Update Auction" : "Add Auction" }}</b-button
        >
      </section>
    </div>
  </section>
</template>

<script>
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";

export default {
  props: {
    auctionId: { type: String },
    existingDate: { type: Object, default: null },
    action: { type: String },
  },
  data() {
    return {
      dateRange: null,
      loading: false,
      color: "#004f4c",
    };
  },
  methods: {
    async updateAuction() {
      console.log("Hello update?");
      try {
        this.loading = true;
        await this.$store.dispatch("admin/updateAuctionDates", {
          dateRange: this.dateRange,
          auctionId: this.auctionId,
        });
        this.loading = false;
        this.$emit("close");
      } catch (error) {
        console.log("Error: " + error);
      }
    },

    async insertAuction() {
      console.log("Hello?");
      if (!this.dateRange?.start || !this.dateRange?.end) {
        this.$buefy.notification.open({
          duration: 5000,
          message: `Invalid Date`,
          position: "is-top",
          type: "is-danger",
          hasIcon: true,
        });
        return;
      }
      try {
        this.loading = true;
        await this.$store.dispatch("admin/insertAuction", {
          dateRange: this.dateRange,
        });
        this.loading = false;
        this.$emit("close");
      } catch (error) {
        this.$buefy.notification.open({
          duration: 5000,
          message: `Failed to add auction`,
          position: "is-bottom-right",
          type: "is-danger",
          hasIcon: true,
        });
        this.loading = false;
        console.log("Error: " + error);
      }
    },
  },
  created() {
    if (this.action === "edit") {
      this.dateRange = this.existingDate;
    }
  },
  components: {
    VueDatePicker,
  },
};
</script>

<style lang="scss" scoped>
input {
  margin-left: 80px;
  margin-right: 80px;
}
</style>
