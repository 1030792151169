<template>
  <section>
    <b-field :label="title" custom-class="is-large"></b-field>
    <b-field v-if="imageType !== 'featured'" custom-class="is-large">
      <b-upload
        @input="imagesUploaded"
        accept="image/jpeg"
        v-model="dropFiles"
        multiple
        drag-drop
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"> </b-icon>
            </p>
            <p>Drop images here or click to upload (MAX SIZE: 500 KB)</p>
          </div>
        </section>
      </b-upload>
    </b-field>
    <b-progress
      v-if="showProgress"
      type="is-success"
      :value="progressValue"
      :max="dropFiles.length"
      show-value
    ></b-progress>
    <div v-if="imageType !== 'featured'" class="tags mt-3">
      <div
        class="m-5"
        style="position: relative"
        v-for="(file, index) in thumbnails"
        :key="index"
      >
        <img width="100px" height="100px" :src="getTempUrl(file)" />
        <span
          style="position: absolute; left: 0; top: -20px; z-index: 20"
          class="tag is-primary"
        >
          {{ file.name.length > 12 ? file.name.substring(0, 12) : file.name }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { storage } from "../main";

import "vue-simple-context-menu/dist/vue-simple-context-menu.css";

const IMAGE_URL = process.env.VUE_APP_IMAGE_URL;
export default {
  props: {
    group: { type: Object },
    title: { type: String },
    imageType: { type: String },
    existingImageUrls: { type: Array, required: false },
    lotId: { type: String },
    context: { type: String, default: "admin" },
  },
  data() {
    return {
      isLoading: false,
      showProgress: false,
      progressValue: 0,
      maxImageSize: 500090,
      storageRef: null,
      dropFiles: [],
      imageIds: [],
      thumbnails: [],
      imageUrls: [],
    };
  },
  methods: {
    getImageUrl(imageId) {
      return `${IMAGE_URL}/sellerLots%2F${this.lotId}%2F${this.imageType}%2F${imageId}?alt=media`;
    },
    getTempUrl(file) {
      console.log("Size: " + file.size);
      return URL.createObjectURL(file);
    },

    imagesUploaded() {
      this.uploadFiles();
    },

    async uploadFiles() {
      if (this.dropFiles.length === 0) {
        this.$swal("No Images", "Add images before uploading", "error");
        return;
      }

      this.progressValue = 0;
      this.showProgress = true;
      const res = [];
      const failedImages = [];
      this.imageIds = [];

      for (const file of this.dropFiles) {
        try {
          console.log("File Size: " + file.size);
          console.log("Max Size: " + this.maxImageSize);

          if (file.size > this.maxImageSize) throw "Image is too large";

          if (file.type !== "image/jpeg") throw "File must be a JPEG image";

          const val = await this.uploadFile(file);
          res.push(val);
          this.thumbnails.push(file);

          this.progressValue++;
        } catch (error) {
          failedImages.push(file.name + " - " + error);
        }
      }

      this.showProgress = false;

      try {
        this.isLoading = true;

        const uploadedImages = this.imageIds.map((id) => {
          console.log(id);
          return { url: this.getImageUrl(id), imageId: id };
        });

        this.imageUrls = this.imageUrls.concat(uploadedImages);

        this.isLoading = false;
        this.dropFiles = [];

        console.log("Failed Images: " + JSON.stringify(failedImages));
        if (failedImages.length === 0) {
          this.$buefy.toast.open({
            message: `Uploaded all ${this.imageType} photos`,
            type: "is-success",
          });
        } else {
          this.$swal(
            "Some Images Were Not Uploaded",
            failedImages.join("<br>"),
            "error"
          );
        }
      } catch (error) {
        console.log(error);
        this.$swal(
          "Failed to upload Images",
          "An unexpected error occured",
          "error"
        );
      }
    },
    uploadFile(file) {
      const extension = file.name.split(".").pop();

      console.dir(file);

      console.log("Extension: " + extension);

      const imageId = create_UUID();
      const imagesRef = this.storageRef.child(
        `sellerLots/${this.lotId}/${this.imageType}/${imageId}.${extension}`
      );

      this.imageIds.push(`${imageId}.${extension}`);

      const p = imagesRef.put(file);

      return p;
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
  },
  created() {
    this.storageRef = storage.ref();
  },
};

function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "img-xxxx-xxxx-xxxx-xxxxxxx".replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}
</script>

<style lang="scss" scoped>
@import "@/assets/bulmaVariables.scss";
.galleryHeading {
  margin-left: 130px;
}

.galleryImage {
  object-fit: cover;
  width: 300px;
  height: 200px;
  margin: 5px;
}

.galleryGrid {
  background-color: rgb(233, 233, 233) !important;
  min-height: 400px;
}

.uploadButton {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
