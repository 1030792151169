<template>
  <section>
    <admin-header></admin-header>
    <router-view></router-view>
  </section>
</template>

<script>
import AdminHeader from "../../components/admin/AdminHeader.vue";
export default {
  components: { AdminHeader },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.$store.dispatch("admin/bindLots").then(() => {
      this.loading = false;
    });

    this.$store.dispatch("admin/bindContactMessages").then(() => {
      this.loading = false;
    });

    this.$store.dispatch("admin/bindInterestRequests").then(() => {
      this.loading = false;
    });

    this.$store.dispatch("admin/bindSubscribers").then(() => {
      this.loading = false;
    });

    this.$store.dispatch("admin/bindUsers").then(() => {});
  },
};
</script>

<style lang="scss" scoped></style>
