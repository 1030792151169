var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ValidationProvider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"margins",attrs:{"position":"is-centered","label":"Name","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"placeholder":"Your name"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Surname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"margins",attrs:{"position":"is-centered","label":"Surname","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text","placeholder":"Your surname","required":""},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"margins",attrs:{"position":"is-centered","label":"Email","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"autocomplete":"email","type":"email","placeholder":"Your email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)}),_c('b-field',{staticClass:"margins",attrs:{"position":"is-centered","label":"Phone Number","message":_vm.phoneFieldMessage,"type":{
        'is-danger': _vm.phoneNumberInvalid,
        'is-success': _vm.phoneNumberValid,
      }}},[_c('b-input',{attrs:{"autocomplete":"tel","type":"tel","id":"phone"},on:{"blur":_vm.validateNumber}})],1),_c('ValidationProvider',{attrs:{"rules":"required","name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Country","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"autocomplete":"off","placeholder":"Select a country"},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}},_vm._l((_vm.countries),function(country){return _c('option',{key:country.code,domProps:{"value":country.code}},[_vm._v(" "+_vm._s(country.name)+" ")])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:6|hasNumber","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var valid = ref.valid;
return [_c('b-field',{staticClass:"margins",attrs:{"position":"is-centered","label":"Password","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"password","icon-pack":"mdi","password-reveal":"","placeholder":"Your password"},on:{"focus":function($event){_vm.passwordFocus = true},"blur":function($event){_vm.passwordFocus = false}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('ul',[_c('li',{class:_vm.password.length < 6 ? 'notValid' : 'valid'},[_vm._v(" At least 6 characters ")]),_c('li',{class:!_vm.hasNumber(_vm.password) ? 'notValid' : 'valid'},[_vm._v(" At least one number ")])]),_c('b-field',{staticClass:"mt-5 mb-4"},[_c('b-checkbox',{attrs:{"size":"is-medium"},model:{value:(_vm.termsCheckbox),callback:function ($$v) {_vm.termsCheckbox=$$v},expression:"termsCheckbox"}},[_vm._v(" I agree to the "),_c('a',{attrs:{"href":"/terms","target":"_blank"}},[_c('b',[_vm._v("Terms and Conditions")])])])],1),_c('b-field',{attrs:{"label":""}},[_c('b-checkbox',{model:{value:(_vm.subscribeCheckbox),callback:function ($$v) {_vm.subscribeCheckbox=$$v},expression:"subscribeCheckbox"}},[_vm._v("Send me occasional newletters about new lots")])],1),_c('b-button',{staticClass:"mt-4",attrs:{"disabled":!_vm.termsCheckbox,"loading":_vm.isLoading,"type":"is-primary is-large"},on:{"click":function($event){return handleSubmit(_vm.signup)}}},[_vm._v("Sign Up")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }