import Vue from "vue";
import Vuex from "vuex";
import admin from "./modules/admin";
import lot from "./modules/lot";
import user from "./modules/user";
import VuexPersist from "vuex-persist";
import { vuexfireMutations } from "vuexfire";

const vuexLocal = new VuexPersist({ storage: window.sessionStorage });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: { ...vuexfireMutations },
  actions: {},
  modules: { admin, lot, user },
  plugins: [vuexLocal.plugin],
});
