import { analytics } from "../main";

const obj = {
  loginEvent(userId) {
    analytics.logEvent("login", { method: "email" });
    analytics.setUserId(userId);
  },
  registerEvent(userId) {
    analytics.logEvent("sign_up", { method: "email" });
    analytics.setUserId(userId);
  },
  searchEvent(searchTerm) {
    analytics.logEvent("search", { search_term: searchTerm });
  },
  selectContentEvent(contentType, item) {
    analytics.logEvent("select_content", {
      content_type: contentType,
      items: [item],
    });
  },
  viewLotEvent(lotId, title, make) {
    analytics.logEvent("view_item", {
      items: [{ item_id: lotId, item_name: title, item_brand: make }],
    });
  },

  interestRequestEvent(lotId, title) {
    analytics.logEvent("interest_request", {
      items: [{ item_id: lotId, item_name: title }],
    });
  },
  subscribeEvent(loggedIn) {
    const userType = loggedIn ? "Logged In" : "Not Logged In";
    analytics.logEvent("subscribe", { user_type: userType });
  },
  bidEvent(lotId, title, make, amount) {
    analytics.logEvent("bid", {
      items: [
        { item_id: lotId, item_name: title, item_brand: make, value: amount },
      ],
    });
  },
};

export const ga = obj;
