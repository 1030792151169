<template>
  <section class="containerWrapper monFontReg">
    <h1 class="title is-1 has-text-centered">
      {{ "Sell Your Vehicle" }}
    </h1>
    <div v-if="successfulUpload" class="box has-text-centered successBox">
      <span class="icon has-text-info">
        <i class="mdi mdi-check-circle succIcon"></i>
      </span>
      <p class="title monFont500 mt-5">Your vehicle has been submitted</p>
    </div>
    <div v-if="!successfulUpload">
      <div class="box">
        <b-field
          class="has-text-centered"
          label="Basic Info"
          custom-class="is-large"
        ></b-field>
        <b-field custom-class="is-medium" label="Make">
          <b-input v-model="lot.make"></b-input>
        </b-field>
        <b-field custom-class="is-medium" label="Model">
          <b-input v-model="lot.model"></b-input>
        </b-field>
        <b-field custom-class="is-medium" label="Year">
          <b-numberinput
            :controls="false"
            v-model="lot.year"
            :min="1900"
          ></b-numberinput>
        </b-field>

        <b-field custom-class="is-medium" label="Mileage (km)">
          <b-numberinput
            :controls="false"
            v-model="lot.mileage"
            :min="0"
          ></b-numberinput>
        </b-field>
        <b-field custom-class="is-medium" label="Colour">
          <b-input v-model="lot.colour"></b-input>
        </b-field>
      </div>
      <!-- LOT DETAILS -->
      <div class="box">
        <b-field
          class="has-text-centered"
          label="Lot Details"
          custom-class="is-large"
        ></b-field>
        <div class="mb-5 mt-5">
          <b-field
            custom-class="is-medium"
            :label="'Tell us about your vehicle (condition, service history, features, etc...)'"
          >
            <b-input
              style="display: block; width: 100%"
              v-for="(bp, index2) in info.bulletPoints"
              :key="index2"
              v-model="bp.bullet"
              icon-right="close-circle"
              icon-right-clickable
              @icon-right-click="removeBullet(index2)"
            ></b-input>
            <b-button @click="addBullet()" style="display: block"
              >Add Bullet Point</b-button
            >
          </b-field>
        </div>
      </div>
      <!-- PRICE INFO -->
      <div class="box">
        <b-field
          custom-class="is-large"
          label="Price Info"
          class="has-text-centered"
        >
        </b-field>
        <b-field custom-class="is-medium" label="Sale Price">
          {{ numberToWords(lot.salePrice) }}
          <b-numberinput
            :controls="false"
            v-model="lot.salePrice"
            :min="0"
          ></b-numberinput>
        </b-field>
      </div>
      <b-field
        custom-class="is-large"
        label="Sold Price"
        v-if="lot.status === 'sold'"
      >
        {{ numberToWords(lot.soldPrice) }}
        <b-numberinput
          :controls="false"
          v-model="lot.soldPrice"
          :min="0"
        ></b-numberinput>
      </b-field>

      <!-- LOT MEDIA -->

      <div class="box">
        <b-field
          custom-class="is-large"
          label="Lot Media"
          class="has-text-centered"
        >
        </b-field>
        <p class="has-text-centered">
          All images must be landscape (horizontal)
        </p>
        <sortable-upload
          ref="exteriorGallery"
          :context="'sell'"
          :group="{ name: 'upload', pull: 'clone', put: false }"
          :title="'Exterior Images'"
          :imageType="'exterior'"
          :existingImageUrls="lot.imageUrls.exterior"
          :lotId="this.lot.lotId"
          @addToFeatured="addToFeatured($event)"
          @setHero="setHero($event)"
        ></sortable-upload>
        <sortable-upload
          ref="interiorGallery"
          :context="'sell'"
          :group="{ name: 'upload', pull: 'clone', put: false }"
          :title="'Interior Images'"
          :imageType="'interior'"
          :existingImageUrls="lot.imageUrls.interior"
          :lotId="this.lot.lotId"
          @addToFeatured="addToFeatured($event)"
          @setHero="setHero($event)"
        ></sortable-upload>
        <sortable-upload
          ref="mechanicalGallery"
          :context="'sell'"
          :group="{ name: 'upload', pull: 'clone', put: false }"
          :title="'Mechanical Images'"
          :imageType="'mechanical'"
          :existingImageUrls="lot.imageUrls.mechanical"
          :lotId="this.lot.lotId"
          @addToFeatured="addToFeatured($event)"
          @setHero="setHero($event)"
        ></sortable-upload>
        <sortable-upload
          ref="documentGallery"
          :context="'sell'"
          :group="{ name: 'upload', pull: 'clone', put: false }"
          :title="'Document Images'"
          :imageType="'document'"
          :existingImageUrls="lot.imageUrls.document"
          :lotId="this.lot.lotId"
          @addToFeatured="addToFeatured($event)"
          @setHero="setHero($event)"
        ></sortable-upload>
      </div>
      <div class="box">
        <b-field
          class="has-text-centered"
          custom-class="is-large"
          label="Upload Lot"
        >
        </b-field>
        <b-notification
          v-for="(error, index) in errors"
          :key="index"
          type="is-danger"
          has-icon
          :message="'<strong>' + error + '</strong>'"
          aria-close-label="Close notification"
          role="alert"
        >
        </b-notification>
        <b-message
          title="Error!"
          type="is-danger"
          has-icon
          v-model="failedNotification"
          aria-close-label="Close message"
        >
          Failed to Upload Vehicle
        </b-message>
        <b-message
          title="Success!"
          type="is-success"
          has-icon
          v-model="successNotification"
          aria-close-label="Close message"
        >
          Successfully Uploaded Vehicle
        </b-message>
        <b-message
          title="Error!"
          type="is-danger"
          has-icon
          v-model="failedImageNotification"
          aria-close-label="Close message"
        >
          Failed to Upload Images
        </b-message>
        <b-button
          class="mt-5"
          expanded
          type="is-primary"
          size="is-large"
          icon-left="upload"
          @click="upload"
        >
          Upload
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import SortableUpload from "@/components/SellerUpload.vue";

import { fire } from "@/main";

export default {
  metaInfo: {
    title: "Sell",
    meta: [
      {
        name: "description",
        content:
          "Our streamlined process makes selling via online showrooms and auctions extremely effective. Tell us about your car!",
      },
    ],
  },
  data() {
    return {
      successfulUpload: false,
      dropFiles: [],
      errors: [],
      statuses: ["On Auction", "For Sale", "Sold"],
      info: {
        heading: "Tell us about your Vehicle",
        bulletPoints: [],
      },
      lot: {
        status: "For Sale",
        bidInfo: {},
        priceRange: {},
        infoText: [],
        imageUrls: {
          hero: null,
          featured: [],
          exterior: [],
          interior: [],
          mechanical: [],
          document: [],
        },
        keywords: [],
        active: true,
        featured: false,
      },
      loadingComponent: "",
      failedNotification: false,
      successNotification: false,
      failedImageNotification: false,
    };
  },

  methods: {
    addBullet() {
      this.info.bulletPoints.push({ bullet: "" });
    },
    removeBullet(index) {
      this.info.bulletPoints.splice(index, 1);
    },
    getYoutubeEmbeddedUrl(url) {
      const u = new URL(url);
      const urlParams = new URLSearchParams(u.searchParams);
      const videoID = urlParams?.get("v");

      if (!videoID) return null;

      const embedBase = `https://www.youtube.com/embed/${videoID}?rel=0`;

      return embedBase;
    },
    deleteDropFile(index) {
      console.dir(this.dropFiles[index]);

      this.dropFiles.splice(index, 1);
    },
    dateFormat(val) {
      return val.toLocaleString("en-ZA", {
        dateStyle: "medium",
      });
    },
    openLoader() {
      this.loadingComponent = this.$buefy.loading.open({
        container: null,
      });
    },
    closeLoader() {
      this.loadingComponent.close();
    },
    checkForm() {
      this.errors = [];

      if (!this.lot.make || this.lot.make == "") {
        this.errors.push("Vehicle has no make");
      }

      if (!this.lot.model || this.lot.model == "") {
        this.errors.push("Vehicle has no model");
      }

      if (this.lot.salePrice == null) {
        this.errors.push("Vehicle has no sale price");
      }

      return this.errors.length == 0;
    },

    async upload() {
      try {
        this.lot.imageUrls.exterior = this.$refs.exteriorGallery.imageUrls;
        this.lot.imageUrls.interior = this.$refs.interiorGallery.imageUrls;
        this.lot.imageUrls.mechanical = this.$refs.mechanicalGallery.imageUrls;
        this.lot.imageUrls.document = this.$refs.documentGallery.imageUrls;

        this.info.bulletPoints = this.info.bulletPoints.filter(
          (bulletPoint) => bulletPoint.bullet.length > 1
        );

        this.lot.infoText = this.info;

        this.lot.keywords = [];

        let succ = this.checkForm();

        if (!succ) {
          console.log("Check form failed");
          return;
        }

        this.openLoader();

        this.lot.createdAt = fire.firestore.FieldValue.serverTimestamp();

        this.lot.seller = {
          uid: this.$store.state.user.id,
          name:
            this.$store.state.user.firstName +
            " " +
            this.$store.state.user.surname,
        };

        await this.$store.dispatch("admin/insertSellerLot", this.lot);
        console.log("Uploaded Lot:");

        const loadingRef = this.loadingComponent;

        console.dir(this.lot);
        window.scrollTo(0, 0);
        loadingRef.close();
        this.successfulUpload = true;
      } catch (error) {
        const loadingRef = this.loadingComponent;
        loadingRef.close();
        console.log("Failed to upload lot");
        console.dir(error);
        this.$swal(
          "Upload Failed",
          "Unable to upload Vehicle: " + error.message,
          "error"
        );
      }
    },
    numberToWords(num) {
      return numToWords(num);
    },
  },
  mounted() {
    this.addBullet();
    this.lot.lotId = this.$store.getters["admin/getNewLotId"]();
  },

  components: { SortableUpload },
};

const arr = (x) => Array.from(x);
const num = (x) => Number(x) || 0;
const isEmpty = (xs) => xs.length === 0;
const take = (n) => (xs) => xs.slice(0, n);
const drop = (n) => (xs) => xs.slice(n);
const reverse = (xs) => xs.slice(0).reverse();
const comp = (f) => (g) => (x) => f(g(x));
const not = (x) => !x;
const chunk = (n) => (xs) =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

// numToWords :: (Number a, String a) => a -> String
const numToWords = (n) => {
  if (!n) {
    return "";
  }
  let a = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  let b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  let g = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
  ];
  // this part is really nasty still
  // it might edit this again later to show how Monoids could fix this up
  let makeGroup = ([ones, tens, huns]) => {
    return [
      num(huns) === 0 ? "" : a[huns] + " hundred ",
      num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "",
      a[tens + ones] || a[ones],
    ].join("");
  };
  // "thousands" constructor; no real good names for this, i guess
  let thousand = (group, i) => (group === "" ? group : `${group} ${g[i]}`);
  // execute !
  if (typeof n === "number") return numToWords(String(n));
  if (n === "0") return "zero";
  return comp(chunk(3))(reverse)(arr(n))
    .map(makeGroup)
    .map(thousand)
    .filter(comp(not)(isEmpty))
    .reverse()
    .join(" ");
};
</script>

<style lang="scss" scoped>
@import "@/assets/bulmaVariables.scss";
.containerWrapper {
  margin-top: 50px;
  margin-bottom: 100px;
  margin-left: 10vw;
  margin-right: 10vw;
  text-align: left;
}
.box {
  padding: 50px;
}

.switch input[type="checkbox"]:checked + .check {
  background: $primary !important;
}

.mainImageWrapper {
  background-color: rgb(233, 233, 233) !important;
  height: 300px;
  width: 450px;
}

.succIcon {
  font-size: 100px;
  color: $primary;
}

.successBox {
  width: 700px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .successBox {
    width: 70vw;
  }
}
</style>
