<template>
  <section>
    <footer class="footer">
      <div class="content has-text-centered has-text-white">
        <div class="columns monFontReg">
          <div class="column">
            <h6>QUICK LINKS</h6>
            <ul>
              <li><router-link :to="{ name: 'Home' }">Home</router-link></li>
              <li><router-link :to="{ name: 'Buy' }">Buy</router-link></li>
              <li><router-link :to="{ name: 'Sell' }">Sell</router-link></li>
              <li>
                <router-link :to="{ name: 'Auction' }">Auction</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Contact' }">Contact</router-link>
              </li>
              <li v-if="loggedIn">
                <router-link :to="{ name: 'Logout' }">Logout</router-link>
              </li>
              <li v-if="!loggedIn">
                <router-link :to="{ name: 'Login' }">Login</router-link>
              </li>
              <li v-if="!loggedIn">
                <router-link :to="{ name: 'Register' }">Register</router-link>
              </li>
              <li v-if="isAdmin">
                <router-link :to="{ name: 'Admin' }">Admin</router-link>
              </li>
            </ul>
          </div>
          <div class="column">
            <h6>CONTACT US</h6>
            <ul>
              <li>
                <a href="mailto:sales@estateauctionhouse.com">
                  <b-icon class="pr-2" icon="email"></b-icon>
                  sales@estateauctionhouse.com</a
                >
              </li>
              <li>
                <a href="tel:+27674016020">
                  <b-icon class="pr-2" icon="phone"></b-icon> +27 67 401 6020</a
                >
              </li>
              <li>
                <a href="https://www.facebook.com/ClassicCarCollection">
                  <b-icon
                    class="mr-5 grow is-clickable"
                    icon="facebook"
                    size="is-large"
                    type="is-info"
                  >
                  </b-icon>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCoPj397TaHkFg-x4bPTkAkg"
                >
                  <b-icon
                    class="mr-5 grow is-clickable"
                    icon="youtube"
                    size="is-large"
                    type="is-danger"
                  >
                  </b-icon>
                </a>
              </li>
            </ul>
          </div>
          <div class="column">
            <h6>TERMS AND CONDITIONS</h6>
            <ul>
              <li>
                <router-link to="terms">Terms and Conditions</router-link>
              </li>
            </ul>
          </div>
          <div class="column">
            <h6>Subscribe</h6>
            <newsletter-subscribe></newsletter-subscribe>
          </div>
        </div>
      </div>

      <div class="has-text-white bottom">
        <div class="container is-fluid">
          <div class="columns">
            <div class="column">
              <p>
                Classic Car Collections – A division of <br />
                Estate Auction House Pty Ltd <br />
                2020/522145/07
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import newsletterSubscribe from "./newsletterSubscribe.vue";
export default {
  components: { newsletterSubscribe },
  computed: {
    loggedIn() {
      return this.$store.state.user.id !== null;
    },
    isAdmin() {
      return this.$store.state.user.admin;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/bulmaVariables.scss";

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.4);
}

.footer {
  background-color: rgb(49, 49, 49);
}
.content ul {
  padding: 0;
  margin: 0;
}
.content ul li {
  list-style-type: none;
  text-align: center;
  padding: 5px;
}
.content ul li a {
  color: #fff;
  font-size: 18px;
}
.content ul li a:hover {
  color: #ddd;
}
h6 {
  text-align: center;
  color: #fff !important;
  font-size: 26px !important;
  margin-bottom: 25px !important;
}
.is-success {
  background-color: $primary !important;
  margin-top: 15px;
}
.fa {
  padding-right: 10px;
  font-size: 24px;
  cursor: pointer;
}
.control.has-icons-left .icon.is-left {
  left: 5px;
}
p {
  font-size: 18px;
}
.is-success:hover {
  background: $secondary !important;
}

.bottomFooter {
  background-color: rgb(31, 31, 31);
}
@media (max-width: 768px) {
  .bottom p,
  .has-text-right {
    text-align: center !important;
  }
}
</style>
