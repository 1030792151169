<template>
  <section class="margins bottom">
    <b-field grouped group-multiline>
      <b-button
        :disabled="selectedRow == null"
        @click="showQuickEditModal"
        class="mr-5"
        type="is-primary"
        >Quick Edit</b-button
      >
      <b-button
        size="is-small"
        label="Clear selected"
        type="is-danger"
        v-if="selectedRow != null || checkedRows != 0"
        @click="clearSelections"
      />
      <b-select v-model="perPage" class="ml-4 mr-5">
        <option value="10">10 per page</option>
        <option value="20">20 per page</option>
        <option value="100">100 per page</option>
      </b-select>

      <b-select v-model="selectedAuction" class="ml-5">
        <option
          v-for="auction in auctions"
          :key="auction.id"
          :value="auction.id"
        >
          {{
            auction.dateRange.start.toDate().toLocaleDateString("en-ZA", {
              dateStyle: "medium",
            }) +
            " - " +
            auction.dateRange.end.toDate().toLocaleDateString("en-ZA", {
              dateStyle: "medium",
            })
          }}
        </option>
      </b-select>
      <b-button
        :disabled="checkedRows.length == 0"
        class="mr-5"
        icon-left="plus"
        icon-pack="fa"
        type="is-success"
        @click="addLotsToAuction"
        :loading="addToAuctionLoading"
      >
        Add to Auction
      </b-button>
    </b-field>

    <b-table
      height="530px"
      :data="lots"
      :checked-rows.sync="checkedRows"
      checkable
      sticky-header
      sticky-checkbox
      default-sort="bidding_starts"
      default-sort-direction="desc"
      sort-icon="arrow-up"
      sort-icon-size="is-regular"
      :selected.sync="selectedRow"
      paginated
      :per-page="perPage"
      :loading="lots == 0"
      hoverable
    >
      <b-table-column
        field="id"
        label="Lot #"
        width="100"
        sortable
        searchable
        numeric
        v-slot="props"
      >
        {{ props.row.id }}
      </b-table-column>

      <b-table-column
        field="title"
        label="Title"
        centered
        searchable
        v-slot="props"
      >
        {{ props.row.title }}
      </b-table-column>

      <b-table-column
        field="reserve_price"
        :visible="columnsVisible['bidding_amounts'].display"
        label="Reserve Price"
        sortable
        centered
        v-slot="props"
      >
        {{ props.row.reserve_price.toLocaleString("en-ZA", currencyFormat) }}
      </b-table-column>

      <b-table-column
        field="current_bid"
        :visible="columnsVisible['bidding_amounts'].display"
        label="Current Bid"
        sortable
        centered
        v-slot="props"
      >
        {{ props.row.current_bid.toLocaleString("en-ZA", currencyFormat) }}
      </b-table-column>

      <b-table-column
        field="starting_bid"
        :visible="columnsVisible['bidding_amounts'].display"
        label="Starting Bid"
        sortable
        centered
        v-slot="props"
      >
        {{ props.row.starting_bid.toLocaleString("en-ZA", currencyFormat) }}
      </b-table-column>

      <b-table-column
        field="current_bid_user_id"
        :visible="columnsVisible['bidding_amounts'].display"
        label="Current Bid User"
        sortable
        centered
        v-slot="props"
      >
        {{ getUserName(props.row.current_bid_user_id) }}
      </b-table-column>

      <b-table-column
        field="buy_now_price"
        :visible="columnsVisible['bidding_amounts'].display"
        label="Buy Now"
        sortable
        centered
        v-slot="props"
      >
        {{ props.row.buy_now_price.toLocaleString("en-ZA", currencyFormat) }}
      </b-table-column>

      <b-table-column
        field="bid_increment"
        :visible="columnsVisible['bidding_amounts'].display"
        label="Bid Increment"
        sortable
        centered
        v-slot="props"
      >
        {{ props.row.bid_increment.toLocaleString("en-ZA", currencyFormat) }}
      </b-table-column>

      <b-table-column
        field="status"
        label="Status"
        width="60"
        sortable
        searchable
        centered
        v-slot="props"
      >
        <span
          :class="[
            'tag',
            props.row.status === 'On Auction' ? 'is-success' : 'is-dark',
          ]"
        >
          {{ props.row.status }}
        </span>
      </b-table-column>

      <b-table-column
        field="active"
        label="Active"
        sortable
        centered
        v-slot="props"
      >
        <span :class="['tag', props.row.active ? 'is-success' : 'is-danger']">
          {{ props.row.active ? "Yes" : "No" }}
        </span>
      </b-table-column>

      <b-table-column
        field="featured"
        label="Featured"
        sortable
        centered
        v-slot="props"
      >
        <span :class="['tag', props.row.featured ? 'is-success' : 'is-dark']">
          {{ props.row.featured ? "Yes" : "No" }}
        </span>
      </b-table-column>

      <b-table-column
        field="createdAt"
        label="Created On"
        sortable
        centered
        v-slot="props"
      >
        {{
          new Date(props.row.createdAt.toDate()).toLocaleDateString("en-ZA", {
            dateStyle: "medium",
          })
        }}
      </b-table-column>

      <template #bottom-left>
        <download-csv
          v-if="checkedRows.length != 0"
          :data="checkedRows"
          :fields="exportFields"
          name="lots.csv"
        >
          <b-button type="is-success is-light">Export Checked</b-button>
        </download-csv>
        <b-button
          :disabled="selectedRow == null"
          @click="showQuickEditModal"
          class="mr-5"
          type="is-primary"
          >Quick Edit</b-button
        >
        <b-button
          size="is-medium"
          class="ml-4"
          type="is-primary"
          @click="editClicked"
          >Edit</b-button
        >

        <b-button
          size="is-small"
          class="ml-6"
          type="is-danger"
          @click="confirmDelete"
          >Delete</b-button
        >
      </template>
    </b-table>
    <!-- <h2 class="title is-2 has-text-centered">Lot Activity</h2> -->
    <!-- <table-widget-row
      :parentRow="selectedRow"
      context="lot"
      class="mt-6"
    ></table-widget-row> -->
    <b-loading
      :active="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></b-loading>
  </section>
</template>
<script>
import JsonCSV from "vue-json-csv";
import QuickEditModal from "@/components/admin/QuickEditModal.vue";

export default {
  data() {
    return {
      currencyFormat: {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 0,
      },
      selectedRow: null,
      selectedAuction: null,
      columnsVisible: {
        bidding_amounts: { title: "Bidding Info", display: false },
      },
      checkedRows: [],
      loadingComponent: "",
      exportFields: [
        "id",
        "title",
        "current_bid",
        "starting_bid",
        "reserve_price",
        "bid_increment",
        "status",
      ],
      perPage: "10",
      loading: false,
      addToAuctionLoading: false,
    };
  },

  methods: {
    getUserName(userID) {
      let user = this.$store.getters.getUserById(userID);
      if (user) {
        if (user.first_name && user.surname) {
          return `${user.first_name} ${user.surname}`;
        }
        return "No user info";
      }
      return "N/A";
    },
    clearSelections() {
      console.dir(this.checkedRows);
      this.selectedRow = null;
      this.checkedRows = [];
    },
    alertCustomError(context, message) {
      this.$buefy.dialog.alert({
        title: `No ${context} Selected`,
        message: message,
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    confirmDelete() {
      if (this.selectedRow == null) {
        this.alertCustomError("Lot", "Click on a row to edit the lot");
      } else {
        this.$buefy.dialog.confirm({
          title: `Deleting lot: ${this.selectedRow.id}`,
          message:
            "Are you sure you want to <b>delete</b> this lot? This action cannot be undone.",
          confirmText: "Delete Lot",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deleteClicked(),
        });
      }
    },
    showQuickEditModal() {
      this.$buefy.modal.open({
        parent: this,
        component: QuickEditModal,
        props: {
          lotId: this.selectedRow.id,
        },
        // events: {
        //   success: (val) => this.loginSuccess(val),
        // },
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
      });
    },
    editClicked() {
      if (this.selectedRow == null) {
        this.alertCustomError("Lot", "Click on a row to edit the lot");
      } else {
        let id = this.selectedRow.id;
        console.log("Selected Row: ", this.selectedRow);
        this.$router.push(`/admin/lots/${id}`);
      }
    },
    deleteClicked() {
      this.openLoader();
      const loadingRef = this.loadingComponent;
      this.$store
        .dispatch("deleteLot", this.selectedRow)
        .then((res) => {
          loadingRef.close();
          console.log(res);
          this.$buefy.notification.open({
            duration: 5000,
            message: `Successfully deleted lot`,
            position: "is-bottom-right",
            type: "is-success",
            hasIcon: true,
          });
        })
        .catch((err) => {
          loadingRef.close();
          console.log(err);
          this.$buefy.notification.open({
            duration: 5000,
            message: `Failed to delete log`,
            position: "is-bottom-right",
            type: "is-danger",
            hasIcon: true,
          });
        });
    },
    async addLotsToAuction() {
      if (this.selectedAuction == null) {
        this.alertCustomError("Auction", "Select an Auction to add to");
      }
      try {
        this.addToAuctionLoading = true;
        const ids = this.checkedRows.map((row) => row.lotId);
        console.log("IDS: ");
        console.dir(ids);
        console.log(`selectedAuction`, this.selectedAuction);
        await this.$store.dispatch("admin/addAuctionLots", {
          auctionId: this.selectedAuction,
          lotIds: ids,
        });

        this.checkedRows = [];

        this.$buefy.notification.open({
          duration: 5000,
          message: `Successfully added lots`,
          position: "is-bottom-right",
          type: "is-success",
          hasIcon: true,
        });

        this.addToAuctionLoading = false;
      } catch (error) {
        console.log(error);

        this.addToAuctionLoading = false;
      }
    },
    openLoader() {
      this.loadingComponent = this.$buefy.loading.open({
        container: null,
      });
    },
    closeLoader() {
      this.loadingComponent.close();
    },
  },
  computed: {
    lots() {
      return this.$store.state.admin.lots;
    },
    auctions() {
      return this.$store.state.admin.auctions;
    },
  },
  created() {
    this.$store.dispatch("admin/bindLots").then(() => {
      this.loading = false;
    });

    this.$store.dispatch("admin/bindAuctions").then(() => {
      this.selectedAuction = this.$store.state.admin.auctions[0]?.id;
    });
  },
  components: {
    downloadCsv: JsonCSV,
  },
};
</script>
<style scoped>
.margins {
  margin: 20px;
}
.bottom {
  margin-bottom: 100px;
}
</style>
