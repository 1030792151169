import axios from "axios";
import { auth, db } from "../main";
import store from "../store/index";
const obj = {
  async placeBid(amount, lotId) {
    const url = process.env.VUE_APP_FUNCTIONS_API;

    const user = auth.currentUser;

    if (user) {
      const token = await user.getIdToken();

      const payload = { bid: { amount, lotId }, auth: { token } };
      try {
        const res = await axios.post(url + "/bids-bids/place", payload);
        console.dir(res);

        return res;
      } catch (error) {
        console.dir(error);
        return error.response;
      }
    }
  },

  async verifiedToBid() {
    const today = new Date();

    const auctions = await db
      .collection("auctions")
      .where("dateRange.end", ">", today)
      .orderBy("dateRange.end")
      .limit(1)
      .get();

    let auctionFound = false;
    let auction = null;
    auctions.forEach((doc) => {
      auctionFound = true;
      auction = doc.data();
      auction.id = doc.id;
    });

    if (auctionFound) {
      return auction.verifiedUsers?.includes(store.state.user.id) ?? false;
    }

    return false;
  },
};

export const user = obj;
