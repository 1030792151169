<template>
  <section>
    <b-field :label="title" custom-class="is-large"></b-field>
    <b-field v-if="imageType !== 'featured'" custom-class="is-large">
      <b-upload v-model="dropFiles" multiple drag-drop>
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"> </b-icon>
            </p>
            <p>Drop your files here or click to upload</p>
          </div>
        </section>
      </b-upload>
      <b-button @click="uploadFiles" class="ml-2 uploadButton" type="is-primary"
        >Upload Images</b-button
      >
    </b-field>
    <b-progress
      v-if="showProgress"
      type="is-success"
      :value="progressValue"
      :max="dropFiles.length"
      show-value
    ></b-progress>
    <div v-if="imageType !== 'featured'" class="tags mt-3">
      <span
        v-for="(file, index) in dropFiles"
        :key="index"
        class="tag is-primary"
      >
        {{ file.name }}
        <button
          class="delete is-small"
          type="button"
          @click="deleteDropFile(index)"
        ></button>
      </span>
    </div>

    <draggable
      class="box galleryGrid"
      :group="group"
      v-model="thumbnails"
      @start="drag = true"
      @end="drag = false"
    >
      <img
        v-for="(thumb, index) in thumbnails"
        class="galleryImage"
        :src="thumb.url"
        :key="index"
        alt=""
        @contextmenu.prevent.stop="
          handleClick($event, {
            url: thumb.url,
            index: index,
            imageId: thumb.imageId,
          })
        "
      />
    </draggable>

    <vue-simple-context-menu
      :elementId="imageType + 'contextMenu'"
      :options="contextOptions"
      :ref="imageType + 'vueSimpleContextMenu'"
      @option-clicked="optionClicked"
    ></vue-simple-context-menu>
  </section>
</template>

<script>
import { storage } from "@/main";

import draggable from "vuedraggable";
import VueSimpleContextMenu from "vue-simple-context-menu";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";

const IMAGE_URL = process.env.VUE_APP_IMAGE_URL;
export default {
  props: {
    group: { type: Object },
    title: { type: String },
    imageType: { type: String },
    existingImageUrls: { type: Array, required: false },
    lotId: { type: String },
  },
  data() {
    return {
      isLoading: false,
      showProgress: false,
      progressValue: 0,
      storageRef: null,
      dropFiles: [],
      imageIds: [],
      contextOptions: [
        {
          name: "Add to Featured",
          action: "featured",
        },
        {
          name: "Set as hero image",
          action: "hero",
        },
        {
          type: "divider",
        },
        {
          name: "DELETE",
          action: "delete",
        },
      ],
      thumbnails: [],
    };
  },
  methods: {
    getImageUrl(imageId) {
      return `${IMAGE_URL}/lots%2F${this.lotId}%2F${this.imageType}%2F${imageId}?alt=media`;
    },
    handleClick(event, item) {
      console.log("Handle click: " + event);
      this.$refs[this.imageType + "vueSimpleContextMenu"].showMenu(event, item);
    },

    optionClicked(event) {
      console.dir(event);

      console.log("Item: ");
      console.dir(event.item);
      if (event.option.action === "delete") {
        console.log("DELETE ITEM");

        this.isLoading = true;

        if (this.imageType === "featured") {
          this.thumbnails.splice(event.item.index, 1);
        } else {
          this.deleteFile(event.item.imageId, event.item.index).then(() => {
            this.isLoading = false;
          });
        }
      }

      if (event.option.action === "featured") {
        this.$emit("addToFeatured", event.item);
        this.$buefy.toast.open({
          message: "Added To Featured",
          type: "is-success",
        });
      }

      if (event.option.action === "hero") {
        this.$emit("setHero", event.item);
        this.$buefy.toast.open({
          message: "Hero Image Set",
          type: "is-success",
        });
      }
    },

    async uploadFiles() {
      if (this.dropFiles.length === 0) {
        this.$swal("No Images", "Add images before uploading", "error");
        return;
      }

      this.progressValue = 0;
      this.showProgress = true;
      const res = [];
      const failedImages = [];
      this.imageIds = [];
      for (const file of this.dropFiles) {
        try {
          const val = await this.uploadFile(file);
          res.push(val);

          this.progressValue++;
        } catch (error) {
          failedImages.push({ file: file, error: error });
        }
      }

      this.showProgress = false;

      try {
        this.isLoading = true;

        const uploadedImages = this.imageIds.map((id) => {
          console.log(id);
          return { url: this.getImageUrl(id), imageId: id };
        });

        this.thumbnails = this.thumbnails.concat(uploadedImages);

        console.log("Thumbnails: " + JSON.stringify(this.thumbnails));
        this.isLoading = false;
        this.dropFiles = [];

        console.log("Failed Images: " + JSON.stringify(failedImages));
        if (failedImages.length === 0) {
          this.$buefy.toast.open({
            message: `Uploaded all ${this.imageType} photos`,
            type: "is-success",
          });
        } else {
          this.$swal(
            "Some Images Were Not Uploaded",
            failedImages.join(),
            "error"
          );
        }
      } catch (error) {
        console.log(error);
        this.$swal(
          "Failed to upload Images",
          "An unexpected error occured",
          "error"
        );
      }
    },
    async deleteFile(imageId, index) {
      // Create a reference to the file to delete
      const ref = this.storageRef.child(
        `lots/${this.lotId}/${this.imageType}/${imageId}`
      );

      try {
        await ref.delete();

        console.log("Deleted ref");

        this.thumbnails.splice(index, 1);
        this.$buefy.toast.open({
          message: "Deleted Image",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: "Failed to delete image",
          type: "is-success",
        });
      }
      // Delete the file
    },
    uploadFile(file) {
      const extension = file.name.split(".").pop();

      console.dir(file);

      console.log("Extension: " + extension);

      const imageId = create_UUID();
      const imagesRef = this.storageRef.child(
        `lots/${this.lotId}/${this.imageType}/${imageId}.${extension}`
      );

      this.imageIds.push(`${imageId}.${extension}`);

      const p = imagesRef.put(file);

      return p;
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
  },
  components: {
    draggable,
    VueSimpleContextMenu,
  },
  created() {
    this.thumbnails = this.existingImageUrls;

    this.storageRef = storage.ref();

    if (this.imageType === "featured") {
      this.contextOptions = [
        {
          name: "Set as hero image",
          action: "hero",
        },
        {
          type: "divider",
        },
        {
          name: "Remove from Featured",
          action: "delete",
        },
      ];
    }
  },
};

function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "img-xxxx-xxxx-xxxx-xxxxxxx".replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}
</script>

<style lang="scss" scoped>
@import "@/assets/bulmaVariables.scss";
.galleryHeading {
  margin-left: 130px;
}

.galleryImage {
  object-fit: cover;
  width: 300px;
  height: 200px;
  margin: 5px;
}

.galleryGrid {
  background-color: rgb(233, 233, 233) !important;
  min-height: 400px;
}

.uploadButton {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
