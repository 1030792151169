<template>
  <div>
    <h2 class="title monFont500">Subscribers</h2>
    <b-table
      class="has-text-left"
      :data="subscribers"
      default-sort="createdAt"
      default-sort-direction="desc"
      bordered
    >
      <b-table-column field="email" label="Email" sortable v-slot="props">
        {{ props.row.email }}
      </b-table-column>
      <b-table-column
        field="createdAt"
        label="Created At"
        sortable
        v-slot="props"
      >
        {{
          new Date(props.row.createdAt.toDate()).toLocaleDateString("en-ZA", {
            dateStyle: "medium",
          }) +
          " " +
          new Date(props.row.createdAt.toDate()).toLocaleTimeString("en-ZA")
        }}
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">Nothing today</div>
      </template>
    </b-table>

    <b-modal
      v-if="showDetails"
      v-model="showDetails"
      :width="640"
      scroll="keep"
    >
      <div class="card">
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4">
                {{ details.firstName + " " + details.surname }}
              </p>
              <p class="subtitle is-6 mt-2">
                {{
                  new Date(details.createdAt.toDate()).toLocaleDateString(
                    "en-ZA",
                    {
                      dateStyle: "medium",
                    }
                  ) +
                  " " +
                  new Date(details.createdAt.toDate()).toLocaleTimeString(
                    "en-ZA"
                  )
                }}
              </p>
            </div>
          </div>

          <div class="content">
            <b-field position="is-centered" label="Email">
              <b-input readonly type="text" v-model="details.email"> </b-input>
            </b-field>
            <b-field position="is-centered" label="Phone Number">
              <b-input readonly type="text" v-model="details.phoneNumber">
              </b-input>
            </b-field>
            <b-field label="Message">
              <b-input
                readonly
                v-model="details.message"
                maxlength="300"
                type="textarea"
              ></b-input>
            </b-field>
            <b-field position="is-centered" label="I am a">
              <span
                v-for="t in details.userType"
                :key="t"
                class="tag is-dark mr-3 modalTag"
              >
                {{ t }}
              </span>
            </b-field>
            <br />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    dailySummary: { type: Boolean, default: false },
  },
  data() {
    return {
      showOlderMessages: false,
      loading: true,
      selectedRow: null,
      showDetails: false,
      details: {},
    };
  },
  methods: {
    rowClicked(event) {
      console.log(event);
      this.details = event;
      this.showDetails = true;
    },
  },

  computed: {
    subscribers() {
      if (!this.dailySummary) {
        return this.$store.state.admin.subscribers;
      }
      const today = dayjs(new Date());

      return this.$store.state.admin.subscribers.filter((m) =>
        dayjs(m.createdAt.toDate()).isSame(today, "day")
      );
    },
  },

  created() {},
};
</script>

<style scoped>
span.modalTag {
  font-size: 16px;
}
</style>
