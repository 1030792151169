<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title monFontBold">Place Bid</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section v-if="!loaded" class="modal-card-body has-text-centered">
      <loading-spinner></loading-spinner>
    </section>
    <section
      v-if="loaded && verifiedToBid"
      class="modal-card-body has-text-centered"
    >
      <div v-if="!placingBid && !bidPlaced">
        <b-field class="sliderField" label="Your Bid:">
          <b-slider
            class="slider"
            v-model="bidValue"
            indicator
            size="is-large"
            :tooltip="false"
            :min="startingBid"
            :max="startingBid + bidIncrement * 15"
            :locale="'en-ZA'"
            :step="bidIncrement"
            ticks
          ></b-slider>
        </b-field>
        <b-field>
          <b-checkbox v-model="termsCheck" type="is-success" class="mt-5">
            I agree to the terms and conditions
          </b-checkbox>
        </b-field>
      </div>
      <div v-if="placingBid">
        <p class="title monFont500">Placing Bid...</p>
        <loading-spinner></loading-spinner>
      </div>

      <div v-if="bidPlaced && !bidFailed">
        <span class="icon has-text-info">
          <i class="mdi mdi-check-circle succIcon"></i>
        </span>
        <p class="title monFont500 mt-5">Your bid has been placed!</p>

        <b-button class="mt-5" @click="$emit('close')">Close</b-button>
      </div>
      <div v-if="bidPlaced && bidFailed">
        <span class="icon has-text-info">
          <i class="mdi mdi-alert-circle failIcon"></i>
        </span>
        <p class="errorMessage monFont500 mt-5">{{ failMessage }}</p>

        <b-button class="mt-5" @click="$emit('close')">Close</b-button>
      </div>
    </section>
    <section
      v-if="loaded && !verifiedToBid"
      class="modal-card-body has-text-centered"
    >
      <p class="disclaimer monFontBold has-text-left">
        All registered bidders are required to pay a refundable goodwill fee of
        R500.00 in order to participate in any Auctions. Please use your full
        name as reference and email POP through to sales@estateauctionhouse.com
        Please make your electronic transfer to below account: <br />
        <br />
        ACCOUNT NAME: ESTATE AUCTION HOUSE <br />
        BANK: First National Bank <br />
        BRANCH: Bryanston <br />
        BRANCH CODE: 250017 <br />
        ACCOUNT NO: 62863223740
      </p>
    </section>

    <footer
      v-if="!placingBid && !bidPlaced"
      style="width: 100%"
      class="modal-card-foot"
    >
      <div>
        <b-button @click="$emit('close')" type="is-danger">Cancel</b-button>
        <b-button
          @click="placeBid"
          :disabled="!termsCheck || !validBid"
          style="position: absolute; right: 0; margin-right: 20px"
          type="is-success"
          >Place Bid</b-button
        >
      </div>
    </footer>
  </div>
</template>

<script>
import { user } from "../utils/user";
import LoadingSpinner from "./LoadingSpinner.vue";

export default {
  props: {
    startingBid: { type: Number, required: true },
    currentBid: { type: Number || null, required: true },
    bidIncrement: { type: Number, required: true },
    lotId: { type: String, required: true },
  },
  data() {
    return {
      bidValue: this.currentBid ?? this.startingBid,
      termsCheck: false,
      placingBid: false,
      bidPlaced: false,
      bidFailed: false,
      loaded: false,
      verifiedToBid: false,
      failMessage:
        "An unexpected error occurred. Please try again or contact us if this error continues",
    };
  },
  computed: {
    validBid() {
      if (!this.currentBid) {
        return this.bidValue >= this.startingBid;
      }

      return this.bidValue > this.currentBid;
    },
  },
  components: {
    LoadingSpinner,
  },

  methods: {
    async placeBid() {
      this.placingBid = true;
      try {
        const res = await user.placeBid(this.bidValue, this.lotId);

        this.placingBid = false;
        this.bidPlaced = true;

        console.dir(res);

        if (res.status !== 200) {
          this.bidFailed = true;

          if (res.status == 422) {
            this.failMessage = res.data.message;
          } else {
            this.failMessage =
              "An unexpected error occurred. Please try again or contact us if this error continues";
          }
        }
      } catch (error) {
        this.bidFailed = true;
      }
    },
  },

  created() {
    console.log("Bid modal created");
    user.verifiedToBid().then((verified) => {
      this.verifiedToBid = verified;
      this.loaded = true;
      console.log("Bidder Verified: " + verified);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/bulmaVariables.scss";

.modal-card {
  width: 700px;
}

.succIcon {
  margin-top: 60px;
  font-size: 100px;
  color: $primary;
}

.failIcon {
  margin-top: 30px;
  font-size: 100px;
  color: red;
}

.errorMessage {
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .modal-card {
    width: 100vw;
  }
}

.sliderField {
  margin: 50px;
  margin-top: 10px;
}
</style>
