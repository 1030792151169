import axios from "axios";

const subscribeToNewsletter = async (email) => {
  console.log("Hi");
  console.log(email);
  const url = process.env.VUE_APP_FUNCTIONS_API;

  console.log("URl: " + url);
  const payload = { email: email };
  try {
    const res = await axios.post(url + "/users-user/subscribe", payload);
    console.dir(res);
    if (res.data.success) return true;
    else return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default subscribeToNewsletter;
